
import React from "react";
import folder from '../../assets/icons/folder.png';
import { useTranslation } from "react-i18next";

export default function EmptyWidget({title}) {
    const {t,i18n} = useTranslation();
    return(
        <div style={{

            paddingTop:"10%",
            paddingBottom:"10%",
            borderRadius:"10px",
            
            display:"flex",width:"100%",backgroundColor:"white",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"5%"}}>
        <img 
           width={90} 
           height={70} 
           src={folder} 
           style={{filter: "grayscale(90%)"}}
           alt="Folder Icon"
       />
           <p style={{fontSize:"0.9rem",letterSpacing:"0.7px",color:"black",padding:"2px",textAlign:"center", fontFamily:"Mukta"}}>{title  ?? t('no_product')}</p>
        </div>
    );
}