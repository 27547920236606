
import { Box } from "@mui/material";
import '../../Styles/DescriptionImageSlider.css';
import React from 'react'; 
export default function DescriptionDottedBox({ title, Icon ,boxClassName,imgSource}) {
    return (
        <Box
            className="dotted-desctription-box"
            sx={{
                padding: "1px",
                background: "white",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginLeft:{xs:"2%",md:"0%"},
                marginRight:{xs:"2%",md:"0%"}
            }}
        >
            <div style={{ display: "flex",width:"100%", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
                justifyContent: "flex-end", // Aligns items to the bottom of the container
                alignItems: "flex-end", // Aligns items to the end (right side) horizontally
                textAlign: "right" 
            }}>
               <img 
                    src={imgSource} 
                    width={"20px"} 
                    height={"20px"} 
                    style={{ 
                        filter: "invert(30%) sepia(100%) hue-rotate(-50deg) saturate(1000%)", 
                        display: "block"
                    }}
                    alt="icon"
                />
            </div>

                
                <div style={{display:"flex",flexDirection:"column",width:"65%",
                textAlign:"start",justifyContent:"start"}}>
                <p
                style={{
                    
                    fontFamily: "Mukta",
                    fontWeight: "400",
                    fontSize: "0.7rem",
                    paddingLeft: "2px",
                    marginTop: "12px",
                    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                    overflow: "hidden",   // Hides the overflowed content
                    textOverflow: "ellipsis" // Adds ellipsis (...) at the end of the text
                }}
                >
                {title}
                </p>
                </div>

            </div>
        </Box>
    );
}
