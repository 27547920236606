
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState,useEffect } from "react";
import '../Styles/SharedStyle.css';
import {Box,Grid,Typography} from "@mui/material"
import FeatureItemCard from "../Components/Home_Page_components/feature_item_card";
import EmptyWidget from "../Components/Global_components/empty_widget";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
export default function SearchPage(){
   
     const {t} = useTranslation();
     const {filtredProducts} = useSelector((state) => state.filter);

     const location = useLocation(); // Get the current location object
     const params = new URLSearchParams(location.search); // Create URLSearchParams object
   
     // Extract query parameters
     const willaya = params.get('willaya');
     const type = params.get('type');
     const pool = params.get('pool');
     const garden = params.get('garden');
     const furnished = params.get('furnished');
     const electricity = params.get('electricity');
     const gaz = params.get('gaz');
     const act_name = params.get('act_name');
     const min_price = params.get('min_price');
     const max_price = params.get('max_price');
     const min_area = params.get('min_area');
     const max_area = params.get('max_area');

     return (
        <div className="Global-div">
               <Box
             sx={{
                background: "#ECECF4",
                width: "90%",
                justifyContent:"center",
                paddingLeft:"5%",
                paddingRight:"5%",
                paddingBottom:"10px",
                paddingTop:"10px",

             }}
            >
             <Typography variant="h5" sx={{
                textAlign:"center",
                color:"#E4180B",
                fontFamily:"Mukta",
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>
                    {t('filter_result')}</Typography>   
             <Typography  sx={{
                textAlign:"center",
                fontSize:"14px",
                width:"80%",
                padding:"0 10%",
                justifyItems:"center",
                alignItems:"center",
                fontFamily:"Mukta",
                justifyContent:"center",paddingTop:"5px",paddingBottom:"20px"}}>
                    {t('filter_result_sub_title')} </Typography>  

                    {/* <Box 
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textAlign:"center",
                        justifyContent:"center",
                        paddingTop: "20px",
                        paddingBottom: "5px",
                     }}
                     >
                     <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center" }}>
                        {willaya && `${t('willaya')} : ${willaya}`}
                     </Typography>
                     {pool && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`${t('pool')} :`}
                        </Typography>
                     )}
                     {type && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`${t('type')} : ${type}`}
                        </Typography>
                     )}
                     {max_price && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`min_price: ${min_price}`}
                        </Typography>
                     )}
                     {max_price && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`max_price: ${max_price}`}
                        </Typography>
                     )}
                     {electricity && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`${t('electricity')} :`}
                        </Typography>
                     )}
                     {furnished && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`${t('furnished')} :`}
                        </Typography>
                     )}
                     {garden && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`${t('garden')} :`}
                        </Typography>
                     )}
                     {min_area && (
                        <Typography variant="h6" sx={{ fontFamily: "Mukta", textAlign: "center", mt: 1 }}>
                           {`max_area: ${min_area}`}
                        </Typography>
                     )}
                     </Box> */}

              


                 {filtredProducts && filtredProducts.length>0 ?
                  <FeatureItemCard  products={filtredProducts}  hideFilter={true}/>
                  :<EmptyWidget/>
                  }

                <div style={{marginTop:"7%"}}></div>
                
            </Box>
        </div>
         
     );

   




}


