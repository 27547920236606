import Slider from 'react-slick'; // Import Slider from react-slick
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import '../../Styles/DescriptionImageSlider.css';
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} description-slider-arrow description-slider-arrow-next`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ color: 'black' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} description-slider-arrow description-slider-arrow-prev`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ transform: 'rotate(-60deg)' }} />
    </div>
  );
}

export default function DescriptionImageSlider({ imageSliders }) {
  const [index, setIndex] = useState(-1);

  const currentImage = imageSliders[index];
  const nextIndex = (index + 1) % imageSliders.length;
  const nextImage = imageSliders[nextIndex] || currentImage;
  const prevIndex = (index + imageSliders.length - 1) % imageSliders.length;
  const prevImage = imageSliders[prevIndex] || currentImage;
  const savedLanguage = localStorage.getItem('language') || navigator.language || 'fr';
  const handleClick = (index) => {
    if(savedLanguage=='ar'){
      document.documentElement.setAttribute('dir', 'ltr');
    }
    
    setIndex(index)};
  const handleClose = () => {
    if(savedLanguage=='ar'){
      document.documentElement.setAttribute('dir', 'rtl');
    }
    
    setIndex(-1)};
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    alignItems: 'left',
    speed: 500,
    slidesToShow: imageSliders.length >= 3 ? 3 : imageSliders.length,
    slidesToScroll: imageSliders.length >= 3 ? 3 : 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />, // Use custom arrow components
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        direction: "rtl", // Keep your app's overall direction as RTL
        width: '99%',
        height: 'auto',
        marginTop: '-10px',
        textAlign: 'center', // Ensure positioning for arrows
      }}
    >
      <Slider {...settings} className="description-slider">
        {imageSliders.map((image, index) => (
          <Box
            key={index}
            onClick={() => handleClick(index)}
            className="slider-div"
            sx={{
              cursor: 'pointer',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '15px',
              backgroundColor: '#D9D9D9',
              backgroundImage: `url(${image.original_url})`,
              width: '100%',
              height: '300px',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: 'block',
            }}
          />
        ))}
      </Slider>

      {/* Lightbox Section with LTR Direction */}
      <Box xs={{ direction: "ltr",justifyContent: "center", alignItems: "center" }}>
        {!!currentImage && (
          <Lightbox
          style={{display:"flex",direction: "ltr",justifyContent: "center", alignItems: "center" }}
            mainSrc={currentImage.original_url}
            imageTitle={currentImage.collection_name}
            mainSrcThumbnail={currentImage.original_url}
            nextSrc={nextImage.original_url}
            nextSrcThumbnail={nextImage.original_url}
            prevSrc={prevImage.original_url}
            prevSrcThumbnail={prevImage.original_url}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}

          />
        )}
      </Box>
    </Box>
  );
}
