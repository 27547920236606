

import '../Styles/SharedStyle.css';
import {Grid,Typography,Box,Button} from '@mui/material';
import { useDispatch } from "react-redux";
import {React, useEffect } from "react";
import { useSelector } from 'react-redux';
import { fetchFavProducts } from '../Redux_Managment/products_slice';
import { useNavigate } from 'react-router-dom';
import EmptyWidget from '../Components/Global_components/empty_widget';
import { useTranslation } from 'react-i18next';
import FeatureItemCard from '../Components/Home_Page_components/feature_item_card';
import { TbRuler2, TbRuler3 } from 'react-icons/tb';

export default function FavoriteProducts() {  
    const dispatch=useDispatch();
    const {t} = useTranslation();
    const {favProduct} = useSelector(state => state.products || {});
    useEffect(()=>{
        dispatch(fetchFavProducts())
    },[dispatch])




    return (
        <div className="Global-div">
         
            <Box
             sx={{
                background: "#ECECF4",
                width: "90%",
                justifyContent:"center",
                paddingLeft:"5%",
                paddingRight:"5%",
                paddingBottom:"10px",
                paddingTop:"10px",

             }}
            >
             <Typography variant="h4" sx={{
                textAlign:"center",
                color:"#E4180B",
                fontFamily:"Mukta",
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>
                    {t('fav_product_title')}</Typography>   
             <Typography  sx={{
                textAlign:"center",
                fontSize:"14px",
                width:"80%",
                padding:"0 10%",
                justifyItems:"center",
                alignItems:"center",
                fontFamily:"Mukta",
                justifyContent:"center",paddingTop:"5px",paddingBottom:"20px"}}>
                    {t('fav_product_title_sub')}<br/>
                    {t('fav_product_title_sub2')}
                     
                     </Typography>  
                      


            

                {favProduct && favProduct.length>0 ?
                   <FeatureItemCard  products={favProduct} hideFilter={true} isFav={true}/>
                : <EmptyWidget/>}
               

                <div style={{marginTop:"7%"}}></div>
                
            </Box>
        </div>
    );
}