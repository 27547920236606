import {Grid ,Box} from "@mui/material";
import "../../Styles/DescriptionImageSlider.css";
import FeatureGrid from '../../Components/Description_Page_Components/features_grid';
import DescriptionPropertyBox from '../../Components/Description_Page_Components/description_property_box'
import DescriptionImageSlider from '../../Components/Description_Page_Components/description_slider';
import DescriptionDottedBox from '../../Components/Description_Page_Components/description_dotted_box';
import garage from '../../assets/icons/garage.png';
import apartment from '../../assets/icons/apartment.png';
import doubleBed from '../../assets/icons/double-bed.png';
import area from '../../assets/icons/area.png';
import bathtub from '../../assets/icons/bathtub.png'; 
import map from '../../assets/images/map.png'; 
import deal from '../../assets/icons/deal.png'
import { t } from "i18next";

export default function DescriptionLeftSection({description,
    garage_val,
    garden,
    pool,
    Gas,
    rooms,
    area_val,
    electricity ,
    Furnished ,
    status,
    sell_price,
    type,
    category_name
}){
    const items = description.split('\n').map(item => item.trim());
    return  <div style={{background:"white", display: "flex", flexDirection: "column", marginTop: "2%",
        justifyContent:"center", alignItems: "center",
            width:"100%",height:"100%"}}>

         {/* Dotted Boxes */}       
         <Grid container spacing={2} sx={{justifyContent: {xs:"space-between",md:"center"},marginTop:"2%"}}>
         <Grid item xs={false} sm={1} md={1} lg={1} xl={1} 
              sx={{
                
                    display: { xs: "none", md: "flex" ,sm:"flex",lg:"flex",xl:"flex"}, // Hides on mobile (xs), shows on medium screens (md)
                    justifyContent: "center",
                    alignItems: "center",
                 
                }}>
            <Box
                sx={{

                    padding: "5px",
                    background: "white",
                    display: { xs: "none", md: "flex" }, // Hides on mobile (xs), shows on medium screens (md)
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
            >
                {/* Your content goes here */}
            </Box>
        </Grid>

            
            <Grid item xs={6} sm={2} md={3} lg={2} xl={2} >
               <DescriptionDottedBox title={type==="Rent"? t('rent'):type==="Sale" ? t('sale') :type==="Exchange"? t('exchange'): t('sale')}  imgSource={deal}/>
            </Grid>
           {rooms!=null && rooms!=0 && <Grid item xs={6} sm={2} md={3} lg={2} xl={2} >
               <DescriptionDottedBox  title={`${rooms} Rooms`}  imgSource={doubleBed} />
            </Grid>}
            {garage_val&&garage_val!=0  && <Grid item xs={6} sm={2} md={3} lg={2} xl={2} >
               <DescriptionDottedBox className="dotted-desctription-box" title={t('garage')} imgSource={garage}/>
            </Grid>}
            <Grid item xs={6} sm={2}  md={3} lg={2} xl={2} >
               <DescriptionDottedBox  title={category_name}  imgSource={apartment}/>
            </Grid>
            <Grid item xs={6} sm={2} md={3} lg={2} xl={2} >
               <DescriptionDottedBox  title={`${area_val} m`}  imgSource={area}/>
            </Grid>

            <Grid item xs={false} sm={1} md={1} lg={1} xl={1} 
              sx={{
                
                    display: { xs: "none", md: 'none' ,sm:"flex",lg:"flex",xl:"flex"}, // Hides on mobile (xs), shows on medium screens (md)
                    justifyContent: "center",
                    alignItems: "center",
                 
                }}>
            <Box
                sx={{

                    padding: "5px",
                    background: "white",
                    display: { xs: "none", md: "flex" }, // Hides on mobile (xs), shows on medium screens (md)
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
            >
                {/* Your content goes here */}
            </Box>
        </Grid>
        

         </Grid>
          
          <div style={{padding:"2%",width:"90%",marginTop:"7%",marginRight:"8%",marginLeft:"8%",fontSize:"0.9rem",fontFamily:"Mukta",fontWeight:"normal",color:"#5B5B5B"}}>
          
            {items.map((item, index) => (
                index==0 ? <p style={{fontSize:"0.9rem",fontFamily:"Mukta",fontWeight:"normal"}} key={index}>{item}</p> :
                <li style={{margin:"1%",fontSize:"0.9rem",fontFamily:"Mukta",fontWeight:"normal"}} key={index}>{item}</li>
            ))}
            
           </div>

         {/* Description Type */} 
          {/* <div style={{padding:"2%",width:"90%",fontFamily:"Mukta"}}>
              <p style={{fontFamily:"Mukta",color:"#107DAC",fontSize:"1rem",fontWeight:"bold"}}>Property Details: </p>
             <Grid container >
                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={"Property Id"} Description={"123456789"} color={"#F4F4F4"}/> 
                            <DescriptionPropertyBox Title={"Property Id"} Description={"123456789"} color={"#F4F4F4"}/> 
                           
                        </Grid>
                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={"Property Type"} Description={"123456789"} color={"white"}/> 
                            
                            <DescriptionPropertyBox Title={"Property Type"} Description={"123456789"} color={"white"}/> 
                            
                        </Grid>
                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={"Property Status"} Description={"123456789"} color={"#F4F4F4"}/> 
                            <DescriptionPropertyBox Title={"Property Status"} Description={"123456789"} color={"#F4F4F4"}/> 
                            
                        </Grid>

                      


                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                           <DescriptionPropertyBox Title={"Bed Room"} Description={"123456789"} color={"white"}/> 
                           
                           <DescriptionPropertyBox Title={"Bed Room"} Description={"123456789"} color={"white"}/> 
                           
                        </Grid>

                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={"Bath Room"} Description={"123456789"} color={"#F4F4F4"}/> 
                            
                            <DescriptionPropertyBox Title={"Bath Room"} Description={"123456789"} color={"#F4F4F4"}/> 
                            
                        </Grid>
           </Grid> 

        
           </div>*/}


           <div style={{padding:"2%",width:"90%",fontFamily:"Mukta"}}>
             <p style={{fontFamily:"Mukta",color:"#107DAC",fontSize:"1rem",fontWeight:"bold"}}>{t('property_details')}: </p>
             <Grid container >
                      <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={t('status')} Description={status} color={"#F4F4F4"}/> 
                            <DescriptionPropertyBox Title={t('category')} Description={category_name} color={"#F4F4F4"}/> 
                           
                        </Grid>
                        <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                            <DescriptionPropertyBox Title={t('sell_price')} Description={sell_price ?? "Not provided"} color={"white"}/> 
                            <DescriptionPropertyBox Title={t('type')} Description={type} color={"white"}/> 
                           
                        </Grid>            
                       
                    </Grid>

        
           </div>


           <div style={{padding:"2%",width:"90%",fontFamily:"Mukta"}}>
             <p style={{fontFamily:"Mukta",color:"#107DAC",fontSize:"1rem",fontWeight:"bold"}}>{t('features')} </p>

              <FeatureGrid  garage={garage_val}
                        garden={garden}
                        pool={pool}
                        Gas={Gas}
                        electricity={electricity}
                        Furnished={Furnished}  />
        
           </div>

        </div>
}