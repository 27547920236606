import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {MenuItem} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom';
import '../../Styles/MainStyle.css';
import userIcon from '../../assets/images/user.png';
import { signOut } from '../../Redux_Managment/auth_slice';
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import {useEffect} from 'react';
import { IoLanguageSharp } from "react-icons/io5";






function ResponsiveAppBar({categories}) {
  const { t, i18n } = useTranslation();
 const [refreshKey, setRefreshKey] = useState(0);
  const changeLanguage = (lang) => {
    console.log(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang); 
    setActivePage('/');
    setRefreshKey((prevKey) => prevKey + 1);
    window.location.reload(true);
  };
 
  
 
  const savedLanguage = localStorage.getItem('language') || 'fr';
  useEffect(() => {
    i18n.changeLanguage(savedLanguage);
    document.documentElement.setAttribute('dir', savedLanguage === 'ar' ? 'rtl' : 'ltr');
  }, [i18n.language]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();
  const user=localStorage.getItem('user_name');
  const token=localStorage.getItem('token');

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [activePage,setActivePage]=useState('/');


 

  const goToOption = (path) => {
    navigate(path);
  };

  const logout = async() => {
    try{
      await dispatch(signOut()).unwrap();
      navigate('/', { replace: true });
    }catch(error){

    }
  };
  const PagewithUrl=[
    {
    'page':t('home'),
    'url':'/',},
    {
      'page':t('categories'),
      'url':'/categories',},
    {
      'page':t('agencies'),
      'url':'/agencies',},
      {
        'page':t('about'),
        'url':'/About-us',},
    
  ];
  const settings = [
    { option: t('profile'), function: () => goToOption('/profile') },
    { option: t('my_products'), function:()=> goToOption('/my-Products')  },
    { option: t('fav_products'), function:()=> goToOption('/my-favorite-products')  },
    { option: t('log_out'), function: () => logout() },
    


    
  ];

  const languages=[
    { language: t('english'), code: 'en' },
    { language: t('french'), code: 'fr' },
    { language: t('arabic'), code: 'ar' },
  ];
  const changePage = (path) => {
    setActivePage(path);
    navigate(path, { replace: true }); // replace should be a boolean
  };
  
  const handleOpenUserMenu = () => {
    navigate('/register');
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

 
  const handleProductClick = (category_id,category_name) => {
    location.pathname!= `/Category/${category_name}` &&
     navigate(`/Category/${category_name}`, { state: { category_id,category_name } });
  };



  return  (
    <AppBar className="App-Bar" position="static" sx={{backgroundColor: '#515151',height:"70px" }}>
      <Container maxWidth="xl"    >
        <Toolbar disableGutters sx={{ paddingLeft: '0%', paddingRight: '0%',justifyContent: 'center' }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {PagewithUrl.map((p) => (

                <MenuItem key={p.page}  onClick={() => changePage(p.url) }>
                  <Typography textAlign="center">

                  {p.page}
             

                  </Typography>
                </MenuItem>
              ))}

            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} alt="logo" style={{height:"50px"}}/>
          </Typography>

         
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} alt="logo" style={{height:"50px"}}/>
          </Typography>
          
          <Box  className="menu_item" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', padding: '10px' }, justifyContent: 'center' }}>
            {PagewithUrl.map((p) => (
              <Button
                key={p.page}
            
                onClick={() =>p.page != t('categories') ? changePage(p.url) : null }
                sx={{ 
                  my: 2, 
                  color: 'white', 
                  display: 'block', 
                  margin: '10px', 
                  letterSpacing: '0.7px',
                  padding: '10px',
                  fontSize: '1.1rem',
                  fontFamily: 'Mukta',
                  textTransform: 'none', 
                  color: activePage == p.url ? "red" : "white"
               }}
                
                
              >
                {p.page === t('categories') ? (
                      <div className="dropdown">
                        <button className="dropbtn"  >{p.page}</button>
                        <div className="dropdown-content">
                          {categories.map((category, index) => (
                              <div className="dropdown-sub-category">
                                <button className="dropbtn-sub-category" onClick={() => handleProductClick(category.id,category.name)}>{category.name}</button>
                                  <div className="dropdown-content-sub-category">
                                    {category.descendants.map((cd, index) => (
                                        <div className="dropdown-sub2-category">
                                        <button className="dropbtn-sub2-category" onClick={() => handleProductClick(cd.id,cd.name)}>{cd.name}</button>
                                          <div className="dropdown-content-sub2-category">
                                              <div>
                                              {cd.descendants.map((ccd, index) => (
                                              <a onClick={() => handleProductClick(ccd.id,ccd.name)} >{ccd.name}</a>
                                              ))}
                                            </div>
                                          </div>
                                      </div>
                                    ))}
                                  </div>
                              </div>
                          ))}
                        </div>
                      </div>
                    ) : p.page}

                
             
              </Button>
            ))}
          </Box>
             

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip style={{display:"flex"}}>
              {!user || !token ?<IconButton onClick={handleOpenUserMenu} style={{marginTop:"3%"}}>
               <img src={userIcon} alt="user icon" width="25" style={{ filter: 'invert(100%)' ,padding:'10px'}} />

               <p 
                style={{
                  color: 'white', 
                  display: 'block', 
                  margin: '10px', 
                  letterSpacing: '0.7px',
                  fontSize:'1rem',
                  fontFamily: 'Mukta',
                }}
               >{t('log_in')}</p> 
              </IconButton>
              : <IconButton >
                    <div className="dropdown">
                        <div style={{display:"flex"}}>
                         
                          <button className="dropbtn"  >
                             <img src={userIcon}  alt="user icon"  
                                  width={25} height={26} 
                                  style={{ filter: 'invert(100%)' ,
                                  padding:'10px',marginTop:"10px"}} />
                          </button>
                        </div>
                        <div className="dropdown-content" style={{marginTop:"25px"}}>
                          {settings.map((option, index) => (
                              <div className="dropdown-sub3-category" style={{cursor:"pointer"}}>
                                <button className="dropbtn-sub3-category" onClick={() => option.function()}>{option.option}</button>
                               
                              </div>
                          ))}
                        </div>
                      </div>
                     
              
             </IconButton> }
                  
               <IconButton sx={{marginTop:"10px",display: { xs: 'none', md: 'flex' } }}>
                    <div className="dropdown">
                        <div style={{display:"flex"}}>
                         
                          <button className="dropbtn"  >
                             {savedLanguage == 'en' ? 'English' : savedLanguage == 'ar'? 'العربية' : "Francais"}
                          </button>
                        </div>
                        <div className="dropdown-content" style={{marginTop:"0px"}}>
                          {languages.map((option, index) => (
                              <div className="dropdown-sub3-category">
                                <button className="dropbtn-sub3-category" onClick={() =>  changeLanguage(option.code)}>{option.language}</button>
                               
                              </div>
                          ))}
                        </div>
                      </div>
                     
              
             </IconButton> 
            </Tooltip>
           
          </Box>
        </Toolbar>
 

      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
