import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

import axios from 'axios'
import BASE_URL from '../Config'
const savedLanguage = localStorage.getItem('language') || 'fr';
export const fetchDashboardData = createAsyncThunk('dashboardData/fetchDashboardData', async () => {
    try {
        // Add headers to the request
        const response = await axios.get(`${BASE_URL}/api/dashboard-details`,{params: {"lang": savedLanguage}}, {
            
            headers: {
                
                'Content-Type': 'application/json',
            }
        });

        return response.data.data;
    } catch (e) {
        console.log(`error from server ${e}`);
        throw e; // Rethrow the error to ensure that it gets properly handled by the `createAsyncThunk` lifecycle methods
    }
});

const dashboardSlice = createSlice({
    initialState:{
        status: 'idle',
        isLoading:false,
        banner:[],
        categories:[],
        firstFiveCategories:[],
        featuredProducts:[],
        featuredAgencies:[],
        saleProducts:[],
        rentProducts:[],
        error:null
    },
    name:'dashboard',
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchDashboardData.pending,(state)=>{
            state.status='loading';

        })
        .addCase(fetchDashboardData.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.status='succeeded';
            state.banner=action.payload.settings.sliders;
            console.log(state.banner);
            state.categories=action.payload.categories;
            state.featuredAgencies=action.payload.featured_agencies;
            if(state.categories && state.categories.length>0){
                state.firstFiveCategories=state.categories.slice(0,5);
                console.log("state.firstFiveCategories");
                console.log(state.firstFiveCategories);
            }
            state.featuredProducts=action.payload.featured_products;

            if (state.featuredProducts && state.featuredProducts.length > 0) {
                state.saleProducts = state.featuredProducts.filter(product => product.type === 'Sale');
                state.rentProducts = state.featuredProducts.filter(product => product.type === 'Rent');
            }
        })
        .addCase(fetchDashboardData.rejected,(state,action)=>{
            state.isLoading=false;
            state.status='failed';
            state.error=action.error.message;
        })
    }
    }
);
export default dashboardSlice.reducer;