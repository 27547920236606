import { Box,Grid ,Typography,Switch,TextField,Button,CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { HiFilter } from "react-icons/hi";
import "../../Styles/SideBar.css";
import { useTranslation } from 'react-i18next';
import { CiSquareCheck } from "react-icons/ci";
import { CgCloseO } from "react-icons/cg";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {fetchFilter} from '../../Redux_Managment/search_filter_slice'
import { fetchStates,fetchCities } from '../../Redux_Managment/location_slice';
import { fetchAct } from '../../Redux_Managment/products_slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaAngleDown } from "react-icons/fa";
const Sidebar = ({ isOpen, onClose }) => {
   const dispatch=useDispatch();
   const navigate=useNavigate();
   const {t,i18n}=useTranslation();
 
   const [min_price,setMinPrice]=useState();
   const [max_price,setMaxPrice]=useState();

   const [min_area,setMinArea]=useState();
   const [max_area,setMaxArea]=useState();


   const [min_piece,setMinPieces]=useState();
   const [max_piece,setMaxPieces]=useState();
   

   const [isOpenWillaya,setOpenWillaya]=useState(false);
   const [isOpenCity,setOpeCity]=useState(false);
   const [isOpenPriceType,setOpePriceType]=useState(false);
   const [isOpenPapier,setOpenPapier]=useState(false);
   const [isOpenType,setOpenType]=useState(false);
   const [isOpenSpecification,setOpenSpecification]=useState(false);
   const [isOpenCondition,setOpenCondition]=useState(false);
   const [loading,setLoading]=useState(false);
   const [price_type_value,setPriceTypeValie]=useState('Da');


   const [willaya,setWillaya]=useState();
   const [state_id,setStateId]=useState();
   const [city,setCity]=useState();
   const [city_id,setCityId]=useState();
   const [type,setType]=useState();
   const [condition,setCondition]=useState();
   const [papier,setPapier]=useState();
   const [act_Type,setActType]=useState();
   const [specification,setSpecification]=useState();
   const [country_id,setCountryId]=useState(4);
   const [featured, setFeatured] = useState(() => ({
      gaz: 0,
      electricity: 0,
      furnished: 0,
      pool: 0,
      garden: 0,
      garage: 0
    }));
    const [counter, setCounter] = useState(0);

   const handleClick = (key) => {
   setFeatured((prev) => {
      const newValue = prev[key] === 1 ? 0 : 1;

      // Increment the counter if the value is changed to 1
      if (newValue === 1) {
         setCounter(prevCounter => prevCounter + 1);
      }

      // Decrement the counter if the value is changed to 0
      if (newValue === 0 && prev[key] === 1) {
         setCounter(prevCounter => prevCounter - 1);
      }

      return { ...prev, [key]: newValue };
   });
   };
    

   
   const {states,cities,error, status }=useSelector((state)=>state.location);
   const {acts }=useSelector((state)=>state.products);

   const savedLanguage = localStorage.getItem('language') || 'fr';
  
   useEffect(()=>{
     dispatch(fetchStates({country_id}))
     dispatch(fetchAct())
   },[dispatch])


   const handleOpeNWillaya=()=>{

      
      setOpenCondition(false);
      setOpenPapier(false);
      setOpenSpecification(false);
      setOpePriceType(false);
      setOpeCity(false);
      setOpenWillaya(true);

   }
   const handleOpeNCity=()=>{
      setOpeCity(true);
      setOpenCondition(false);
      setOpenPapier(false);
      setOpenSpecification(false);

      setOpenWillaya(false);
      setOpePriceType(false);
      setOpeCity(false);

   }

 
   const handleWillayaValue = (id, name) => {
      setOpePriceType(false);
      setOpeCity(false);
    
      setOpenWillaya(false);
      setOpenSpecification(false);
      setOpenCondition(false);
      setOpenPapier(false);
      setWillaya(name);
      setStateId(id);
    
      // Dispatch the action
      dispatch(fetchCities({ state_id: id }));
      setOpeCity(true);
    };

   const handleCityaValue=(id,name)=>{
      setOpeCity(false);
      setOpePriceType(false);
      setOpeCity(false);
      setOpenWillaya(false);
      setOpenSpecification(false);
      setOpenCondition(false);
      setOpenPapier(false);
      setCity(name);
      setCityId(id);
      
   }

   const handleOpenPapier=()=>{

      setOpenWillaya(false);
      setOpenSpecification(false);
      setOpenCondition(false);
      setOpenPapier(true);
      setOpePriceType(false);
      setOpeCity(false);

   }

   const handleOpenPriceType=()=>{

      setOpePriceType(true);
      setOpeCity(false);

      setOpenWillaya(false);
      setOpenSpecification(false);
      setOpenCondition(false);
      setOpenPapier(false);

   }
   const handleOpenSpecification=()=>{

      setOpenWillaya(false);
     
      setOpenCondition(false);
      setOpenPapier(false);
      setOpenSpecification(true);

   }

   const handleOpenType=()=>{
      setOpenType(true);
      setOpenWillaya(false);
     
      setOpenCondition(false);
      setOpenPapier(false);
      setOpenSpecification(false);
     

   }
   const handleActType=(id,name)=>{
      setActType(id);
      setPapier(name);
      setOpenPapier(false);
   }
   const handlePriceType=(name)=>{
     
      setPriceTypeValie(name);
      setOpePriceType(false);
   }

   

 const type_list = [
  {
    name: t('for_rent'),   // Make sure 'for_rent' is correctly defined in your translation file
    value: "Rent"
  },
  {
    name: t('for_sale'),   // Ensure 'for_sale' is also defined in your translation file
    value: "Sale"
  },
  {
    name: t('for_exchange'),   // Similarly, 'for_exchange' should be defined
    value: "Exchange"
  },
];

    const price_type =["Da","Millions","Milliard"];

    useEffect(() => {
       if(!isOpen){
         setOpenCondition(false);
         setOpenPapier(false);
         setOpenWillaya(false);
         setOpenType(false);
         setOpenSpecification(false);
         setOpeCity(false);
         setOpePriceType(false);
       }
    })

   


  



    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
       try{
         await dispatch(fetchFilter({state_id,min_price,max_price,city_id,
            type,min_area,max_area,min_piece,max_piece,willaya,condition,
            papier,specification,
            act_Type,
              gaz: featured.gaz,            // Explicitly set keys for these properties
            electricity: featured.electricity,
            furnished: featured.furnished,
            pool: featured.pool,
            garden: featured.garden,
            garage: featured.garage})).unwrap();
          
         const queryParams = new URLSearchParams();

         // Add only the params that have a value
         if (min_price) queryParams.append('min_price', min_price);
         if (max_price) queryParams.append('max_price', max_price);
         if (type) queryParams.append('type', type);
         if (min_area) queryParams.append('min_area', min_area);
         if (max_area) queryParams.append('max_area', max_area);
         if (willaya) queryParams.append('willaya', willaya);
         if (condition) queryParams.append('condition', condition);
         if (papier) queryParams.append('act_name', papier);
         if (specification) queryParams.append('specifications', specification);
         if(state_id) queryParams.append('state_id', state_id);
         if(city_id) queryParams.append('city_id', city_id);
         if(act_Type) queryParams.append('act_Type', act_Type);
         if (featured.gaz === 1) {
            queryParams.append('gaz', featured.gaz);
          }
         if (featured.electricity === 1) {
            queryParams.append('electricity', featured.electricity);
         }
         if (featured.furnished === 1) {
            queryParams.append('furnished', featured.furnished);
         }
         if (featured.pool === 1) {
            queryParams.append('pool', featured.pool);
         }
         if (featured.garden === 1) {
            queryParams.append('garden', featured.garden);
         }
          
         
         // Construct the full URL with query params
         const url = `/Filter?${queryParams.toString()}`;
         
         // Navigate to the URL
         navigate(url);
       
          
     
      }
       catch(e){}
       finally{
         setLoading(false);
       }
    
    }

    

    
   

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: savedLanguage!='ar' ? isOpen ? 0 : '-250px' : 0,
        right:savedLanguage==='ar'? isOpen ? 0 : '-250px' :0 ,
        width: { xs: isOpen ? '80%' : "0px", md: isOpen ? '400px' : "0px" },
        height: '100%',
        minHeight: '100vh',  // Ensure the height exceeds the viewport
        fontFamily: 'Mukta',
        fontWeight: 'normal',
        background: '#333',
        color: '#fff',
        transition: 'left 0.3s ease',
        padding: isOpen ? "0px" : '0px',
        zIndex: 1000,
        overflowY: 'auto', // Enable vertical scrolling
      }}
    >
      <CgCloseO  onClick={onClose} style={{fontSize:"2rem", position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
     <Typography style={{fontFamily:"Mukta",
      fontWeight:"600",marginTop:"10%",marginBottom:"3%",fontSize:"1.8rem",textAlign:"center"}}>{t('filter_title')}</Typography>
      
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>handleOpeNWillaya()}>{willaya ?? t('willaya') }</Typography>
         <FaAngleDown/>
         {states && states.length>0 &&
         <Box
         sx={{
            position: 'absolute',  // Absolute positioning inside the relative parent
            top: '100%',
            left:savedLanguage!='ar'? isOpen ? isOpenWillaya ?  "0%" : "-90%" : "-90%" :0,
            right:savedLanguage=='ar'? isOpen ? isOpenWillaya ?  "0%" : "-90%" : "-90%" :0 ,
            width: { xs: isOpenWillaya ? '80%' : "0px", md: isOpenWillaya ? '99%' : "0px" },
            maxHeight: '300px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
            
         {states.map((w, i) => (
            <Grid container key={i} onClick={()=>handleWillayaValue(w.id,w.name)}>
               <Grid item xs={2} md={2} sx={{display:"flex",padding:"10px",justifyContent:"end",alignItems:"end"}}>
                  <CiSquareCheck style={{fontSize:"1.2rem" ,marginTop:"-15px", color:state_id === w.id ? "white" : "transparent"}}/>
               </Grid>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} key={i}>{w.name}</Typography>
               </Grid>
               
            </Grid>
         ))}
         </Box>}

     
      </div>

      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>{
            setOpeCity(true);}}>{city ?? t('City') }</Typography>
         <FaAngleDown/>
       
         <Box
         sx={{
            position: 'absolute',  // Absolute positioning inside the relative parent
            top: '100%',
            left:savedLanguage!='ar'? isOpen ? isOpenCity ?  "0%" : "-90%" : "-90%" :0,
            right:savedLanguage=='ar'? isOpen ? isOpenCity ?  "0%" : "-90%" : "-90%" :0 ,
            width: { xs: isOpenCity ? '80%' : "0px", md: isOpenCity ? '99%' : "0px" },
            maxHeight: '300px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
             
         {cities.map((w, i) => (
            <Grid container key={i} onClick={()=>handleCityaValue(w.id,w.name)}>
               <Grid item xs={2} md={2} sx={{display:"flex",padding:"10px",justifyContent:"end",alignItems:"end"}}>
                  <CiSquareCheck style={{fontSize:"1.2rem" ,marginTop:"-15px", color:city_id === w.id ? "white" : "transparent"}}/>
               </Grid>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} key={i}>{w.name}</Typography>
               </Grid>
               
            </Grid>
         ))}
         </Box>

     
      </div>
     
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
      <div  class="side-bar-item-container">
         <div class='side-bar-item-price'>
            <Typography>{t('prix')}</Typography>
            <HiFilter />
         </div>
         
         <div  class='side-bar-item-price-field'>
         <Grid container>
                     <Grid item xs={3.5} md={3.5}  style={{marginLeft:"5px",marginRight:"5px",marginTop:"-4px",marginBottom:"-4px"}}>
                     <TextField
                        style={{ backgroundColor: '#333',color:"white" }}
                        margin="normal"
                        required
                        fullWidth
                        id="Min"
                        placeholder={t('min')}
                        name="Min"
                        value={min_price}
                        onChange={(e) => {
                           const value = e.target.value;
                           // Prevent negative values
                           if (value >= 0) {
                             setMinPrice(value);
                           }else{
                              setMinPrice(0);

                           }
                        
                         }}
                        
                        type="number"
                        InputProps={{
                           disableUnderline: true,
                           min: 0,
                           sx: {
                              '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              fontFamily: "Mukta",
                              fontWeight: "normal",
                              fontSize: "1rem",
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              },
                              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                              border: '2px solid red', // Custom error border
                              },
                              '& .MuiInputBase-input': {
                              padding: '10px 0', 
                              color:"white"
                           
                              
                              },
                           },
                        }}
                     />

                     </Grid>
                     <Grid item xs={3.5} md={3.5} style={{marginLeft:"5px",marginRight:"5px",marginTop:"-4px",marginBottom:"-4px"}}>
                     <TextField
                           style={{ backgroundColor: '#333' }}
                           margin="normal"
                           required
                           fullWidth
                           id="Max"
                           placeholder={t('max')}
                           name="Max"
                           type="number"
                           value={max_price}
                           onChange={(e) => {
                              const value = e.target.value;
                              // Prevent negative values
                              if (value >= 0) {
                                 setMaxPrice(value);
                              }else{
                                 setMaxPrice(0);}
                            }}
                           InputProps={{
                                 disableUnderline: true,
                                 min: 0,
                              
                                 sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                       border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                       border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       border: 'none',
                                    },
                                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                                       border: '2px solid red', // Custom error border
                                    },
                                    '& .MuiInputBase-input': {
                                       padding: '8px 0', 
                                          color:"white"
                                    },
                                 },
                           }}
                        />
                     </Grid>
             </Grid>
                  
         </div>
      </div>
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>handleOpenPriceType()}>{price_type_value ?? t('Da')}</Typography>
         <FaAngleDown/>
         <Box
         sx={{
            position: 'absolute',
            top: "100%",
            left: savedLanguage!='ar'  ? isOpen ? isOpenPriceType ?  "0%" : "-90%" : "-90%" : 0,
            right:savedLanguage=='ar' ? isOpen ? isOpenPriceType ?  "0%" : "-90%" : "-90%" : 0,
            width: { xs: isOpenPriceType ? '80%' : "0px", md: isOpenPriceType ? '99%' : "0px" },
            maxHeight: '300px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
          
         {price_type.map((w, i) => (
          <Grid container style={{cursor:"pointer"}} onClick={()=>handlePriceType(w)}>
             <Grid item xs={2} md={2} 
             sx={{display:"flex",padding:"10px",justifyContent:"end",alignItems:"end"}}>
                  <CiSquareCheck style={{fontSize:"1.2rem" , color:papier == w ? "white" :"transparent"}}/>
               </Grid>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} key={i}>{w}</Typography>
               </Grid>
              
            </Grid>
         ))}
         </Box>

     
      </div>

   
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>handleOpenType()}>{type ?? t('type') }</Typography>
         <Box
         sx={{
            position: 'absolute',
            top: "100%",
            
            left: savedLanguage!='ar'? isOpen ? isOpenType ?  "0%" : "-90%" : "-90%" : 0,
            right: savedLanguage=='ar'? isOpen ? isOpenType ?  "0%" : "-90%" : "-90%" : 0,
            width: { xs: isOpenType ? '80%' : "0px", md: isOpenType ? '99%' : "0px" },
            maxHeight: '300px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
         {type_list.map((w, i) => (
            <Grid container key={i} onClick={()=>{
               setOpenType(false);
               setType(w.value)}}>
              <Grid 
                  item 
                  xs={2} 
                  md={2} 
                  sx={{display: "flex", padding: "10px", justifyContent: "end", alignItems: "end"}}
               >
                  <CiSquareCheck style={{fontSize:"1.2rem" , color:type === w.value ? "white" : "transparent"}}/>
               </Grid>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} key={i}>{w.name}</Typography>
               </Grid>
            
            </Grid>
         ))}
         </Box>

     
      </div>

      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
      <div class="side-bar-item-container-superficie">
         <div class='side-bar-item-superficie'>
            <Typography>{t('superficie')}</Typography>
            <HiFilter />
         </div>
         
         <div class='side-bar-item-superficie-field'>
         <Grid container>
            <Grid item xs={3.5} md={3.5}  style={{marginLeft:"5px",marginRight:"5px",marginTop:"-4px",marginBottom:"-4px"}}>
            <TextField
               style={{ backgroundColor: '#333',color:"white" }}
               margin="normal"
               required
               fullWidth
               id="Min"
               placeholder={t('min')}
               name="Min"
               value={min_area}
               onChange={(e) => {
                  const value = e.target.value;
                  // Prevent negative values
                  if (value >= 0) {
                     setMinArea(value);
                  }else{
                     setMinArea(0);}
                  }}
               
               type="number"
               InputProps={{
                  disableUnderline: true,
                  min: 0,
                  sx: {
                     '& .MuiOutlinedInput-notchedOutline': {
                     border: 'none',
                     fontFamily: "Mukta",
                     fontWeight: "normal",
                     fontSize: "1rem",
                     },
                     '&:hover .MuiOutlinedInput-notchedOutline': {
                     border: 'none',
                     },
                     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     border: 'none',
                     },
                     '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                     border: '2px solid red', // Custom error border
                     },
                     '& .MuiInputBase-input': {
                     padding: '10px 0', 
                     color:"white"
                  
                     
                     },
                  },
               }}
            />

            </Grid>
            <Grid item xs={3.5} md={3.5} style={{marginLeft:"5px",marginRight:"5px",marginTop:"-4px",marginBottom:"-4px"}}>
            <TextField
                  style={{ backgroundColor: '#333' }}
                  margin="normal"
                  required
                  fullWidth
                  id="Max"
                  placeholder={t('max')}
                  name="Max"
                  type="number"
                  value={max_area}
                  onChange={(e) => {
                     const value = e.target.value;
                     // Prevent negative values
                     if (value >= 0) {
                        setMaxArea(value);
                     }else{
                        setMaxArea(0);}
                     }}
                  InputProps={{
                        disableUnderline: true,
                        min: 0,

                     
                        sx: {
                           '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                           },
                           '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                           },
                           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                           },
                           '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                              border: '2px solid red', // Custom error border
                           },
                           '& .MuiInputBase-input': {
                              padding: '8px 0', 
                                 color:"white"
                           },
                        },
                  }}
               />
            </Grid>
         </Grid>
                  
         </div>
      </div>
    
   
   
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
    
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>handleOpenSpecification()}>{t('specification')} {counter !== 0 ? `( ${counter} )` : ''}
         </Typography>
         <FaAngleDown/>
         <Box
         sx={{
            position: 'absolute',
            top: "100%",
            left:savedLanguage!='ar' ? isOpen ? isOpenSpecification ?  "0%" : "-90%" : "-90%" : 0,
            right:savedLanguage=='ar' ? isOpen ? isOpenSpecification ?  "0%" : "-90%" : "-90%" : 0,
            width: { xs: isOpenSpecification ? '80%' : "0px", md: isOpenSpecification ? '99%' : "0px" },
            maxHeight: '200px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
             <Grid container>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} >{t('close')}</Typography>
               </Grid>
               <Grid item xs={6} md={6} sx={{display:"flex",padding:"10px",justifyContent:"end",alignItems:"end"}}>
                  <CgCloseO onClick={()=>setOpenSpecification(false)} style={{fontSize:"1.2rem",cursor:"pointer" , color:"white"}}/>
               </Grid>
            </Grid>
            {Object.entries(featured).map(([key, value], i) => (
            <Grid 
               container 
               key={i} 
               style={{cursor: "pointer"}} 
               onClick={() => handleClick(key)}
            >
                 <Grid 
                  item 
                  xs={2} 
                  md={2} 
                  sx={{display: "flex", padding: "10px", justifyContent: "end", alignItems: "end"}}
               >
                  <CiSquareCheck 
                  style={{
                     fontSize: "1.2rem", 
                     color: value === 1 ? "white" : "transparent",
                  }}
                  />
               </Grid>
               <Grid item xs={6} md={6}>
                  <Typography style={{padding: "10px"}}>{t(key)}</Typography>
               </Grid>
             
            </Grid>
            ))}

         </Box>

     
      </div>
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
   
      <div className='side-bar-item' style={{ position: 'relative' }}>
         <Typography onClick={()=>handleOpenPapier()}>{papier ?? t('papier')}</Typography>
         <FaAngleDown/>
         <Box
         sx={{
            position: 'absolute',
            top: "100%",
            left: savedLanguage!='ar'  ? isOpen ? isOpenPapier ?  "0%" : "-90%" : "-90%" : 0,
            right:savedLanguage=='ar' ? isOpen ? isOpenPapier ?  "0%" : "-90%" : "-90%" : 0,
            width: { xs: isOpenPapier ? '80%' : "0px", md: isOpenPapier ? '99%' : "0px" },
            maxHeight: '300px',  // Set a max height for the box
            fontFamily: 'Mukta',
            fontWeight: 'normal',
            background: '#333',
            border: "1px solid grey",
            color: 'white',
            transition: 'left 0.3s ease',
            padding: isOpen ? "0px" : '0px',
            zIndex: 1000,
            overflowY: 'auto',             
            overflowX: 'hidden',           
         }}
         >
          
         {acts.map((w, i) => (
          <Grid container style={{cursor:"pointer"}} onClick={()=>handleActType(w.id,w.name)}>
             <Grid item xs={2} md={2} 
             sx={{display:"flex",padding:"10px",justifyContent:"end",alignItems:"end"}}>
                  <CiSquareCheck style={{fontSize:"1.2rem" , color:papier == w.name ? "white" :"transparent"}}/>
               </Grid>
              <Grid item xs={6} md={6}>
                  <Typography style={{padding:"10px"}} key={i}>{w.name}</Typography>
               </Grid>
              
            </Grid>
         ))}
         </Box>

     
      </div>
      <div style={{background:"grey",width:"100%",height:"1.5px"}}/>
      
  
      
      <div className='side-bar-item'>
           <Button
                style={{ width: '80%', marginTop: '1rem', padding: "10px", justifyContent: "center", alignItems: "center", borderRadius: "25px" }}
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={loading} 
                sx={{ margin: 3, justifyContent: "center", textTransform: 'none', alignItems: "center", bgcolor: '#E4180B', borderRadius: "25px", fontFamily: "Mukta-BOLD" }}
            >
                {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : t('filter')}
            </Button>
      </div>     
    </Box>
  );
};

export default Sidebar;
