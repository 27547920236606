// data_slice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../Config'; 
const savedLanguage = localStorage.getItem('language') || 'fr';
export const fetchProductByCategory = createAsyncThunk(
  'categories/fetchProductByCategory',
  async (category_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/category-products/${category_id}`,{params: {"lang": savedLanguage}});
      
      console.log("fetch translated data", response.data);
      
      // Return response.data directly, not response.data.data
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch products');
    }
  }
);

export const fetchcategoryById = createAsyncThunk(
  'categories/fetchCategoryById',
  async (category_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/categories/${category_id}`,{params: {"lang": savedLanguage}});
      
      console.log("-----1----categoryBy from server", response.data);
      
      // Return response.data directly, not response.data.data
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch category');
    }
  }
);

export const fetchcategories = createAsyncThunk(
  'categories/fetchcategories',
  async ( ) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/categories`,{params: {"lang": savedLanguage}});
      
      console.log("-----1----categories from server", response.data);
      
      // Return response.data directly, not response.data.data
      return response.data;
    } catch (error) {
      console.log(error.response?.data);
    }
  }
);




const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    productsByCategory: [],
    categories: [],
    category: null,
    status: 'idle',
    error: null,
    categoriesStatus: 'idle',
    categoriesError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchcategories.pending, (state) => {
      state.categoriesStatus = 'loading';
    })
    .addCase(fetchcategories.fulfilled, (state, action) => {
     
      state.categoriesStatus = 'succeeded';
      if(action.payload && action.payload.data){
        state.categories = action.payload.data;
      }
       
    })
    
    .addCase(fetchcategories.rejected, (state, action) => {
      state.categoriesStatus = 'failed';
      state.categoriesError = action.payload || action.error.message;
    })
      .addCase(fetchProductByCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsByCategory = action.payload.data.data; // Directly accessing action.payload.data
        console.log("-----222222----fetch list products categories from server", state.productsByCategory);
      })
      .addCase(fetchProductByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchcategoryById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchcategoryById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.category = action.payload.data; // Directly accessing action.payload.data
        console.log("-----2----fetch list categoryById from server", state.category);
      })
      .addCase(fetchcategoryById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default categoriesSlice.reducer;

