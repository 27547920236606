import React from "react";
import Slider from 'react-slick'; // Import Slider from react-slick
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import "../../Styles/ImageSlider.css"; 
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// Define custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-arrow-next`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
    <PlayArrowIcon className="arrow-icon"  style={{   color: 'black' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-arrow-prev`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon"   style={{ transform: 'rotate(-60deg)' }} />
    </div>
  );
}

const ImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
  
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />, // Use custom arrow components
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Box 
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        position: 'relative', // Ensure positioning for arrows
      }}
    >
      <Slider {...settings} className="image-slider">
        {images.map((image, index) => (
          <Box
            key={index}
            className="slider-div"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#D9D9D9',
              backgroundImage: `url(${image.original_url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",

               width: '100%',
                height: {xs:"200px",md:"380px"},
                display: 'block',
              
            }}
          >
           
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlider;
