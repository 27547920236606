import * as React from 'react';
import {Box,Button,IconButton,Typography,TextField,
  Slider, FormControl,
  InputLabel,
  Grid,} from '@mui/material';
import '../Styles/SharedStyle.css'
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {addProduct,fetchAct} from '../Redux_Managment/products_slice';
import { fetchcategories } from '../Redux_Managment/categories_slice';

import Step1 from '../Components/Add_Product_Component/step1';
import Step2 from '../Components/Add_Product_Component/step2';
import Step3 from '../Components/Add_Product_Component/step3';
import Step4 from '../Components/Add_Product_Component/step4';
import Step5 from '../Components/Add_Product_Component/step5';
import { useTranslation } from 'react-i18next';


export default function AddProduct() {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {categories,state,error, }=useSelector(state=>state.categories);
  const {acts, }=useSelector(state=>state.products);
  const [activateStep,setActivateStep]=React.useState(0);
  const [category_id, setCategoryId] = useState();
  const [categoryName, setcategoryName] = useState();
  
 
  const [act_id, setActId] = useState();
  const [actName, setActName] = useState();

  const [country_id,setCountryId]=useState(4);
  const [country_Name,setCountryName]=useState();

  const [city_id,setCityId]=useState();
  const [city_Name,setCityName]=useState();

  const [state_id,setStateId]=useState();
  const [state_Name,setStateName]=useState();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [address, setAddress] = useState();
  const [area, setArea] = useState();
  const [type, setType] = useState(1);
  const [status, setStatus] = useState("New");

  const [price, setAPrice] = useState();
  const [min_price, setMinPrice] = useState();
  const [sell_price, setSellPrice] = useState();
  
  const [price_negotiable, setNegoPrice] = useState(1);
  const [num_of_rooms, setNumRoom] = useState();
  const [num_of_floors, setNumFloor] = useState();
  const [floor, setFloor] = useState();
  const [date_of_construction, setDateOfconstruction] = useState(() => {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0]; // Formats the date as "YYYY-MM-DD"
  });

  const [productCoverImage, setProductCoverImage] = useState(null);
  const [productBanners, setProductBnners] = useState([]);
  const [loading,setLoading]=useState(false);

  const [features, setFeatures] = useState({
    pool: false,
    electricity: false,
    gas: false,
    garden: false,
    garage: false,
    furnished: false,
  });

  // Function to handle switch changes
 
  
  const handleAddProduct = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
  
      // Append text fields
      formData.append('title', title);
      formData.append('description', description);
      formData.append('address', address);
      formData.append('area', area);
      formData.append('act_type', act_id);
      formData.append('type', type === 1 ? "Rent" : type === 2 ? "Sale" : type === 3 ? "Exchange" : "unknown");
      formData.append('status', status);
      formData.append('price', price);
      formData.append('min_price', min_price);
      formData.append('sell_price', sell_price);
      formData.append('price_negotiable', price_negotiable);
      formData.append('num_of_rooms', num_of_rooms);
      formData.append('num_of_floors', num_of_floors);
      formData.append('floor', floor);
      formData.append('date_of_construction', date_of_construction);
      formData.append('country_id', country_id);
      formData.append('state_id', state_id);
      formData.append('city_id', city_id);
      formData.append('category_id', category_id);
      
      // Append features
      formData.append('pool', features.pool ? 1 : 0);
      formData.append('electricity', features.electricity ? 1 : 0);
      formData.append('gas', features.gas ? 1 : 0);
      formData.append('garden', features.garden ? 1 : 0);
      formData.append('garage', features.garage ? 1 : 0);
      formData.append('furnished', features.furnished ? 1 : 0);
  
      // Append images
      if(productBanners.length>0){
        productBanners.forEach((image, index) => {
          formData.append(`images[${index}]`, image); // Assuming productBanners is an array of File objects
        });
      }
  
      await dispatch(addProduct(formData)).unwrap();
  
      navigate('/', { replace: true });
      console.log('Product added successfully');
    } catch (err) {
      if (err.response && err.response.data) {
        console.error('API Error:', err.response.data);
      } else {
        console.error('An unexpected error occurred:', err);
      }
    } finally {
      setLoading(false);
    }
  };
  
  


  
  
  const selectCategory=(category_id,category_name)=>{
    setCategoryId(category_id);
    setcategoryName(category_name);
    setActivateStep(1);
  }

  const selectAct=(act_id,act_name)=>{
    setActId(act_id);
    setActName(act_name);
  }

  useEffect(() => {
   
    dispatch(fetchcategories());
    dispatch(fetchAct());
   
   }, [dispatch]);


 





  const steps = [
    {
     
     content: <Step1   
     categories={categories} 
     selectCategory={selectCategory} 
     categoryName={categoryName} 
     setActivateStep={setActivateStep}/>
    },
    {
      
       content: <Step2 
       setActivateStep={setActivateStep}
       title={title} 
       setTitle={setTitle}
       description={description}
       setDescription={setDescription}
       address={address}
       setAddress={setAddress}
       area={area}
       setArea={setArea}
       type={type}
       setType={setType}
       status={status}
       setStatus={setStatus}
       price={price}
       setAPrice={setAPrice}
       min_price={min_price}
       setMinPrice={setMinPrice}
       sell_price={sell_price}
       setSellPrice={setSellPrice}
       price_negotiable={price_negotiable}
       setNegoPrice={setNegoPrice}
       num_of_rooms={num_of_rooms}
       setNumRoom={setNumRoom}
       num_of_floors={num_of_floors}
       setNumFloor={setNumFloor}
       floor={floor}
       setFloor={setFloor}
       date_of_construction={date_of_construction}
       setDateOfconstruction={setDateOfconstruction}

     />
     
      },
    {
    
       content: <Step3  setActivateStep={setActivateStep}
       setCountryId={setCountryId}
       country_id={country_id}
       country_name={country_Name}
       state_id={state_id}
       state_name={state_Name}
       city_name={city_Name}
       city_id={city_id}
       setCountryName={setCountryName}
       setStateId={setStateId}
       setStateName={setStateName}
       setCityId={setCityId} 
       setCityName={setCityName}/> 
     },  
      {
       
       content:
       <Step4 setActivateStep={setActivateStep} setCover={setProductCoverImage} setBanners={setProductBnners}/>
      },  
      {
       
        content:  <Step5 acts={acts} actName={actName} selectAct={selectAct} setActivateStep={setActivateStep} features={features} setFeatures={setFeatures} addProduct={handleAddProduct} loading={loading}/>
   
       },
   
   ];
  return (

    <div className='Global-div' style={{justifyContent:"start",alignItems:"center"}}>
     
          <Typography variant="h5" sx={{  textAlign:"center", fontFamily:"Mukta",  color:"#2573e9", justifyContent:"center",  paddingTop:"20px",   paddingBottom:"5px"}}>
                  {t('create_product')}
          </Typography>   
          <Box   
            sx={{
              width: "80%",
              justifyContent:"center",
              alignItems:"center",
              paddingLeft:"5%",
              paddingRight:"5%",
              paddingBottom:"10px",
              background: "#ECECF4",
              paddingTop:"10px",
              display:"flex"
            }}> 
            {steps[activateStep].content}

            
            
          </Box>
      

    </div>
  
  );
}
