
import React from "react";
import {Box,Typography} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAgencies  } from "../Redux_Managment/agencies_slice";

import AgenciesGrid from "../Components/Agency/agencies_grid";
import LoadingWidget from "../Components/Global_components/loading_widget";
import EmptyWidget from "../Components/Global_components/empty_widget";
 export default function AgenciesPage() {
    const dispatch=useDispatch();
    const {agencies,status}=useSelector((state)=>state.agencies || {})
    const {t,i18n}=useTranslation();
    useEffect(() => {
        dispatch(fetchAgencies(1));
    }, [dispatch]);
    const handlePageChange = (page) => {
        dispatch(fetchAgencies(page));
    };


    return (
        <div style={{ display: "flex",
            flexDirection: "column", justifyContent: "center", alignItems: "center", background: "white", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" }}>
            
            <Box sx={{
                background: "#ECECF4",
                width: "100%",
                paddingBottom:"10px"
            }}>

             <Typography variant="h5" sx={{
                textAlign:"center",
                color:"#E4180B",
                fontFamily:"Mukta",
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>{t('agency_page_title')}</Typography>   
             <Typography  sx={{
                textAlign:"center",
                fontSize:"14px",
                width:"80%",
                padding:"0 10%",
                justifyItems:"center",
                alignItems:"center",
                fontFamily:"Mukta",
                justifyContent:"center",paddingTop:"5px",paddingBottom:"20px"}}>
                    {t('agency_page_sub_title')}</Typography>   


                    {status == 'loading' && <LoadingWidget/>}
            {status == 'failed' && 
            <div width="90%"><EmptyWidget/></div>}
            {status == 'succeeded' &&     

               agencies && agencies.length>0 && <AgenciesGrid agencies={agencies} />}

           
              
           </Box>
        </div>
    );
}