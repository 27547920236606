import { Box,Grid,Typography,IconButton } from "@mui/material";
import React, { useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Avatar } from '@mui/material';
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useTranslation } from "react-i18next";


export default function Step1({categories,selectCategory,categoryName,setActivateStep}) {
    const [expandedCategories, setExpandedCategories] = useState({});
    const { t } = useTranslation();
    const checkSubCategories = (categoryId) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId] 
        }));
    };
    const CategoryItem = ({ category, checkSubCategories, expandedCategories }) => {
        const isExpanded = expandedCategories[category.id] || false;
    
        return (
            <div style={{ cursor:"pointer",width: "95%", display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center" }}>
             <div
                  onClick={() => {
                      if (category.descendants && category.descendants.length > 0) {
                          checkSubCategories(category.id);
                      } else {
                          selectCategory(category.id, category.name);
                      }
                  }}
                  style={{
                      width: "100%", // Full width
                      padding: "1%",
                      borderBottom: "0.5px solid #ececf4",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                  }}
              >
    
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar alt="Category Icon" src={category.icon?.original_url} sx={{ width: 40, height: 40, margin: "5px" }} />
                        <p style={{ fontSize: "0.9rem", fontFamily: "Mukta-Bold" }}>{category.name}</p>
                    </div>
                    {  category.descendants && category.descendants.length>0 &&<MdOutlineArrowDropDown style={{ fontSize: "1.5rem" }} />}
                </div>
    
                {isExpanded && category.descendants && category.descendants.map((subCategory, subIndex) => (
                   <div key={subIndex} style={{width:"100%",margin:"1px",display:"flex",
                   justifyContent:"center",alignContent:"center",alignItems:"center",fontFamily:"Mukta",fontSize:"0.8rem"}}>
                      <CategoryItem 
                        key={subIndex} 
                        category={subCategory} 
                        checkSubCategories={checkSubCategories} 
                        expandedCategories={expandedCategories} 
                    />
                   </div>
                ))}
            </div>
        );
    };

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background: "white",
            borderRadius: "10px",
        }}>


            <Grid container style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                marginTop:"3%",
                justifyContent: "center",
                alignItems: "center"
            }}>
              <Grid item xs={12} md={6} justifyContent={"center"} display={"flex"} textAlign={{xs:"center" , md:"start"}}>
                 
                <Typography sx={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold", }}>
             
                    {t('add_product_s1')}</Typography>
             
              </Grid>  
              
              <Grid item xs={12} md={6} background="red" justifyContent={{xs:"center" , md:"end"}} alignItems={{xs:"center" , md:"end"}}>
          
              {categoryName && <Box
              onClick={()=>{setActivateStep(1)}}
                sx={{
                    cursor: "pointer",
                        paddingLeft: "2%",
                        paddingRight: "2%",
                        display: "flex",
                        justifyContent: {xs:"center", md : "end",},
                        alignItems: {xs:"center", md :"flex-end",} ,
                        textAlign: {xs:"center", md : "end",}
                    }}>
                     <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold",color:"#ff574b" }}>
                      {t('next_btn')}
                    
                     
                     </Typography>
                   
                        
                 
                   
                </Box>}
              </Grid>


            </Grid>
            <Box sx={{width:{ xs: "100%", md: "90%",textAlign:"center"}}}> 
                { categoryName && <Typography style={{ fontSize: {xs: "0.7rem",md: "1rem"}, 
                    fontFamily: "Mukta",color:"#2573e9" ,textAlign:{xs:"center",md:"start"}}}>
                {t('selected_category')}: {categoryName}</Typography>}
            </Box>
           
            <Box sx={{
                width: { xs: "100%", md: "90%" },
                height: { xs: "500px", md: "500px" }, // Adjust height as needed
                display: "flex",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                overflowY: "auto" // Enable vertical scrolling
            }}>
               {categories && categories.length > 0 && categories.map((category, index) => (
                  <CategoryItem 
                      key={index} 
                      category={category} 
                      checkSubCategories={checkSubCategories} 
                      expandedCategories={expandedCategories}
                  />
              ))}
            </Box>
       </Box>
    );
}