import * as React from 'react';
import {Box,Button,IconButton,Typography,TextField,
  Slider, FormControl,
  InputLabel,
  Grid,} from '@mui/material';
import '../Styles/SharedStyle.css'
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {editProduct,fetchAct} from '../Redux_Managment/products_slice';
import { fetchcategories } from '../Redux_Managment/categories_slice';
import { useLocation } from 'react-router-dom';
import Step1 from '../Components/Add_Product_Component/step1';
import Step2 from '../Components/Add_Product_Component/step2';
import Step3 from '../Components/Add_Product_Component/step3';
import Step4 from '../Components/Add_Product_Component/step4';
import Step5 from '../Components/Add_Product_Component/step5';
import { useTranslation } from 'react-i18next';




export default function EditProduct() {

  const dispatch=useDispatch();
  const navigate=useNavigate();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const { product } = location.state || {};
  const {categories,state,error, }=useSelector(state=>state.categories);
  const {acts, }=useSelector(state=>state.products);
  const [activateStep,setActivateStep]=React.useState(0);
  const [category_id, setCategoryId] = useState(product.category?.id );
  const [categoryName, setcategoryName] = useState(product.category?.name);

  const [act_id, setActId] = useState(product?.act_type?.id);
  const [actName, setActName] = useState(product?.act_type?.name);

  const [country_id,setCountryId]=useState(4);
  const [country_Name,setCountryName]=useState();

  const [city_id,setCityId]=useState(product.city_id);
  const [city_Name,setCityName]=useState();

  const [state_id,setStateId]=useState(product.state_id);
  const [state_Name,setStateName]=useState();

  const [title, setTitle] = useState(product.title ?? "");
  const [description, setDescription] = useState(product.description ?? "");
  const [address, setAddress] = useState(product.address ?? "");
  const [area, setArea] = useState(product.area ?? 0);
  const [type, setType] = useState(product.type =="Rent"  ? 1 : type == "Sale"?2 : type =="Exchange"?3  : 1);
  const [status, setStatus] = useState(product.status??"New");

  const [price, setAPrice] = useState(product.price ?? 0);
  const [min_price, setMinPrice] = useState(product.min_price??0);
  const [sell_price, setSellPrice] = useState(product.sell_price??0);
  
  const [price_negotiable, setNegoPrice] = useState(product.price_negotiable ?? 1);
  const [num_of_rooms, setNumRoom] = useState(product.num_of_rooms ?? 0);
  const [num_of_floors, setNumFloor] = useState(product.num_of_floors ?? 0);
  const [floor, setFloor] = useState(product.floor ?? 0);
  const [imgsUrl,setImagesUrl]=useState(product.images_collection ?? []);
  const [deleteImgs,setDeleteImages]=useState([]);
  const [date_of_construction, setDateOfconstruction] = useState(() => {
    const currentDate = new Date();
    return product.date_of_construction ?? currentDate.toISOString().split('T')[0]; // Formats the date as "YYYY-MM-DD"
  });

  const [productCoverImage, setProductCoverImage] = useState(null);
  const [productBanners, setProductBnners] = useState([]);
  const [loading,setLoading]=useState(false);

  const [features, setFeatures] = useState({
    pool:product.pool == 1 ? true : false,
    electricity:product.electricity == 1 ? true : false,
    gas:product.gaz == 1 ? true : false,
    garden:product.garden == 1 ? true : false,
    garage:product.garage == 1 ? true : false,
    furnished:product.furnished == 1 ? true : false,
  });

  // Function to handle switch changes
 
  

  const handleEditProduct = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      console.log(product.id);
  
      // Append text fields
      formData.append('title', title);
      formData.append('product_id',product.id)
      formData.append('description', description);
      formData.append('address', address);
      formData.append('area', area);
      formData.append('act_type', act_id);
      formData.append('type', type === 1 ? "Rent" : type === 2 ? "Sale" : type === 3 ? "Exchange" : "unknown");
      formData.append('status', status);
      formData.append('price', price);
      formData.append('min_price', min_price);
      formData.append('sell_price', sell_price);
      formData.append('price_negotiable', price_negotiable);
      formData.append('num_of_rooms', num_of_rooms);
      formData.append('num_of_floors', num_of_floors);
      formData.append('floor', floor);
      formData.append('date_of_construction', date_of_construction);
      formData.append('country_id', country_id);
      formData.append('state_id', state_id);
      formData.append('city_id', city_id);
      formData.append('category_id', category_id);
      
      // Append features
      formData.append('pool', features.pool ? 1 : 0);
      formData.append('electricity', features.electricity ? 1 : 0);
      formData.append('gas', features.gas ? 1 : 0);
      formData.append('garden', features.garden ? 1 : 0);
      formData.append('garage', features.garage ? 1 : 0);
      formData.append('furnished', features.furnished ? 1 : 0);

      if(productBanners.length>0){
        productBanners.forEach((image, index) => {
     
          formData.append(`images[${index}]`, image); // Assuming productBanners is an array of File objects
        });
      }

      if(deleteImgs.length>0){
        deleteImgs.forEach((id, index) => {
          console.log('img2222',id);
          formData.append(`delete_images[${index}]`, id); // Assuming productBanners is an array of File objects
        });
      }
  
    

      
      
     
  
     await dispatch(editProduct(formData)).unwrap();
  
     navigate('/', { replace: true });
     // console.log('Product added successfully');
    } catch (err) {
      if (err.response && err.response.data) {
        console.error('API Error:', err.response.data);
      } else {
        console.error('An unexpected error occurred:', err);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleEditProducts = async () => {
    setLoading(true);
    try {
      await dispatch(editProduct({
        title,
        product_id:product.id,
        description,
        address,
        area,
        act_type:act_id,
        type:type == 1 ? "Rent" : type == 2 ? "Sale" : type == 3 ? "Exchange" : "unknown",
        status,
        price,
        min_price,
        sell_price,
        price_negotiable,
        num_of_rooms,
        num_of_floors,
        floor,
        date_of_construction,
        productCoverImage,
        productBanners,
        category_id,
        pool: features.pool ? 1 : 0,
        electricity: features.electricity ? 1 : 0,
        gas: features.gas ? 1 : 0,
        garden: features.garden ? 1 : 0,
        garage: features.garage ? 1 : 0,
        furnished: features.furnished ? 1 : 0,
        images: productBanners,
        country_id,
        state_id,
        city_id,
      })).unwrap();

      navigate('/', { replace: true });
      
      // Handle successful addition here
      console.log('Product added successfully');
    } catch (err) {
      if (err.response && err.response.data) {
        console.error('API Error:', err.response.data);
        // You might want to set some state here to display the error to the user
      } else {
        console.error('An unexpected error occurred:', err);
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const selectCategory=(category_id,category_name)=>{
    setCategoryId(category_id);
    setcategoryName(category_name);
    setActivateStep(1);
  }

  const selectAct=(act_id,act_name)=>{
    setActId(act_id);
    setActName(act_name);
  }

  useEffect(() => {
    console.log("before call categories");
    dispatch(fetchcategories());
    dispatch(fetchAct());
    console.log("acts are ",acts);
   }, [dispatch]);


 





  const steps = [
    {
     label: 'Identify Your Category',
     content: <Step1 categories={categories} setActivateStep ={setActivateStep} selectCategory={selectCategory} categoryName={categoryName}/>
    },
    {
       label: 'Identify Your Category',
       content: <Step2 
       setActivateStep={setActivateStep}
       title={title} 
       setTitle={setTitle}
       description={description}
       setDescription={setDescription}
       address={address}
       setAddress={setAddress}
       area={area}
       setArea={setArea}
       type={type}
       setType={setType}
       status={status}
       setStatus={setStatus}
       price={price}
       setAPrice={setAPrice}
       min_price={min_price}
       setMinPrice={setMinPrice}
       sell_price={sell_price}
       setSellPrice={setSellPrice}
       price_negotiable={price_negotiable}
       setNegoPrice={setNegoPrice}
       num_of_rooms={num_of_rooms}
       setNumRoom={setNumRoom}
       num_of_floors={num_of_floors}
       setNumFloor={setNumFloor}
       floor={floor}
       setFloor={setFloor}
       date_of_construction={date_of_construction}
       setDateOfconstruction={setDateOfconstruction}
     />
     
      },
 

      {
        label: 'Provide Form Details',
        content: <Step3  setActivateStep={setActivateStep}
        country_id={country_id}
       
         state_id={state_id}
         state_name={state_Name}
         city_name={city_Name}
         city_id={city_id}
         setCountryName={setCountryName}
         setStateId={setStateId}
         setStateName={setStateName}
         setCityId={setCityId} 
         setCityName={setCityName}/> 
       },  
       {
        label: 'Specify Desired Features',
        content: <Step4 setActivateStep={setActivateStep}  setBanners={setProductBnners} imgsUrl={imgsUrl} setDeleteImages={setDeleteImages} setImagesUrl={setImagesUrl}/>
       },  
       {
         label: 'Specify Desired Features',
         content:  <Step5 acts={acts} actName={actName} selectAct={selectAct} setActivateStep={setActivateStep} features={features} setFeatures={setFeatures} addProduct={handleEditProduct} loading={loading}/>
    
        },
   
   ];

  
  return (

    <div className='Global-div' style={{justifyContent:"start",alignItems:"center"}}>
     

           
            <Typography variant="h5" sx={{
                textAlign:"center",
                fontFamily:"Mukta",
                color:"#2573e9",
                
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>
                    {t('edit_product')}
            </Typography>   
         
       <Box   
          sx={{
            width: "80%",
            justifyContent:"center",
            alignItems:"center",
            paddingLeft:"5%",
            paddingRight:"5%",
            paddingBottom:"10px",
            background: "#ECECF4",
            paddingTop:"10px",
            display:"flex"
           }}> 
           {steps[activateStep].content}

           
           
        </Box>
      

    </div>
  
  );
}
