// src/app/store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux_Managment/auth_slice';
import categoriesReducer from './Redux_Managment/categories_slice'; 
import productsReducer from './Redux_Managment/products_slice';
import dashboardReduces from './Redux_Managment/dashboard_slice';
import agencyReducer from './Redux_Managment/agencies_slice';
import filterReducer from './Redux_Managment/search_filter_slice';
import locationReduce from './Redux_Managment/location_slice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    products: productsReducer, 
    agencies: agencyReducer,
    filter: filterReducer,
    location:locationReduce,
    dashboard: dashboardReduces
  },
});

export default store;
