
import { Box, Grid, Typography } from "@mui/material";
import { IoMdTime } from "react-icons/io";
import Avatar from '@mui/material/Avatar';
import 'slick-carousel/slick/slick.css';
import { MdOutlineFavorite } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { CiBoxList } from "react-icons/ci";
import "../../Styles/FeaturedItemCardStyle.css";
import { RiFloodLine } from "react-icons/ri";
import { TbArrowAutofitWidth } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {useState} from "react";
import { toggleFavProduct } from "../../Redux_Managment/products_slice";
import { useTranslation } from "react-i18next";

export default function ItemCard({product,isFav,agency_details}) {
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const { t } = useTranslation();

    const handleProductClick = () => {
    
      navigate('/description', { state: { product,agency_details } });
    };
    const handleAgencyClick = (agency_id,agency_name) => {
        
        navigate(`/Agency-Detail/${agency_name}`, { state: { agency_id,agency_name } });
      };
    const [isFavorited, setIsFavorited] = useState(false);

    const handleTggleFavClick = async(product_id) => {
      setIsFavorited(!isFavorited);
      try{
        await dispatch(toggleFavProduct({product_id})).unwrap();
      }
      catch(err){
        console.log(err);}
    };

    return (

        <Box 
        onClick={() => handleProductClick()}
      
        sx={{
                cursor:"pointer",
                maxWidth:{ xs: "100%", lg: "90%", xl: "80%", md: "95%",sm:"95%" },
                marginTop: "20px",
                marginLeft: { xs: "1px", md: "5px",lg:"10px",sm:"15px" },
                marginBottom:"5px",
                backgroundColor:"red",
                height: "100%",
                background: "white",
                display: "flex",
                flexDirection: "column", // Arrange children in a column
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                borderRadius: "10px",
            }}
        >
           <Grid
              
                style={{
                    width: "100%",
                    height: "170px", 
                    position: "relative",
                    backgroundImage: product.images_collection && product.images_collection.length > 0 && product.images_collection[0].original_url 
                        ? `url(${product.images_collection[0].original_url})` 
                        : 'url(default-image-url)', // Replace 'default-image-url' with a fallback image URL or handle the case as needed
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    overflow: "hidden",
                    display: "flex",
                    backgroundColor: "white",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start",
                }}
>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,

                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",                                         zIndex: 1, 
                    }}
                />
                
                <div style={{ position: "relative", zIndex: 2, width: "97%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", padding: "7px" }}>
                        <div
                            style={{
                                height: "25px",
                                width: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "5px",
                                fontSize: "11px",
                                backgroundColor: "green",
                                borderRadius: "50%",
                                padding: "1px",
                            }}
                        >
                            <IoMdTime style={{ color: "white", width: "25px", height: "25px" }} />
                        </div>
                        <div style={{ 
                            width: "auto", 
                            textAlign: "center", 
                            color: "white", 
                            margin: "3px", 
                            marginTop: "7px", 
                            fontSize: "11px", 
                            backgroundColor: "rgba(0, 0, 0, 1.1)", 
                            borderRadius: "15px", 
                            padding: "2px 5px 0px 5px", // Adjusted padding
                            height: "23px" 
                            }}>
                        <p style={{ 
                        fontFamily: "Mukta", 
                        textAlign: "center", 
                        fontSize: "11px", 
                        margin: "0", 
                        lineHeight: "23px" // Match this with the div's height
                        }}> {product.type==="Rent"? t('rent'):product.type==="Sale" ? t('sale') :product.type==="Exchange"? t('exchange'): t('sale')}</p>
                               </div>

                        <div style={{ 
                        width: "auto", 
                        textAlign: "center", 
                        color: "white", 
                        margin: "3px", 
                        marginTop: "7px", 
                        fontSize: "11px", 
                        backgroundColor: "rgba(0, 0, 0, 0.5)", 
                        borderRadius: "15px", 
                        padding: "2px 5px 0px 5px", // Adjusted padding
                        height: "23px" 
                        }}>
                        <p style={{ 
                        fontFamily: "Mukta", 
                        textAlign: "center", 
                        fontSize: "11px", 
                        margin: "0", 
                        lineHeight: "20px" // Match this with the div's height
                        }}>{product.category.name}</p>
                        </div>

                    </div>

                    <Box    
                sx={{
                    height: {xs:"12vh",md:"10vh",lg:"11vh"},
                
                }}></Box>

                       {product.agency && (<div onClick={(e) => 
                        {
                            e.stopPropagation();
                            handleAgencyClick(product.agency.id,product.agency.name)}} style={{ display: "flex", flexDirection: "row", padding: "7px", justifyContent: "space-between" }}>
                    
                            <Avatar
                            alt="Avatar"
                            src={product.agency.logo.original_url}
                            sx={{ width: 40, height: 40 }}
                        />
                           <Typography style={{ fontSize: "12px", color: "white", fontFamily: "Mukta", fontWeight: "500", paddingLeft: "5px",paddingTop:"9px" }}>{product.agency.name}</Typography>
                       
                       

                       
                        {/* <div style={{ display: "flex", flexDirection: "row", paddingTop: "15px" }}>
                            <FaRegCalendar style={{ color: "white", fontSize: "15px" }} />
                          
                         </div> */}

                    </div> )}
                </div>
            </Grid>
                            
            <Grid style={{ justifyContent: "center",background:"white",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px" ,alignItems: "center", width: "100%", padding: "5%" }}>
                <p  className="feature-item-style">{product.title} </p>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "2%" }}>
                    <IoLocationSharp style={{ color: "red" }} />
                    <Typography className="feature-second-item-style" style={{ fontSize: "12px" }}>{product.address}</Typography>
                </div>
        
                <div style={{ display: "flex", flexDirection: "row", marginTop: "7%" }}>
                    <div className="icon-box" onClick={(e) => {
                        e.stopPropagation();
                        handleTggleFavClick(product.id)}}>
                    
                        <MdOutlineFavorite  style={{ color:(isFav|| isFavorited) ? 'red' :"grey", cursor: 'pointer' }} className="fav-icon"  />
                    </div>
                    <div className="icon-box">
                        <CiBoxList style={{ color: "#979797", justifyContent: "center", alignItems: "center" }} />
                    </div>
        
                    <Typography style={{ color: "red", margin: "2%", fontWeight: "bold", fontSize: "16px" }}>{product.price} Da</Typography>
                </div>




                <Grid  container spacing={0} style={{ marginTop: "7%",width:"100%" ,display: "flex", alignItems: "center",textAlign: "center"}}>
                {/* First row */}
                <Grid item   xs={4} sm={4} style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",padding:"5px"  }}>
                        {/* <IoBedOutline className="row-icon-style"  /> */}
                        <RiFloodLine width={40} height={30}  style={{marginRight:"1px",color:"grey",fontSize:"22px"}} />
                    <Typography sx={{fontSize:{xs:"10px",md:"12px",sm:"10px",lg:"13px",xl:"14px"},padding:"0px",fontFamily:"Mukta-Bold"}}>{product.floor} {t('floor')}</Typography>
                
                    </Grid>



                

                {/* Third row */}
                {product.area && <Grid item   xs={4} sm={4} style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",padding:"5px" }}>
                        {/* <IoBedOutline className="row-icon-style"  /> */}
                
                        <TbArrowAutofitWidth width={40} height={30}  style={{marginRight:"1px",color:"grey",fontSize:"22px"}} />
                        
                        {/* <img width={22} height={20}  style={{marginRight:"1px"}} src={areaIcon}></img>*/}
                        <Typography sx={{fontSize:{xs:"10px",md:"12px",sm:"10px",lg:"13px",xl:"14px"},padding:"0px",fontFamily:"Mukta-Bold"}}>{product.area} m</Typography>
                
                    
                    </Grid>}
                </Grid>

            </Grid>
        </Box>
    );
}