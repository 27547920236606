
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import BASE_URL from '../Config';


const savedLanguage = localStorage.getItem('language') || 'fr';
export const fetchFilter=createAsyncThunk('filter/fetchFilter', async (credentials) => {
    const payload = {};

    // Dynamically add the parameters to the payload only if they have values
    if (credentials.category_id) payload.category_id = credentials.category_id;
    if (credentials.type) payload.type = credentials.type;
    if (credentials.min_price) payload.min_price = credentials.min_price;
    if (credentials.max_price) payload.max_price = credentials.max_price;
    if (credentials.num_of_rooms) payload.num_of_rooms = credentials.num_of_rooms;
    if (credentials.min_area) payload.min_area = credentials.min_area;
    if (credentials.max_area) payload.max_area = credentials.max_area;
    if (credentials.garage) payload.garage = credentials.garage;
    if (credentials.garden) payload.garden = credentials.garden;
    if (credentials.furnished) payload.furnished = credentials.furnished;
    if (credentials.pool) payload.pool = credentials.pool;
    if (credentials.electricity) payload.electricity = credentials.electricity;
    if (credentials.gaz) payload.gaz = credentials.gaz;
    if (credentials.order) payload.order = credentials.order;
    if (credentials.state_id) payload.state_id = credentials.state_id;
    if (credentials.city_id) payload.city_id = credentials.city_id;
    if (credentials.act_type) payload.act_type = credentials.act_type;
    
    // Construct query string with URLSearchParams
    const params = new URLSearchParams(payload).toString();
    
    // Construct the full URL with query parameters
    const fullUrl = `${BASE_URL}/api/products?${params}`;
    
   
    console.log("Full URL:", fullUrl);
    
    try {
        
        const response = await axios.get(`${BASE_URL}/api/products`, { params: payload });
    
    
    
        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
    }
    
})


export const searchAll = createAsyncThunk('filter/searchAll', async (credentials) => {
    const payload = {};

    // Set the search term and model
    payload['search[term]'] = credentials.term;
    if(credentials.model) payload.model = credentials.model; // Default if not provided

    // Construct query string with URLSearchParams
    const params = new URLSearchParams(payload).toString();
    
    // Construct the full URL with query parameters

 
    const fullUrl = `${BASE_URL}/api/search-all?search[term]=${credentials.term}${payload.model ? `&model=${payload.model}` : ''}`;

    // Print the full URL for debugging
    console.log("Full URL:", fullUrl);
    
    try {
        // Make the GET request with axios using the params
        const response = await axios.get(fullUrl);
    
        // Log the response data
        console.log(response.data);
    
        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error; // Rethrow the error for proper handling in the calling function
    }
});

export const searchByTerm = createAsyncThunk('filter/searchByTerm', async (credentials) => {
    const payload = {};

    // Set the search term and model
    payload['search[term]'] = credentials.term;// Default if not provided

    // Construct query string with URLSearchParams
    const params = new URLSearchParams(payload).toString();
    
    // Construct the full URL with query parameters
    const fullUrl = `${BASE_URL}/api/search?search[term]=${credentials.term}`;
    
    // Print the full URL for debugging
    console.log("Full URL:", fullUrl);
    
    try {
        // Make the GET request with axios using the params
        const response = await axios.get(fullUrl);
    
        // Log the response data
        console.log(response.data);
    
        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error; // Rethrow the error for proper handling in the calling function
    }
});


const filterSlice = createSlice({
    name:"filter",
    initialState: {
        filtredProducts: [],
        seggetions:[],
        filterdSearchAllProducts:[],
        filterdSearchAllAgencies:[],
        filterdSearchAllCategories:[],
        status: 'idle',
        error: null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchFilter.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchFilter.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.filtredProducts = action.payload.data.data;
            console.log('products from filter list',state.filtredProducts);
        })
        .addCase(fetchFilter.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
       .addCase(searchAll.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(searchAll.fulfilled, (state, action) => {
            state.status = 'succeeded';
        
            // Safely access the nested data with null checks
            const products = action.payload.data['App\\Models\\Product'];
            const agencies = action.payload.data['App\\Models\\Agency'];
            const categories = action.payload.data['App\\Models\\Category'];
        
            state.filterdSearchAllProducts = products ? products['App\\Models\\Product']?.data || [] : [];
            state.filterdSearchAllAgencies = agencies ? agencies['App\\Models\\Agency']?.data || [] : [];
            state.filterdSearchAllCategories = categories ? categories['App\\Models\\Category']?.data || [] : [];
        })
        
        .addCase(searchAll.rejected, (state, action) => {
            state.status = 'failed'; 
            state.error = action.error.message;
        })
        .addCase(searchByTerm.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(searchByTerm.fulfilled, (state, action) => {
            state.status = 'succeeded';
        
            // Safely access the nested data with null checks
            state.seggetions = action.payload;
            console.log('seggetions from server',state.seggetions);
       })
        
        .addCase(searchByTerm.rejected, (state, action) => {
            state.status = 'failed'; 
            state.error = action.error.message;
        })
    }

})
export default filterSlice.reducer;


