
import React from 'react';
import { Grid, TextField } from '@mui/material';
import userIcon from '../../assets/images/user.png';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
export default function ReviewSection(){
    const { t ,i18n} = useTranslation();
    const [value, setValue] = React.useState(2);
    const [hover, setHover] = React.useState(-1);
    return (
        <div style={{background:"white", display: "flex", flexDirection: "column", marginTop: "2%",
            justifyContent:"center", alignItems: "center",
                width:"100%",height:"100%"}}>
         
         




           <div style={{padding:"2%",width:"75%",fontFamily:"Mukta"}}>
             <p style={{fontFamily:"Mukta",color:"#107DAC",fontSize:"1rem",fontWeight:"bold"}}>{t('review_for')} </p>

             <div style={{background:"#D5D5D5",height:"3px",width:"100%"}}/>


              <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
               
                <div  style={{display:"flex",flexDirection:"row"}}>
                <img src={userIcon} alt="user icon" width="55" style={{  padding:'10px'}} />

                 <div>
                    <p style={{ fontFamily: "Mukta", color: "#5B5B5B", fontSize: "0.8rem", fontWeight: "bold", marginBottom: "4px" }}>
                    Property Type
                    </p>
                    <Rating
                    name="hover-feedback"
                    value={value}
                    precision={0.5}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    icon={<StarIcon style={{ opacity: 0.8, fontSize: "22px" }} fontSize="12px" />}
                    emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: "22px" }} fontSize="12px" />}
                    />
                 </div>
             


                </div>

              </Grid>


              <Grid container spacing={0} style={{ justifyContent: "space-between" }}>
                <Grid item xs={false} md={1} sx={{  display: { xs: 'none', sm: 'none', md: 'block' },}}>
                 
               

                </Grid>
                <Grid item xs={12} md={11}  >

                <p style={{ fontFamily: "Mukta", color: "#5B5B5B", fontSize: "0.8rem", fontWeight: "800", marginBottom: "4px" }}>
                {t('property_content')}
                </p>
                <TextField
                style={{ backgroundColor: '#F1F1F1' }}
                margin="normal"
                required
                fullWidth
                name="comment"
                placeholder= {t('leave_comment')}
                type="text"
                id="text"
                multiline
                rows={5} // Initial height
                maxRows={10} // Maximum height before scrolling
                InputProps={{
                    disableUnderline: true,
                    sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid red', // Custom error border
                    },
                    '& .MuiInputBase-input': {
                        padding: '8px 0', // Adjust padding for the text inside
                    },
                    },
                }}
                />

               <p style={{ fontFamily: "Mukta", color: "#5B5B5B", fontSize: "0.8rem", fontWeight: "800", marginBottom: "4px" }}>
                 {t('leave_comment')}
                </p>


                </Grid>

              </Grid>
              
              
        
           </div>

        </div>
    );
}