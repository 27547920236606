import React, {useState } from "react";
import {Typography, Grid } from "@mui/material"; // Ensure path and name are correct
import FeatureItemCard from "../../Components/Home_Page_components/feature_item_card";
import RowItemsTitles from "../../Components/Home_Page_components/row_items_titles";
import { useTranslation } from 'react-i18next';




export default function 
FeaturedPropertiesSection({products,forEnt,forSale,hideProperties,agency_details}) {

  const { t, i18n } = useTranslation();
  const [selectProductType, setSelectProductType]=useState('All')


  
  const productsToShow = selectProductType === 'All' ? products :
  selectProductType === 'Sale' ? forSale :
  selectProductType === 'Rent' ? forEnt :
  [];

  const isProductsListEmpty = productsToShow.length === 0;
  


  return (
    <div style={{ width: "100%", background: "#ECECF4", paddingBottom: "30px" }}>

      <Grid container style={{ width: "80%", margin: "auto" }}>
      <Grid   item 
        xs={12} 
        md={6} >
         <RowItemsTitles title1={t('featured')} title2={t('properties')}  description= {t('sub_description')} />
     
      
      </Grid>
     
      {!hideProperties ?
       <Grid 
        item 
        xs={12} 
        md={6} 
        fontFamily={"Mukta"}
        
        container 
        direction="row" 
        justifyContent={{ xs: "center", md: "flex-end" }} 
        alignItems="center" 
        spacing={2} 
        sx={{ marginTop: { xs: "2%", md: 0 } }}
      >
    
      <Grid item onClick={() => setSelectProductType('All')}>
        <Typography className="p-option">{t('all')}</Typography>
        <div style={{ width: "100%", height: "1px", backgroundColor: selectProductType === "All" ? "red" : "transparent", marginTop: "-5%" }}></div>
      </Grid>

      <Grid item onClick={() => setSelectProductType('Rent')}>
        <Typography className="p-option">{t('for_rent')}</Typography>
        <div style={{ width: "100%", height: "1px", backgroundColor: selectProductType === "Rent" ? "red" : "transparent", marginTop: "-5%" }}></div>
      </Grid>

      <Grid item onClick={() => setSelectProductType('Sale')}>
        <Typography className="p-option">{t('for_sale')}</Typography>
        <div style={{ width: "100%", height: "1px", backgroundColor: selectProductType === "Sale" ? "red" : "transparent", marginTop: "-5%" }}></div>
      </Grid> 
      </Grid> :  
      
     <div></div>
      
      }
               
      </Grid>
  

      {isProductsListEmpty ? (
      <p style={{ textAlign: "center" ,justifyContent: "center", alignItems: "center",fontFamily:"Mukta",margin:"20px"}}>{t('no_product')}</p> // Display a message when the list is empty
    ) : (
      <FeatureItemCard products={productsToShow} hideFilter={hideProperties} agency_details={agency_details}/> // Pass products to your component
    )}
    </div>
  );
}
