import React from 'react';
import "../../Styles/Footer.css";
import "../../Styles/SharedStyle.css";
import { Box, Grid, Typography } from "@mui/material";
import myimg from "../../assets/images/backgound.jpg"
import logo from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const {t,i18n} = useTranslation();
  const navigate=useNavigate();
  const goToOption = (path) => {
    navigate(path, { replace: true });
  }; 
  return (
    <Box
      sx={{
        position: 'relative', // Set relative positioning for overlay
        background: "#333",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundImage: `url(${myimg})`,
        width: '100%',
        height: 'auto',
       // paddingBottom: '20px' // Adjust as needed
      }}
    >
      {/* Black overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black filter with 50% opacity
          zIndex: 1 // Ensures the overlay is above the background image
        }}
      />

      <Box sx={{ position: 'relative', zIndex: 2,display: "flex",justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
        <Grid container spacing={0} sx={{ marginBottom: 4, textAlign: "center", paddingTop: "3%", paddingBottom: "1%", background: "#494949" }}>
          <Grid item xs={12} md={5} style={{borderRight: "1px solid white"}}>
          <img src={logo} alt="logo" style={{height:"50px"}}/>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={0} sx={{ marginBottom: 4, textAlign: "center" }}>
              <Grid item xs={12} md={3}  sx={{  textAlign: { xs: "center", md: "right"}, }}>
                <Typography style={{ color: "white",fontFamily: "Mukta",fontSize:"18px" }} >{t('categories')}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography style={{ color: "white",fontFamily: "Mukta",fontSize:"18px" }} >{t('agencies')}</Typography>
              </Grid>
              <Grid item xs={12} md={3}  sx={{  textAlign: { xs: "center", md: "left"}, }}>
              <Typography style={{ color: "white",fontFamily: "Mukta",fontSize:"18px" }} >{t('contact')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <footer className="footer" >
          <div style={{ width: "100%" }}>
            <Grid container spacing={4} className="footer-content">
              <Grid item xs={12} md={3} className="footer-section">
                <Typography variant="h5">{t('about_us')}</Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                {t('footer_sub_title1')}
                 </Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                {t('footer_sub_title2')}
                </Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                 {t('footer_sub_title3')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} lg={3} xl={3} sm={6} className="footer-section">
                <Typography variant="h5">{t('subscribe')}</Typography>
                <Typography style={{ color: "white", fontSize: "16px", fontWeight: "100", fontFamily: "Mukta-BOLD", paddingTop: "10px", paddingBottom: "10px" }}>
                  {t('email_address')}
                </Typography>
                <div style={{
                  display: 'flex',
                  border: "1px solid white",
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0px',
                  gap: '0px' // Optional: Adds space between the buinput and button
                }}>
                  <input

                    sx={{width:{xs:"100%",md:"80%"}}}
                    style={{
                      marginTop: '7px',
                      border: 'none', // Remove border
                      backgroundColor: 'transparent',
                      // Adjust width as needed
                    }}
                    type="email"
                    placeholder="Your email address"
                  />
                  <button style={{ background: "red", paddingLeft: "10px", paddingRight: "10px", width: "50%", margin: "5px" }}>Sign up</button>
                </div>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                  {t('contact_with_us')}
                </Typography>
                <div className="social-links">
                  <a href="#"><i className="fab fa-facebook"></i></a>
                  <a href="#"><i className="fab fa-instagram"></i></a>
                  <a href="#"><i className="fab fa-linkedin"></i></a>
                </div>
              </Grid>

              <Grid item xs={12} md={3} className="footer-section">
                <Typography variant="h5">{t('contact_us')}</Typography>
                <Typography style={{ color: "red", fontSize: "16px", fontWeight: "100", fontFamily: "Mukta-BOLD", paddingTop: "10px", paddingBottom: "10px" }}>
                  {t('reality_properties')}
                </Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                  Cité combata n°121 Oran
                </Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                  +213 777 77 77 77
                </Typography>
                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "100", fontFamily: "Mukta", paddingTop: "10px", paddingBottom: "10px" }}>
                  info@maldi.com
                </Typography>
              </Grid>

              <Grid item xs={12} md={3} className="footer-section">
                <Typography variant="h5">Useful Links</Typography>
                <Typography className='hover-text-div' onClick={() => goToOption("/")}>
                {t('home')}
                </Typography>
                <Typography className='hover-text-div'  onClick={() => goToOption("/categories")}>
                {t('categories')}
                </Typography>
                <Typography className='hover-text-div'  onClick={() => goToOption("/agencies")} >
                {t('agencies')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </footer>
        <div className="footer-bottom">
          <Typography variant="body2" align="center">© Copyright Agency 2024</Typography>
        </div>
      
      </Box>
     
    </Box>
  );
};

export default Footer;


