import { Box, Grid, Typography, IconButton } from "@mui/material";
import { MdKeyboardBackspace } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCountries,fetchCities,fetchStates } from "../../Redux_Managment/location_slice";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
export default function Step3({setActivateStep,setCityId,setCityName,setStateId,setStateName,country_id,city_name,city_id,state_name,state_id}){

    const dispatch= useDispatch();
    const { t } = useTranslation();
    const {countries,states,cities,error, stateStatus }=useSelector((state)=>state.location);
   
    useEffect(()=>{
      dispatch(fetchStates({country_id}))
    },[dispatch])


    

   



    const handleSelectState= async (state_id,state_name)=>{

      setStateId(state_id);
      console.log(state_name);
      setStateName(state_name);
      try{
        await dispatch(fetchCities({state_id})).unwrap();
      }catch{
        console.log("failled fetching state error")
      }
    }

    const handleSelectCity= async (city_id,city_name)=>{

      setCityId(city_id);
      setCityName(city_name);
       
    }

    return (

      <Box

            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                padding: "2%", 
            }}  
        
        >
              <Grid container style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                marginTop:"3%",
                justifyContent: "center",
                alignItems: "center"
            }}>
              <Grid item xs={12} md={6} justifyContent={"center"} display={"flex"} textAlign={{xs:"center" , md:"start"}}>
                 
      
              <Box sx={{
                    width: "90%",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    display: "flex",
                    flexDirection:{xs:"column",md:"row"},
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <IconButton onClick={() => setActivateStep(1)}>
                    <MdKeyboardBackspace style={{ fontSize: "1.5rem", color: "#ff574b" }} />
                </IconButton>
                <Typography style={{ fontSize: "1.1rem", fontFamily: "Mukta-Bold" }}>
                {t('add_product_s3')}</Typography>
                 
            </Box>
     

           </Grid>

       
          <Grid item xs={12} md={6} marginTop={{xs:"3%",md:"0%"}} background="red" justifyContent={{xs:"center" , md:"end"}} alignItems={{xs:"center" , md:"end"}}>
          
        {country_id && city_id && state_id &&
      
          <Box
       onClick={() => setActivateStep(3)}
       sx={{
        cursor: "pointer",
            display: "flex",
            justifyContent: {xs:"center", md : "end",},
            alignItems: {xs:"center", md :"flex-end",} ,
            textAlign: {xs:"center", md : "end",}
        }}>
                 <Typography  style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold",color:"#ff574b" }}>
                 {t('next_btn')}</Typography>
             
               
            </Box>
            
       }
        </Grid>
             </Grid>
         

       
   
         
      {stateStatus == "succeeded" ? 
      <Grid container sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>



        <Grid container sx={{width:{xs:"100%",md:"90%",}}}>
        <Grid item xs={12} md={6} sx={{justifyContent:{xs:"center",md:"start"} ,textAlign:{xs:"center",md:"start"}}}>
        <Typography style={{margin:"2%",fontFamily:"Mukta-bold"}}>{t('select_your_state')}</Typography>  

        </Grid>
       {state_name&&<Grid item xs={12} md={6} sx={{justifyContent:{xs:"center",md:"end"} ,textAlign:{xs:"center",md:"end"}}}>
        <Typography style={{margin:"2%",fontFamily:"Mukta-bold"}}>{t('selected_state')} {state_name}</Typography>  

        </Grid>}
       </Grid> 
        
      {states && states.length > 0 &&  
      <Box sx={{
                width: { xs: "90%", md: "90%" },
                height: { xs: "100px", md: "100px" }, // Adjust height as needed
                display: "flex",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                overflowY: "auto" // Enable vertical scrolling
            }}>
                 {  states.map((c, index) => (
                  
                  <Grid  onClick={()=>{handleSelectState(c.id,c.name)}} sx={{display:"flex",padding:"5px",textAlign:"start",marginLeft:"15px",cursor:"pointer",marginRight:"10px",borderRadius:"0px"}} item xs={6} md={3}>
                    <FaCheckCircle style={{padding:"3px",color:state_id==c.id ? "green" : "transparent"}}/>
                    <Typography>{c.name}</Typography>
                  </Grid>
                 ))}
        </Box>}

        <Grid container sx={{width:{xs:"100%",md:"90%",}}}>
        <Grid item xs={12} md={6} sx={{justifyContent:{xs:"center",md:"start"} ,textAlign:{xs:"center",md:"start"}}}>
        <Typography style={{margin:"2%",fontFamily:"Mukta-bold"}}>{t('select_your_city')}</Typography>  

        </Grid>
       {city_name&&<Grid item xs={12} md={6} sx={{justifyContent:{xs:"center",md:"end"} ,textAlign:{xs:"center",md:"end"}}}>
        <Typography style={{margin:"2%",fontFamily:"Mukta-bold"}}>{t('selected_city')} {city_name}</Typography>  

        </Grid>}
       </Grid>  
        
      {cities && cities.length > 0 &&  
      <Box sx={{
                width: { xs: "90%", md: "90%" },
                height: { xs: "100px", md: "150px" }, // Adjust height as needed
                display: "flex",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                overflowY: "auto" // Enable vertical scrolling
            }}>
                 {  cities.map((c, index) => (
                  
                  <Grid  onClick={()=>{handleSelectCity(c.id,c.name)}} sx={{display:"flex",padding:"5px",textAlign:"start",marginLeft:"15px",cursor:"pointer",marginRight:"10px",borderRadius:"0px"}} item xs={6} md={3}>
                    <FaCheckCircle style={{padding:"3px",color:city_id==c.id ? "green" : "transparent"}}/>
                    <Typography>{c.name}</Typography>
                  </Grid>
                 ))}
        </Box>}  
       
      {/* {states && states.length > 0 && 
      <div>
      <Grid container sx={{width:{xs:"100%",md:"90%"}}}>
        <Grid item xs={12} md={6}>
        <Typography style={{margin:"2%"}}>Select your State</Typography>  

        </Grid>
        {state_name && <Grid item xs={12} md={6} justifyContent="end" textAlign={"end"}>
        <Typography style={{margin:"2%"}}>Select State : {state_name}</Typography>  

        </Grid>}
       </Grid> 
        <Box sx={{
                width: { xs: "90%", md: "90%" },
                height: { xs: "100px", md: "150px" }, // Adjust height as needed
                display: "flex",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                overflowY: "auto" // Enable vertical scrolling
            }}>
                 {  states.map((c, index) => (
                  <Grid  onClick={()=>{handleSelectState(c.id,c.name)}} sx={{display:"flex",padding:"5px",textAlign:"start",marginLeft:"15px",cursor:"pointer",marginRight:"10px",borderRadius:"0px"}} item xs={6} md={3}>
                  <FaCheckCircle style={{padding:"3px",color: state_id==c.id ? "green" : "transparent"}}/>
                  <Typography>{c.name}</Typography>
                  </Grid>
               ))}
        </Box>
        </div>} 
      {cities && cities.length > 0 && 
      <div>
        <Grid container sx={{width:{xs:"100%",md:"90%"}}}>
          <Grid item xs={12} md={6}>
          <Typography style={{margin:"2%"}}>Select your City</Typography>  

          </Grid>
          {city_name && <Grid item xs={12} md={6} justifyContent="end" textAlign={"end"}>
          <Typography style={{margin:"2%"}}>Selected City : {city_name}</Typography>  

          </Grid>}
        </Grid> 
          <Box sx={{
                  width: { xs: "90%", md: "90%" },
                  height: { xs: "100px", md: "150px" }, // Adjust height as needed
                  display: "flex",
                  flexDirection: "column",
                  background: "white",
                  borderRadius: "10px",
                  overflowY: "auto" // Enable vertical scrolling
              }}>
                  {  cities.map((c, index) => (

                      <Grid  onClick={()=>{handleSelectCity(c.id,c.name)}}
                       sx={{display:"flex",padding:"5px",textAlign:"start",marginLeft:"15px",
                       cursor:"pointer",marginRight:"10px",borderRadius:"0px"}} item xs={6} md={3}>
                      <FaCheckCircle style={{padding:"3px",color:city_id==c.id ? "green" : "transparent"}}/>
                      <Typography>{c.name}</Typography>
                      </Grid>
                    
                  ))}
          </Box>
        </div>} */}

       </Grid> : stateStatus == "loading" ? <div>Pending</div> :<div>Some thing went wrong</div>}

          
        
      
        </Box> 
       
    )

}
