import {React , useState,useRef ,useEffect} from "react";

import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ItemCard from "./item_card";
import "../../Styles/FeaturedItemCardStyle.css";
import Sidebar from "../Global_components/side_bar";
import FilterIcon from "../../assets/icons/filter-icon.png";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} new-custom-arrow new-custom-arrow-next`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ color: 'black' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} new-custom-arrow new-custom-arrow-prev`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ transform: 'rotate(-180deg)', color: 'black' }} />
    </div>
  );
}


export default function FeatureItemCard({products,hideFilter=false ,isFav=false,agency_details}) {
  const sidebarRef = useRef(null);
  const {t,i18}=useTranslation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };


  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    rows: 2,
    slidesPerRow: 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dotsClass: 'my-custom-slider-dots',
    customPaging: () => <button color="red"></button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeSidebar]);

  const savedLanguage = localStorage.getItem('language') || 'fr';
  return (
    <Grid sx={{
      
      width: {xs:"70%",md:"75%",lg:"80%"}, margin: '0 auto', position: 'relative' }}>
      <Slider {...settings} style={{direction:savedLanguage!="ar"?"ltr":"rtl"}}>
        {products.map((currentProduct, index) => (
          
     
          <div key={index}>
            <ItemCard  product={currentProduct} isFav={isFav} agency_details={agency_details} />
          </div>
        ))}
      </Slider>

      {hideFilter == false ?<div style={{cursor:"pointer"}}>
        <Box  onClick={openSidebar} 
        sx={{width:{xs:"60px",md:"100px",lg:"100px" },
             height:{xs:"60px",md:"100px",lg:"100px" },
             background:"#DC3B3B",borderRadius:"50%",position:"absolute",
             top:savedLanguage !== 'ar' ?"100%":"105%",
             right: savedLanguage === 'ar' ? { xs: "80%", md: "90%", lg: "90%" } : "0%",
             left: savedLanguage !== 'ar' ? { xs: "105%", md: "100%", lg: "100%" } : "0%",
             transform:"translate(-50%,-50%)",display:"flex",
             flexDirection:"column",justifyContent:"center",
             alignItems:"center",textAlign:"center"}}>
          <img  width={"22px"} style={{marginTop:"15px"}} src={FilterIcon}></img>
          <p style={{color:"white",fontSize:"0.8rem",letterSpacing:"0.5px",fontWeight:"400",fontFamily:"Mukta",marginTop:"1px"}}> {t("filter")} </p>
        </Box>
        <div ref={sidebarRef}>
         <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />
      </div>
       
      </div>:<div></div>}

    
    </Grid>
  );
}