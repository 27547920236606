import { Box, Typography } from "@mui/material";
import React from "react";

export default function RowItemsTitles({ title1, title2, description }) {
    return (
        <Box 
            sx={{ 
                display: "flex", 
                flexDirection: "column",
                paddingTop: "20px", 
                width: "100%",
                justifyContent: { xs: "center", md: "start" }, 
                alignItems: { xs: "center", md: "start" }, 
                textAlign: { xs: "center", md: "start" }
            }}
        >
            {/* Left Section */}
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row" }}>
                <Typography 
                    variant="h1" 
                    sx={{ 
                        margin: 0, 
                        fontFamily: "Mukta", 
                        fontWeight: "100", 
                        fontSize: {xs:"1.7rem",md:"2.2rem"} 
                    }}
                >
                    {title1}
                </Typography>
                <Typography 
                    variant="h1" 
                    sx={{ 
                        margin: 0, 
                        marginLeft: "5px", 
                        color: "red", 
                        fontFamily: "Mukta", 
                        fontWeight: "100", 
                        fontSize: {xs:"1.7rem",md:"2.2rem"} 
                    }}
                >
                    {title2}
                </Typography>
            </div>

            {/* Description Section */}
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "5px" }}>
                <Box
                    sx={{
                        width: { xs: "100%", md: "100%" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0px",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ 
                            fontWeight: "regular", 
                            color: "grey",
                            marginLeft: "0px", // Ensure no extra margin
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
            </div>
        </Box>
    );
}
