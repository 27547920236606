import React from 'react';
import '../../Styles/PropertyGrid.css';
import backGround from "../../assets/images/backgound.jpg" // You'll need to create this CSS file

import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const properties = [
  { id: 1, image: {backGround}, text: 'Home town' },
  { id: 2, image: {backGround}, text: 'Apartement' },
  { id: 3, image: {backGround}, text: 'About us' },
  { id: 4, image: {backGround}, text: 'About you' },
  { id: 5, image: {backGround}, text: 'About us' },
];

const PropertyGrid = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="property-grid-container" style={{ textAlign: 'center' }}>
          <Grid 
                      container 
                      direction="row" 
                      marginTop="2%"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ flexWrap: "nowrap" }}
                      
                    >
                    <h1 
                    
                    style={{ marginBottom: "0px",fontFamily:"Mukta-Bold",
                     fontWeight:"100",fontSize:"2.2rem" }}>
                      {t('reality_properties')}
                      </h1>
                      
                    </Grid>
      
                 <p   style={{color:"grey", margin: 0,fontFamily:"Mukta",  marginTop:"-10px",marginBottom:"1vh",
                     fontWeight:"100",fontSize:"0.9rem" }}
                     >{t('sub_description')}</p>
                        <div className="property-grid" style={{ textAlign: 'center' }}>
                          {properties.map((property) => (
                            <div key={property.id} style={{backgroundImage: `url(${backGround})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            }} className="property-item">
                             
                              <div className="property-text" >{property.text}</div>
                            </div>
                          ))}
                        </div>
    </div>
  );
};

export default PropertyGrid;