import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import '../../Styles/MainStyle.css';
import { useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Container, Toolbar, TextField, InputAdornment, Box, Grid, IconButton, CircularProgress } from '@mui/material';
import { searchAll, searchByTerm } from '../../Redux_Managment/search_filter_slice';
import { useTranslation } from 'react-i18next';

function SearchBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const { seggetions } = useSelector((state) => state.filter);

  const handleSubmit = async (modelType, termName) => {
    console.log("termName", term);
    const model = modelType.toString().split('\\').pop(); // "Product"

    try {
      await dispatch(searchAll({ term, ...(model && { model: `App\\Models\\${model}` }) })).unwrap();

      const queryParams = new URLSearchParams();
      if (term) queryParams.append('search[term]', term);
      if (model) queryParams.append('model', model);

      const url = `/search-all?${queryParams.toString()}`;
      navigate(url, { state: { term, model } });
    } catch (e) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const handleTermChanging = async (e) => {
    const value = e.target.value;
    setTerm(value);
    try {
      await dispatch(searchByTerm({ term: value })).unwrap();
    } catch (e) {
      console.log("something went wrong", e);
    }
  };

  return (
    <AppBar className="App-Bar" position="static" sx={{ backgroundColor: '#515151', height: "55px", border: "0px solid transparent", boxShadow: 'none' }}>
      <Container maxWidth="xl" sx={{ justifyContent: "center", alignItems: "center" }}>
        <Toolbar disableGutters sx={{ paddingLeft: '5%', paddingRight: '5%', justifyContent: 'center' }}>
          <TextField
            variant="outlined"
            value={term}
            onChange={handleTermChanging}
            placeholder={t('search')}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              // Check if the click is inside the suggestion box to prevent losing focus
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setIsFocused(false);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && term !== "") {
                handleSubmit("", term);
              }
            }}
            sx={{
              width: { xs: "100%", md: '55%' },
              height: '40px',
              backgroundColor: '#fff',
              borderRadius: '20px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                height: '40px',
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!loading ? (
                    <IconButton onClick={() => { term && handleSubmit("", term) }} edge="end">
                      <FaSearch fontSize={"1.2rem"} />
                    </IconButton>
                  ) : (
                    <CircularProgress size="20px" sx={{ color: "red" }} />
                  )}
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
          />
        </Toolbar>

        {isFocused && (
          <Box
            sx={{
              position: 'absolute',
              left: { xs: "5%", md: "22%" },
              right: { xs: "5%", md: "22%" },
              width: { xs: "90%", md: "55%" },
              mt: "-3px",
              backgroundColor: '#fff',
              boxShadow: 2,
              borderRadius: '8px',
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                maxHeight: '200px',
                overflowY: 'auto',
                p: 0,
              }}
            >
              <Grid container justifyContent="start" textAlign="start">
                {seggetions && seggetions.length > 0 ? 
                  seggetions.map((item, index) => (
                    <p 
                      onMouseDown={(e) => {
                        e.stopPropagation(); // Prevent the event from bubbling up
                        console.log("Clicked item:", item);
                        setTerm(item.title ?? item.name);
                        handleSubmit(item.model, item.title ?? item.name);
                      }} 
                      key={index} 
                      style={{ 
                        color: "black", 
                        fontFamily: "Mukta", 
                        cursor: "pointer", 
                        fontSize: "0.9rem", 
                        padding: "10px", 
                        margin: "0px" 
                      }}
                    >
                      {item.title ?? item.name}
                    </p>
                  )) : 
                  <p style={{ fontFamily: "Mukta", color: "black", padding: "10px" ,textAlign:"center"}}>{t('no_suggestions')}</p>
                }
              </Grid>
            </Box>
            <Button
              fullWidth
              onClick={() => setIsFocused(false)}
              sx={{ color: "black", fontFamily: "Mukta", color: "red" }}
            >
              {t('close')}
            </Button>
          </Box>
        )}
      </Container>
    </AppBar>
  );
}

export default SearchBar;
