import {React, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import ResponsiveAppBar from "../Components/Global_components/nav_bar";
import userImage from "../assets/images/user.png"
import background from "../assets/images/backgound.jpg";
import whiteHouseIcon from "../assets/images/home_page/white_house.png";
import houseIcon from "../assets/images/home_page/house.png";
import residential from "../assets/images/home_page/residential.png";
import modernHouse from "../assets/images/home_page/modern-house.png";
import condominium from "../assets/images/home_page/condominium.png";
import TopSection from "./home_page_section/top_section";
import PropertyGrid from "../Components/Home_Page_components/property_grid";
import FeaturedPropertiesSection from "../Pages/home_page_section/featured-properties-section";
import WorkingWithRealitySection from "../Pages/home_page_section/working-with-reality"
import {useDispatch,useSelector} from "react-redux";
import { fetchDashboardData } from "../Redux_Managment/dashboard_slice";
import FeaturesForSale from "../Pages/home_page_section/featured_for_sale";
import RowItemsTitles from "../Components/Home_Page_components/row_items_titles";
import logo from "../assets/images/logo.png";
import AgenciesGrid from "../Components/Agency/agencies_grid";
import SearchBar from "../Components/Global_components/search_bar";
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { banner,categories,featuredProducts,saleProducts,rentProducts,firstFiveCategories,featuredAgencies, status, error } = useSelector(state => state.dashboard || {}); // Access state.products

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);


 
      const items = [
        { image: condominium,title:"Condominium"}, 
        { image: modernHouse,title:"Modern House"},
        { image: residential, title:"Residential"}, 
        { image: houseIcon, title:"House"}, 
        { image: whiteHouseIcon, title:"White House"}, 
        // Add more items as needed
    ];
    return (
      <>

      {status === "loading" && <div style={{height:"100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "white", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" }}> 
        <img src={logo} alt="logo" style={{height:"20vh"}}/>
        <Typography sx={{fontSize:"1.2rem",color:"Black",fontFamily:"Mukta-Bold",textAlign:"center"}}> Sahla Immo</Typography>
        
        </div>}

     { status === "succeeded" &&
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "white", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" }}>
          <div style={{ width: "100%" ,background: "#ECECF4"}}>
            <SearchBar/>
          
        
            <TopSection banner={banner} items={items} background={background} firstFiveCategories={firstFiveCategories}/>
          </div>
          {featuredProducts && featuredProducts.length>0 && <FeaturedPropertiesSection products={featuredProducts}
          forEnt={rentProducts} forSale={saleProducts}  hideProperties={false} />}
          <WorkingWithRealitySection/>

          
        
          <div style={{ width: "100%", background: "#ECECF4", paddingBottom:"10%" }}>

          
            {saleProducts && saleProducts.length > 0 &&<div>
            
            <Grid container style={{ width: "80%", margin: "auto" }}>
            <Grid   item 
              xs={12} 
              md={6} >
            <RowItemsTitles title1={t('featured')} title2={t('for_sale')} description= {t('sub_description')} />
            
            </Grid>
          
          </Grid>
            <FeaturesForSale products={saleProducts}/>
            </div>}

            {rentProducts && rentProducts.length > 0 && <div>
            
            <Grid container style={{ width: "80%", margin: "auto" }}>
            <Grid   item 
              xs={12} 
              md={6} >
            <RowItemsTitles title1={t('featured')} title2={t('for_rent')} description= {t('sub_description')}/>
            
            </Grid>
          
            </Grid>
            <FeaturesForSale products={rentProducts}/>
            </div>}
          </div>

      
          <div style={{ width: "80%", background: "white",marginTop:"3%" }}>
      
          <PropertyGrid/>
          </div>


          <div style={{ width: "100%", background: "#ECECF4", paddingBottom:"10%" }}>
          <Grid container style={{ width: "90%", margin: "auto" }}>
            <Grid   item 
              style={{margin:"2%"}}
              xs={12} 
              md={6} >
            <RowItemsTitles title1={t('featured')} title2={t('agencies')}  description={t('agency_explore_more')} />
            
            </Grid>
            {featuredAgencies && featuredAgencies.length>0 && <AgenciesGrid agencies={featuredAgencies} />}
            </Grid>
          </div>

        
      
        </div>
    }
    </>
    );
}