import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Authentication from './Pages/Authentication';
import DescriptionPage from './Pages/DescriptionPage';
import ProductsByCategory from './Pages/ProductsByCategory';
import ProfilePage from './Pages/ProfilePage.js';
import Layout from './Layout'; // Import Layout component
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDashboardData } from './Redux_Managment/dashboard_slice';
import AgenciesPage from './Pages/AgenciesPage.js';
import AboutAsPage from './Pages/AboutAsPage.js';
import CategoriesPage from './Pages/CategoriesPage.js';
import UserProducts from './Pages/UserProducts.js';
import AddProduct from './Pages/AddProduct.js';
import ScrollTop from './ScrollTop.js';
import FavoriteProducts from './Pages/FavoriteProducts.js';
import AgencyDetail from './Pages/AgencyDetails.js';
import EditProduct from './Pages/EditProduct.js';
import SearchPage from './Pages/SearchPage.js';
import SearchAllPage from './Pages/searchAllPage.js';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  return (
    <BrowserRouter>
     <ScrollTop />
      <Routes>
        {/* Wrap all routes in the Layout component */}
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Authentication />} />
          <Route path="/profile" element={<ProfilePage/>} />
          <Route path="/description" element={<DescriptionPage />} />
          <Route path="/agencies" element={<AgenciesPage />} />
          <Route path="/about-us" element={<AboutAsPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/Category/:category" element={<ProductsByCategory />} />
          <Route path="/my-Products" element={<UserProducts />} />
          <Route path="/new-Product" element={<AddProduct />} />
          <Route path="/edit-Product/:product" element={<EditProduct />} />
          <Route path="/my-favorite-products" element={<FavoriteProducts />} />
          <Route path="/Agency-Detail/:agency" element={<AgencyDetail />} />
          <Route path="/Filter" element={<SearchPage />} />
          <Route path="/search-all" element={<SearchAllPage />} />

         
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
