import React, { useState } from 'react';
import { Box, Grid, Typography,TextField,IconButton,InputAdornment,CircularProgress } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import "../Styles/SharedStyle.css";
import FeatureItemCard from "../Components/Home_Page_components/feature_item_card";
import AgenciesGrid from '../Components/Agency/agencies_grid';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EmptyWidget from '../Components/Global_components/empty_widget';
import { searchAll } from '../Redux_Managment/search_filter_slice';
import { useTranslation } from 'react-i18next';


export default function SearchAllPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const {model,term} = location.state || {};
    const [searchvalue,setSearchValue] = useState();
    const [searchTerm, setTerm] = useState(searchvalue || term || '');
   
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const {agencies} = useSelector((state) => state.agencies);
    const {products} = useSelector((state) => state.products);
    const {t} = useTranslation();

    const {        filterdSearchAllProducts,
        filterdSearchAllAgencies,
        filterdSearchAllCategories} = useSelector((state) => state.filter);


    const [selectedModel, setModel] = useState(model || 'All');

    const handleSubmit = async (modelType,termName) => {
    
       
      
        setSearchValue(termName);
        setLoading(true);
        
        const modelValue = modelType == 'All' ? '' : selectedModel; 
        console.log(modelValue);
       
        try {
          await dispatch(searchAll({ term:searchTerm, ...(modelValue !='All' && { model: `App\\Models\\${modelValue}` }) })).unwrap();
        
         
        } catch (e) {
           console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='Global-div'>
           <Box
             sx={{
                background: "#ECECF4",
                width: "90%",
                justifyContent:"center",
                paddingLeft:"5%",
                paddingRight:"5%",
                paddingBottom:"10px",
                paddingTop:"10px",

             }}
            >
             <Typography variant="h5" sx={{
                textAlign:"center",
                color:"#E4180B",
                fontFamily:"Mukta",
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>
                    {t('search_for')} {searchTerm} </Typography>   
             <Typography  sx={{
                textAlign:"center",
                fontSize:"14px",
                width:"80%",
                padding:"0 10%",
                justifyItems:"center",
                alignItems:"center",
                fontFamily:"Mukta",
                justifyContent:"center",paddingTop:"5px",paddingBottom:"20px"}}>
                  {t('search_for_sub_title')} </Typography>   
         

                     <Grid container>
    <Grid 
        item 
        xs={12} 
        md={6} 
        fontFamily={"Mukta"}
        marginBottom={"10px"}
        container 
        direction="row" 
        justifyContent={{ xs: "center", md: "flex-start" }} 
        alignItems="center" 
        spacing={2} 
        sx={{ marginTop: { xs: "2%", md: 0 } }}  
    >
        <Grid item onClick={() => setModel('All')}>
            <Typography className="p-option" style={{fontFamily:"Mukta",fontWeight:"bold",margin:"5px"}}>All</Typography>
            <div style={{ width: "100%", height: "1px", backgroundColor: selectedModel === "All" ? "red" : "transparent", marginTop: "-5%" }}></div>
        </Grid>     
        <Grid item onClick={() => setModel('Product')}>
            <Typography className="p-option" style={{fontFamily:"Mukta",fontWeight:"bold",margin:"5px"}}>Products</Typography>
            <div style={{ width: "100%", height: "1px", backgroundColor: selectedModel === "Product" ? "red" : "transparent", marginTop: "-5%" }}></div>
        </Grid>
        <Grid item onClick={() => setModel('Agency')}>
            <Typography className="p-option" style={{fontFamily:"Mukta",fontWeight:"bold",margin:"5px"}}>Agency</Typography>
            <div style={{ width: "100%", height: "1px", backgroundColor: selectedModel === "Agency" ? "red" : "transparent", marginTop: "-5%" }}></div>
        </Grid>
    </Grid> 

    <Grid 
        item 
        xs={12} 
        md={6} 
        container 
        justifyContent="flex-end" 
        alignItems="center"
    >
        <TextField
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setTerm(e.target.value)}
            placeholder="Search..."
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if(searchTerm!=""){
                    handleSubmit(model,searchTerm)
                  }
                }
            }}
            sx={{
                width: { xs: "100%", md: '55%' },
                height: '40px',
                backgroundColor: '#fff',
                borderRadius: '20px',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    height: '40px',
                    '& fieldset': {
                        border: 'none',
                    },
                    '&:hover fieldset': {
                        border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                        border: 'none',
                    },
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {!loading ? (
                            <IconButton onClick={() => { searchTerm && handleSubmit(model, searchTerm) }} edge="end">
                                <FaSearch fontSize={"1.2rem"} />
                            </IconButton>
                        ) : (
                            <CircularProgress size="20px" sx={{ color: "red" }} />
                        )}
                    </InputAdornment>
                ),
                autoComplete: "off", 
            }}
        />
    </Grid>
</Grid>

            
            {selectedModel === 'Product' && (
                filterdSearchAllProducts && filterdSearchAllProducts.length > 0 ? (
                    <FeatureItemCard products={filterdSearchAllProducts} hideFilter={true} />
                ) : (
                    <EmptyWidget/> // Message when there are no products
                )
            )}

            {selectedModel === 'Agency' && (
                filterdSearchAllAgencies && filterdSearchAllAgencies.length > 0 ? (
                    <AgenciesGrid agencies={filterdSearchAllAgencies} />
                ) : (
                    <EmptyWidget title={t('no_agency')}/> // Message when there are no agencies
                )
            )}


      {selectedModel === 'All' && (
    filterdSearchAllProducts.length === 0 && filterdSearchAllAgencies.length === 0 ? (
        <EmptyWidget title={t('no_result')} />
    ) : (
        <div>
            {filterdSearchAllProducts.length > 0 && (
                <FeatureItemCard products={filterdSearchAllProducts} hideFilter={true} />
            )}
            {filterdSearchAllAgencies.length > 0 && (
                <AgenciesGrid agencies={filterdSearchAllAgencies} />
            )}
        </div>
    )
)}


             

       
           </Box>
        </div>
    );
}