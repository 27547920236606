import { Typography,Box } from "@mui/material";
import background from "../../assets/images/backgound.jpg";
import { MdLocationPin } from "react-icons/md";
import RecommandedVilla from '../../Components/Description_Page_Components/recomanded_villa';
export default function DescriptionRightSection({Agency,formAgency,agency_detail}) {

    const currentAgency = Agency ??  agency_detail;
    return (
        <Box>
                 <Box sx={{background:"white", display: "flex", flexDirection: "column", marginTop: "2%",
                        justifyContent:"center", alignItems: "center",marginLeft:{xs:"0%",md:"4%",},margin:"4%",borderRadius:"15px",
                            width:"100%",height:"auto"}}>  
                            <img src={currentAgency.banners[0].original_url} width={"100%"} height={"220px"} style={{borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}/>
                            <Typography style={{fontFamily:"Mukta",fontWeight:"normal",fontSize:"1rem",color:"#0344A6",width:"80%",marginTop:"4%"}}>{currentAgency.name}</Typography>
                            <div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center",width:"80%",marginTop:"2%"}}>
                                <MdLocationPin color='red' style={{marginTop:"-5%"}}/>
                                <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                                    <Typography style={{fontFamily:"Mukta-Bold",fontWeight:"normal",fontSize:"0.7rem",letterSpacing:"1px",color:"#5B5B5B",marginTop:"0%"}}>{currentAgency.address}</Typography>
                                    <Typography style={{fontFamily:"Mukta-Bold",fontWeight:"normal",fontSize:"0.7rem",letterSpacing:"1px",color:"#5B5B5B",marginTop:"0%"}}>{currentAgency.phone} </Typography>
                            
                                </div>
                            
                            </div> 

                            <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"#C7C3C3",height:"3px",margin:"5%",borderRadius:"5px"}}></div>
                                <Typography style={{fontFamily:"Mukta",fontWeight:"500",letterSpacing:"1px",fontSize:"0.8rem",color:"#5B5B5B",marginTop:"0%",width:"80%"}}>Enjoy ample living space with an open floor plan that
                                        seamlessly connects the living, dining, and kitchen areas. 
                                </Typography>

                                <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"white",height:"15px",margin:"5%",borderRadius:"5px"}}></div>
                          
                            
                    </Box>

                    {/* <Box sx={{background:"white", display: "flex", flexDirection: "column",
                        justifyContent:"center", alignItems: "center",marginLeft:{xs:"0%",md:"4%",},marginTop:"4%",borderRadius:"15px",
                            width:"100%",height:"auto"}}>  

                            
                               <RecommandedVilla/>
                           
                         

                          
                            
                    </Box> */}
        </Box>
    );
}