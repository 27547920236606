import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../Config'


const savedLanguage = localStorage.getItem('language') || 'fr';

export const fetchCountries=createAsyncThunk('location/fetchCountries', async ()=>{
  try{
    const response = await axios.get(`${BASE_URL}/api/locations`,{params: {"lang": savedLanguage}});
    
    return response.data;
  }
  catch(e){
    console.log("error during fetch data ");

  }});
export const fetchStates=createAsyncThunk('location/fetchState', async (credentials)=>{
    const payload={};

    console.log("credentials",credentials)
   
    if (credentials.country_id) payload.country_id = credentials.country_id;
    const params = new URLSearchParams(payload).toString();
    
    // Construct the full URL with query parameters
    const fullUrl = `${BASE_URL}/api/locations?${params}`;
    
    // Print the full URL for debugging
    console.log("Full URL:", fullUrl);
    try{
      const response = await axios.get(`${BASE_URL}/api/locations`,{params:payload,lang:savedLanguage})
      console.log("fetch states",response)
      return response.data;
    }
    catch(e){
      console.log("error during fetch data ");
  
    }
  
  });
  export const fetchCities=createAsyncThunk('location/fetchCities', async (credentials)=>{
    const payload={};
   
    if (credentials.state_id) payload.state_id = credentials.state_id;
    try{
      const response = await axios.get(`${BASE_URL}/api/locations`,{params:payload,lang:savedLanguage})
  
      return response.data;
    }
    catch(e){
      console.log("error during fetch data ");
  
    }
  
  })  

const locationSlice = createSlice({
    name:"location",
    initialState:{
        status:"ide",
        stateStatus:"ide",
        citiesStatus:"ide",
        countries:[],
        states:[],
        cities:[],
        error:null
    },
    reducers:{},
    extraReducers:(builder) => {
        builder
     
        .addCase(
            fetchCountries.pending,(state)=>{
                state.status='loading';
                state.states = [];
                state.cities = [];

            })
        .addCase(fetchCountries.fulfilled, (state, action) => {
            // state.status = 'succeeded';
            // state.countries = action.payload;
            // console.log("fetch countries", state.countries);
            })
        .addCase(fetchCountries.rejected, (state, action) => {
        // state.status = 'failed';
        // state.error = action.error.message;
        }) 
        .addCase(
            fetchCities.pending,(state)=>{
                // state.citiesStatus='loading';

            })
        .addCase(fetchCities.fulfilled, (state, action) => {
            state.citiesStatus = 'succeeded';
            state.cities = action.payload;
            console.log("fetch cities", state.cities);
            })
        .addCase(fetchCities.rejected, (state, action) => {
            state.citiesStatus = 'failed';
            state.error = action.error.message;
        })
        .addCase(
            fetchStates.pending,(state)=>{
            state.stateStatus='loading';

            })
        .addCase(fetchStates.fulfilled, (state, action) => {
            state.stateStatus = 'succeeded';
            state.states = action.payload;
            console.log("fetch states--1", state.states);

            })
        .addCase(fetchStates.rejected, (state, action) => {
            state.stateStatus = 'failed';
            state.error = action.error.message;
        })    

    }


})
export default   locationSlice.reducer;