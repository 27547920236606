

import '../Styles/SharedStyle.css';
import {Grid,Typography,Box,Button} from '@mui/material';
import UserProductsGrid from "../Components/User_Products/user_products_grid";
import { useDispatch } from "react-redux";
import {React, useEffect } from "react";
import { useSelector } from 'react-redux';
import { fetchUserProducts } from '../Redux_Managment/products_slice';
import { useNavigate } from 'react-router-dom';
import EmptyWidget from '../Components/Global_components/empty_widget';
import { useTranslation } from 'react-i18next';
export default function UserProducts() {  
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {t} = useTranslation();
    const {userProducts} = useSelector(state => state.products || {});

    
    useEffect(()=>{
        dispatch(fetchUserProducts())
    },[dispatch])


    const handleAddProduct=()=>{
        navigate("/new-Product");
    }


    return (
        <div className="Global-div">
         
            <Box
             sx={{
                background: "#ECECF4",
                width: "90%",
                justifyContent:"center",
                paddingLeft:"5%",
                paddingRight:"5%",
                paddingBottom:"10px",
                paddingTop:"10px",

             }}
            >
             <Typography variant="h5" sx={{
                textAlign:"center",
                color:"#E4180B",
                fontFamily:"Mukta",
                 justifyContent:"center",paddingTop:"20px",paddingBottom:"5px"}}>
                    {t('user_product_title')}</Typography>   
             <Typography  sx={{
                textAlign:"center",
                fontSize:"14px",
                width:"80%",
                padding:"0 10%",
                justifyItems:"center",
                alignItems:"center",
                fontFamily:"Mukta",
                justifyContent:"center",paddingTop:"5px",paddingBottom:"20px"}}>
                   {t('user_product_sub_title')} </Typography>   


                <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={3} lg={3} xl={3}>
                        <div style={{background:"white", borderRadius:"10px",display:"flex",flexDirection:"column"}}>
                            
                            <Typography variant="h4" 
                                sx={{textAlign:"center",fontFamily:"Mukta",color:"Black",justifyContent:"center",paddingBottom:"5px"}}>
                                0
                            </Typography>
                            <Typography  sx={{ textAlign:"center", color:"Black", fontFamily:"Mukta", justifyContent:"center",paddingBottom:"5px"}}>
                                {t('all_products')}
                            </Typography>  
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={3} lg={3} xl={3}>
                      <div style={{background:"white", borderRadius:"10px",display:"flex",flexDirection:"column"}}>
                           
                            <Typography variant="h4" 
                              sx={{textAlign:"center",fontFamily:"Mukta",color:"green",justifyContent:"center",paddingBottom:"5px"}}>
                               0
                            </Typography>
                            <Typography  sx={{ textAlign:"center", color:"green", fontFamily:"Mukta", justifyContent:"center",paddingBottom:"5px"}}>
                               {t('accepted_products')}
                            </Typography>  
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={3} lg={3} xl={3}>
                       <div style={{background:"white", borderRadius:"10px",display:"flex",flexDirection:"column"}}>
                            
                            <Typography variant="h4" 
                                sx={{textAlign:"center",fontFamily:"Mukta",color:"#FFAF00",justifyContent:"center",paddingBottom:"5px"}}>
                                0
                            </Typography>
                            <Typography  sx={{ textAlign:"center", color:"#FFAF00", fontFamily:"Mukta", justifyContent:"center",paddingBottom:"5px"}}>
                                {t('pending_products')}
                            </Typography>  
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={3} lg={3} xl={3}>
                       <div style={{background:"white", borderRadius:"10px",display:"flex",flexDirection:"column"}}>
                            
                            <Typography variant="h4" 
                                sx={{textAlign:"center",fontFamily:"Mukta",color:"red",justifyContent:"center",paddingBottom:"5px"}}>
                                0
                            </Typography>
                            <Typography  sx={{ textAlign:"center", color:"red", fontFamily:"Mukta", justifyContent:"center",paddingBottom:"5px"}}>
                                {t('rejected_products')}
                            </Typography>  
                        </div>
                    </Grid>
                </Grid>

                <Grid container style={{marginTop:"5%",marginLeft:"1%",marginRight:"1%"}}  >
                   
                    <Grid item xs={12} md={8} display="flex" 
                    flexDirection={'column'}
                    textAlign={{xs:'center',sm:"start",md:"start",lg:"start",xl:"start"}}
                    justifyContent={{ xs: "center", md: "flex-start",sm:"flex-start", lg:"flex-start", xl:"flex-start" }} // Center on xs, align end on md
                    alignItems="center" // Center vertically (if needed)
                    style={{ height: '100%' }} // Ensure the item takes full height if needed
                     >
                       <Typography sx={{fontFamily:"Mukta",justifyContent:"center",fontSize:{xs:"1.5rem",md:"1.8rem",lg:"2rem"}}}>
                        {t('invetory_product')}</Typography>
                       <Typography sx={{fontFamily:"Mukta",justifyContent:"center",fontSize:"0.86rem",alignItems:"justify"}}>
                       
                        {t('invetory_product_sub_title')}
                       </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" 
                    justifyContent={{ xs: "center", md: "flex-end" }} // Center on xs, align end on md
                    alignItems="center" // Center vertically (if needed)
                    style={{ height: '100%' }} // Ensure the item takes full height if needed
    >
                       <Button 
                         onClick={handleAddProduct} 
                         style={{ marginTop: '1rem', padding: "10px", justifyContent: "center", alignItems: "center", borderRadius: "25px" }}
                            fullWidth
                            variant="contained"
                            sx={{width: '40%' ,margin: 3, justifyContent: "center", textTransform: 'none', alignItems: "center", bgcolor: '#E4180B', borderRadius: "25px", fontFamily: "Mukta-BOLD" }}
                    
                       
                       > {t('new_product')}</Button>
                    </Grid>
                </Grid>

                {userProducts && userProducts.length > 0 ?
                 <UserProductsGrid  products={userProducts}/>
                 :<EmptyWidget/>}

                <div style={{marginTop:"7%"}}></div>
                
            </Box>
        </div>
    );
}