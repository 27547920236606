import BASE_URL from '../Config';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const savedLanguage = localStorage.getItem('language') || 'fr';
export const fetchAgencies = createAsyncThunk(
    'agencies/fetchAgencies',
    async (page = 1, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/agencies`, {
                params: { page , "lang": savedLanguage }  // Pass the page parameter to the API
            });

            console.log("-----1111111----fetch agencies from server", response.data);

            // Return the entire response data
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch agencies');
        }
    }
);
  
export const fetchAgencyById = createAsyncThunk(
    'agencies/fetchAgenciesById',
    async (category_id, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${BASE_URL}/api/agency/${category_id}`,{params: {"lang": savedLanguage}});
        
        console.log("-----1----agnecy by from server", response.data);
        
        // Return response.data directly, not response.data.data
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch category');
      }
    }
  );
  

const agencySlice = createSlice({
    name: "agencies",
    initialState: {
        agencies: [],
        agency: null,
        agencyProducts:[],
        status: 'idle',
        oneStatus: 'idle',
        error: null,
        agencyError: null,
        pagination: {
            currentPage: 1,
            lastPage: 1,
            perPage: 20,
            total: 0,
            nextPageUrl: null,
            prevPageUrl: null
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAgencies.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchAgencies.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.agencies = action.payload.data; 
            state.pagination = {
                currentPage: action.payload.data.current_page,
                lastPage: action.payload.data.last_page,
                perPage: action.payload.data.per_page,
                total: action.payload.data.total,
                nextPageUrl: action.payload.data.next_page_url,
                prevPageUrl: action.payload.data.prev_page_url
            };
        })
        .addCase(fetchAgencies.rejected, (state, action) => { // Changed from `error` to `rejected`
            state.status = 'failed';
            state.error = action.payload; // Adjusted to `payload` if error message is in payload
        })
        .addCase(fetchAgencyById.pending, (state) => {
            state.oneStatus = 'loading';
        })
        .addCase(fetchAgencyById.fulfilled, (state, action) => {
            state.oneStatus = 'succeeded';
           
            state.agency = action.payload.agency;
            
            state.agencyProducts= action.payload.products;
            console.log("state.agencyProducts",state.agencyProducts);
        })
        .addCase(fetchAgencyById.rejected, (state, action) => { // Changed from `error` to `rejected`
            state.oneStatus = 'failed';
            state.agencyError = action.payload; // Adjusted to `payload` if error message is in payload
        });
    }
});

export const { actions, reducer } = agencySlice;
export default reducer;
