import React, { useState } from 'react';
import { IoIosArrowDropdown } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdPassword } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import "../../Styles/Authentication.css";
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Button, TextField, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import googleIcon from "../../assets/images/google.png";
import facebookIcon from "../../assets/images/facebook.png";
import { registerUser } from '../../Redux_Managment/auth_slice'; // Assume this is your registration action
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function RegisterForm({handleTabChange}) {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate =useNavigate();
  const { status, error } = useSelector((state) => state.auth);

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = t('required_field');
    if (!email) errors.email = t('required_field');
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) errors.email = t('invalide_email');
    if (!password) errors.password =  t('required_field');
    if (password !== rePassword) errors.rePassword = t('not_password');
    // if (!role) errors.role = 'Role is required';

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true); // Set loading to true when starting the request

    try {
      await dispatch(registerUser({ name, email, password, role })).unwrap();
      navigate('/', { replace: true });
    } catch (err) {
      // Handle registration errors
      console.error('Registration failed:', err);
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };


  return (
    <Box component="form" noValidate onSubmit={handleSubmit} 
    sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <div style={{ paddingLeft: "9%", paddingRight: "9%" }}>

        <TextField
          style={{ backgroundColor: '#F1F1F1' }}
          margin="normal"
          required
          fullWidth
          id="username"
          placeholder={t('user_name')}
          name="username"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          autoComplete="username"
          autoFocus
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <FaRegUser />
              </InputAdornment>
            ),
            sx: {
              height: '40px',
              padding: '0 14px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '2px solid red',
              },
              '& .MuiInputBase-input': {
                padding: '8px 0',
              },
            },
          }}
        />
        <span style={{color:"red",fontSize:"12px"}}>{errors.name}</span>
        <TextField
          style={{ backgroundColor: '#F1F1F1' }}
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={t('email')}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          autoComplete="email"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <MdOutlineMailOutline />
              </InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '2px solid red',
              },
              '& .MuiInputBase-input': {
                padding: '8px 0',
              },
            },
          }}
        />
        <span style={{color:"red",fontSize:"12px"}}>{errors.email}</span>
        <TextField
          style={{ backgroundColor: '#F1F1F1' }}
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder={t('password')}
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <MdPassword />
              </InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '2px solid red',
              },
              '& .MuiInputBase-input': {
                padding: '8px 0',
              },
            },
          }}
        />

        <span style={{color:"red",fontSize:"12px"}}>{errors.password}</span>
        <TextField
          style={{ backgroundColor: '#F1F1F1' }}
          margin="normal"
          required
          fullWidth
          name="rePassword"
          placeholder={t('re_password')}
          type="password"
          id="rePassword"
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
          error={!!errors.rePassword}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <MdPassword />
              </InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '2px solid red',
              },
              '& .MuiInputBase-input': {
                padding: '8px 0',
              },
            },
          }}
        />
        <span style={{color:"red",fontSize:"12px"}}>{errors.rePassword}</span>
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F1F1F1',
            paddingRight: '10px',
            marginTop: '15px',
            paddingLeft: '10px',
            height: '40px'
          }}
        >
          <InputAdornment position="start" sx={{ marginLeft: '4px' }}>
            <IoIosArrowDropdown />
          </InputAdornment>
          <Select
            fullWidth
            value={role}
            onChange={(e) => setRole(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: '2px solid red',
              },
              '& .MuiInputBase-input': {
                padding: '4px 0',
                fontSize: '14px',
                lineHeight: '1.2'
              },
            }}
          >
            <MenuItem disabled value="">
              <p style={{ fontFamily: "Mukta", color: "#999999", fontSize: "14px" }}>Select role</p>
            </MenuItem>
            <MenuItem value={10}>Role 1</MenuItem>
            <MenuItem value={20}>Role 2</MenuItem>
            <MenuItem value={30}>Role 3</MenuItem>
          </Select>
        </Box> */}

      </div>

      <Typography style={{ fontFamily: "Mukta", color: "#4D4D4D", marginTop: '15px', fontSize: "14px" ,textAlign: 'center',}}>
      {t('have_account')} <span onClick={() => handleTabChange('login')} style={{cursor:"pointer" ,color: '#E4180B', fontWeight: '700' }}>{t('login')}</span>
      </Typography>
      <p style={{textAlign: 'center', fontFamily: 'Mukta', color: 'red', letterSpacing: '0.3px', fontSize: '14px'}}>{error}</p>
         
      <Button
        type="submit"
        fullWidth
        variant="contained"
        style={{ width: '80%', marginTop: '1rem', padding: "10px", 
          justifyContent: "center", alignItems: "center", borderRadius: "25px" }}
     
        sx={{ margin: 3, justifyContent: "center",
           textTransform: 'none', alignItems: "center", bgcolor: '#E4180B', borderRadius: "25px", fontFamily: "Mukta-BOLD" }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('register')}
      </Button>

      <Typography variant="body2" color="text.secondary"  
        style={{curson:"pointer",textAlign: 'center', fontFamily: "Mukta", color: "#4D4D4D", marginTop: '0px', fontSize: "14px"}}>
        {t('or_connect')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '10px' ,marginBottom: '10px'}}>
        <img src={googleIcon} alt="Google" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
        <img src={facebookIcon} alt="Facebook" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
      </Box>
    </Box>
  );
}
