
import React from "react";
import {CircularProgress} from "@mui/material";


export default function LoadingWidget (){

    return (
        <div 
                style={{width:"100%",display:"flex",height:"50vh",justifyContent:"center",alignItems:"center",}}>
                  <CircularProgress/></div>
    );
}
