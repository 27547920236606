import React, { useEffect } from 'react';
import { Grid, Box,Typography } from '@mui/material';
import { MdOutlineFavorite } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";
import "../Styles/DescriptionImageSlider.css";
import "../Styles/MainStyle.css";
import FeaturedPropertiesSection from '../Pages/home_page_section/featured-properties-section';
import DescriptionDottedBox from '../Components/Description_Page_Components/description_dotted_box';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductByCategory,fetchcategoryById } from '../Redux_Managment/categories_slice';
import Appartement from '../assets/icons/apartment.png';
import { useNavigate } from 'react-router-dom';
import EmptyWidget from '../Components/Global_components/empty_widget';
import { useTranslation } from 'react-i18next';

export default function ProductsByCategory() {
    const location = useLocation();
    const navigate = useNavigate();
    const { category_id, category_name } = location.state || {};
    const { t } = useTranslation();

    
    const dispatch = useDispatch();
    const { productsByCategory = [], category = {}, state, error } = useSelector((state) => state.categories || {});
    
    useEffect(() => {
        if (category_id) {
            dispatch(fetchProductByCategory(category_id));
            dispatch(fetchcategoryById(category_id));
        }
    }, [dispatch, category_id]);

    const handleCategoryClick = (category_id,category_name) => {
      navigate(`/Category/${category_name}`, { state: { category_id,category_name } });
 };

    
    if (state === "loading") {
      return (
        <div 
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Use 100vh to fill the viewport height
            width: "100%",   // Fill the width of the parent
            textAlign: "center",
            fontFamily: "Mukta",
            fontWeight: "bold",
            fontSize: "1rem"
          }}
        >
          Loading...
        </div>
      );
    }
    
    // if (error) {
    //   return (
    //     <div 
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100vh", // Use 100vh to fill the viewport height
    //         width: "100%",   // Fill the width of the parent
    //         textAlign: "center",
    //         fontFamily: "Mukta",
    //         fontWeight: "bold",
    //         fontSize: "1rem"
    //       }}
    //     >
    //       Error... {error}
    //     </div>
    //   );
    // }
    

    return (
        <div style={{
            background: "#ECECF4", 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
            width: "100%", 
            maxWidth: "1500px", 
            margin: "0 auto", 
            padding: "0rem", 
            height: "100%"
        }}>


            <div style={{ width: "80%" }}>
            <Box sx={{ display: "flex", flexDirection: "row",paddingTop: "20px", width: "100%",justifyContent: { xs: "center", md: "start"}, alignItems:  { xs: "center", md: "start"},textAlign: { xs: "center", md: "start"} }}>
  {/* Left Section */}
  <div style={{background: "red", display: "flex", justifyContent: "start", alignItems: "sta", flexDirection: "row",textAlign:"center" }}>
    
                <Grid sx={{ display: "grid", justifyContent: "center", alignItems: "center", marginTop: "1%" ,width:"100%",textAlign:"center"}}>
                    <Box
                        className="dotted-box"
                        sx={{
                            width: { xs: "80px", lg: "80px" ,xl:"120px"},
                            height: { xs: "80px", lg: "80px" ,xl:"120px"},
                            padding: "5px",
                            background: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                      <img src={category && category.icon ? category.icon.original_url : Appartement} alt="user icon" width="50%" height="50%"
                      ></img>
                    </Box>
                  </Grid>
       
   
  </div>
  
  <Typography sx={{width:"100%" ,margin: 0,paddingTop: "10px", fontFamily: "Mukta", fontWeight: "100", fontSize: {xs:"1.1rem",md:"1.5rem"},marginLeft:"10px" }}>
        {category_name}
      </Typography>  
</Box>


<Grid container spacing={2} style={{ marginTop: "20px" }}>
  {category && category.descendants.length>0 && category.descendants.map((c,i)=>(
       <Grid  item xs={6} sm={3} md={3} style={{cursor:"pointer"}} onClick={() => handleCategoryClick(c.id,c.name)}>
       <DescriptionDottedBox  title={c.name}  imgSource={Appartement}/>
    </Grid>
  )) }
           
         
         
</Grid>

            </div>
            {productsByCategory.length === 0 && (
                <div style={{
                    textAlign: "center",
                    fontFamily: "Mukta",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    height: "100%",
                    width: "80%",
                    margin: "1%"
                }}>
                 <EmptyWidget title={t('no_product_for_category')}/>
                </div>
            )}
            {productsByCategory.length > 0 && (
                <FeaturedPropertiesSection 
                    products={productsByCategory}
                    hideProperties={true}
                />
            )}
            <div style={{ height: "10%", margin: "5%" }}></div>
     
        </div>
    );
}
