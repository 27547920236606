
import  React from 'react';




import { Box,Grid,Typography,IconButton } from "@mui/material";
import { MdKeyboardBackspace } from "react-icons/md";
import { Avatar } from '@mui/material';
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchcategories } from '../Redux_Managment/categories_slice';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import LoadingWidget from '../Components/Global_components/loading_widget';
import EmptyWidget from '../Components/Global_components/empty_widget';



export default function CategoriesPage({}) {

    const dispatch=useDispatch();
    const navigate = useNavigate();
    const location= useLocation()


    const {categories,categoriesStatus,error }=useSelector((state)=>state.categories);


    useEffect(() => {
        dispatch(fetchcategories());
      }, [dispatch]);

      const handleProductClick = (category_id,category_name) => {
        location.pathname!= `/Category/${category_name}` &&
         navigate(`/Category/${category_name}`, { state: { category_id,category_name } });
      };
   
    const [expandedCategories, setExpandedCategories] = useState({});
    const checkSubCategories = (categoryId) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId] 
        }));
    };
    const CategoryItem = ({ category, checkSubCategories, expandedCategories }) => {
        const isExpanded = expandedCategories[category.id] || false;
    
        return (
            <div style={{ width: "95%", display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center" }}>
             <div
                  onClick={() => {
                      if (category.descendants && category.descendants.length > 0) {
                          checkSubCategories(category.id);
                      } else{
                        handleProductClick(category.id,category.name)
                      }
                  }}
                  style={{
                      width: "100%", // Full width
                      padding: "1%",
                      borderBottom: "0.5px solid #ececf4",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                  }}
              >
    
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar alt="Category Icon" src={category.icon?.original_url} sx={{ width: 40, height: 40, margin: "5px" }} />
                        <p style={{ fontSize: "0.9rem", fontFamily: "Mukta-Bold" }}>{category.name}</p>
                    </div>
                    {  category.descendants && category.descendants.length>0 &&<MdOutlineArrowDropDown style={{ fontSize: "1.5rem" }} />}
                </div>
    
                {isExpanded && category.descendants && category.descendants.map((subCategory, subIndex) => (
                   <div style={{width:"100%",margin:"1px",display:"flex",
                   justifyContent:"center",alignContent:"center",alignItems:"center",fontFamily:"Mukta",fontSize:"0.8rem"}}>
                      <CategoryItem 
                        key={subIndex} 
                        category={subCategory} 
                        checkSubCategories={checkSubCategories} 
                        expandedCategories={expandedCategories} 
                    />
                   </div>
                ))}
            </div>
        );
    };

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background: "white",
            borderRadius: "10px",
        }}>


            <div style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
              <p style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold" }}>Categories List</p>
             
           


            </div>
       
            {categoriesStatus == 'loading' && <LoadingWidget/>}
            {categoriesStatus == 'failed' && <div width="90%"><EmptyWidget/></div>}
            {categoriesStatus == 'succeeded' &&
            <Box sx={{
                width: { xs: "100%", md: "90%" },
                height: { xs: "500px", md: "500px" }, // Adjust height as needed
                display: "flex",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                overflowY: "auto" // Enable vertical scrolling
            }}>
               {categories && categories.length > 0 && categories.map((category, index) => (
                  <CategoryItem 
                      key={index} 
                      category={category} 
                      checkSubCategories={checkSubCategories} 
                      expandedCategories={expandedCategories}
                  />
              ))}
            </Box>}


      </Box>
    );
}