import React from "react";

import { useTranslation } from 'react-i18next';
import map from "../../assets/images/map.png";

export default function MapSection(){
    const {t,i18n} = useTranslation();
    return (
        <div style={{background:"white", display: "flex", flexDirection: "column", marginTop: "2%",
            justifyContent:"center", alignItems: "center",
                width:"100%",height:"100%"}}>
             
             




               <div style={{padding:"2%",width:"75%",fontFamily:"Mukta"}}>
                 <p style={{fontFamily:"Mukta",color:"#107DAC",fontSize:"1rem",fontWeight:"bold"}}>{t('property_address')} : </p>

                 <img width={"100%"} src={map}></img>
                  
                 {/* <iframe
                    src="https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=20,17"
                    width="600"
                    height="450"
                    style={{ border: 0, borderRadius: '15px' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed"
                    ></iframe> */}

               </div>

            </div>
    );
}