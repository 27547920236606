import React from "react";
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import ItemCard from "../../Components/Home_Page_components/item_card";
import "../../Styles/FeaturesForSale.css";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Directions } from "@mui/icons-material";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} features-for-sale-custom-arrow  features-for-sale-custom-arrow-next`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ color: 'black' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} features-for-sale-custom-arrow features-for-sale-custom-arrow-prev`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ transform: 'rotate(-60deg)' }} />
    </div>
  );
}
const savedLanguage = localStorage.getItem('language') || 'fr';
const FeaturesForSale = ({ products }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,

    justifyContent:"start",
    alignItems:"start",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const savedLanguage = localStorage.getItem('language') || 'fr';
  return (
    <Box 
      sx={{
        width: {
          xs: '70%',md:"80%"},
        margin: 'auto',
        position: 'relative',
      }}

      // className="features-for-sale-slider-wrapper"
    >
      <Slider {...settings} className="features-for-sale-slider" style={{direction:savedLanguage!="ar"?"ltr":"rtl"}}>
        {products.map((product, index) => (
          <ItemCard key={index} className="slider-div" product={product} />
        ))}
      </Slider>
    </Box>
  );
};

export default FeaturesForSale;
