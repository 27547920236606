import React from "react";
import {Box, Grid, Typography } from "@mui/material";
import villaIcon from "../../assets/images/home_page/red-villa.png";
import appartementIcon from "../../assets/images/home_page/building.png";
import moderIcon from "../../assets/images/home_page/red-office-building.png";
import WorkWithRealityItem from "../../Components/Home_Page_components/workw_with_reality_item";
import "../../Styles/MainStyle.css";
import { t } from "i18next";

export default function WorkingWithRealitySection ({}) {
    return (
        <div style={{ height:"100%",width: "80%",display: "flex", backgroundColor: "white",flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "0 auto", paddingBottom: "5rem",paddingTop:"5rem" }}>
         
        
    
        <Grid 
             container 
             direction="row" 
             marginTop="2%"
             justifyContent={{ xs: "center", }} 
             alignItems="center"
             sx={{ flexWrap: "nowrap" }}
             
           >
           <h1  className="working-with-reality-title">
               {t('working_with')}
             </h1>
             <h1  className="working-with-reality-title" style={{color:"red",marginLeft:"5px"}}>
                 {t('reality')}
             </h1>
            
           </Grid>

           <Grid container spacing={1} style={{ margin: "5%" }}>
            <Grid item xs={12}  md={4} style={{ marginBottom: "5%" }}>
              <WorkWithRealityItem image={appartementIcon} />
            </Grid>
            
            <Grid item xs={12} sx={{display:{xs:"flex",md:"none"}}} >
              <Box sx={{ background: "white", height: { xs: "20px", md: "0px" }, width: "100%" }}></Box>
            </Grid>

            <Grid item xs={12}  md={4} style={{ marginBottom: "5%" }}>
              <WorkWithRealityItem image={villaIcon} />
            </Grid>


            <Grid item xs={12} sx={{display:{xs:"flex",md:"none"}}} >
              <Box sx={{ background: "white", height: { xs: "20px", md: "0px" }, width: "100%" }}></Box>
            </Grid>

            <Grid item xs={12}  md={4} style={{ marginBottom: "5%" }}>
              <WorkWithRealityItem image={moderIcon} />
            </Grid>
          </Grid>

</div>
    );
}