
import React from 'react';
import {MdLocationPin} from 'react-icons/md';
import background from '../../assets/images/backgound.jpg';
import { Avatar, Box, Typography } from '@mui/material';
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import '../../Styles/SharedStyle.css';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



export default function AgencyItem({agency }) {
  const {t,i18n} = useTranslation();

  const navigate=useNavigate();
  const handleAgencyClick = (agency_id,agency_name) => {
        
    navigate(`/Agency-Detail/${agency_name}`, { state: { agency_id,agency_name } });
  };
  
  


    return (
        <Box 
        
         onClick={()=>handleAgencyClick(agency.id,agency.name)} 
        sx={{background:"white", display: "flex", flexDirection: "column", marginTop: "2%",
            justifyContent:"center", alignItems: "center",marginLeft:{xs:"0%",md:"4%",},marginTop:"4%",borderRadius:"15px",
                width:"95%",height:"auto",cursor:"pointer"}}>  
                <img  src={agency.banners && agency.banners.length>0 ? agency.banners[0].original_url : background} 
                width={"100%"} height={"200px"} style={{borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}/>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center', // Center items vertically
                        marginTop: '4%',
                        marginBottom: '5%',
                        
                        width: '100%',
                        gap: '1rem', // Adds space between Avatar and Typography
                    }}
    >
      <Avatar 
       src={agency.logo && agency.logo.original_url ? agency.logo.original_url : ''} 
       // Adjust according to how you pass the background image
        style={{ marginLeft: '20px',marginRight:"5%", padding: '0' ,border:'1px solid #0344A6'}} // Remove margin and padding from Avatar if not needed
      />
      <div style={{width:"100%",margin:"-5%"}}>
      <Typography
        style={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            letterSpacing: '0.51px',
          fontFamily: 'Mukta',
          fontWeight: 'normal',
          fontSize: '1.2rem',
          color: '#0344A6',
          textAlign: 'start', // Center text horizontally
        }}
      >
        {agency.name}
      </Typography>
      <Typography
        style={{
          fontFamily: 'Mukta',
          fontWeight: 'normal',
          fontSize: '0.8rem',
          color: 'black',
          textAlign: 'start', // Center text horizontally
        }}
      >
       {agency.phone}
      </Typography>
      

      </div>
   
      
    </div>
 
    <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"white",height:"1px",margin:"2%",borderRadius:"5px"}}></div>
              
                <Typography
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Mukta',
                            fontWeight: 500,
                            letterSpacing: '1px',
                            fontSize: '0.8rem',
                            color: '#5B5B5B',
                            marginTop: '0%',
                            width: '85%',
                        }}
                        >
                     {agency.about ??  t('no_description')}  
                 </Typography>

                 <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"#ECECF4",height:"1px",margin:"2%",borderRadius:"5px"}}></div>
                 <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"white",height:"7px",margin:"2%",borderRadius:"5px"}}></div>
                 <div style={{display:"flex",justifyContent:"start",alignItems:"start",width:"100%"}}>
                    <a 
                        href={agency.facebook} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ textDecoration: 'none' ,marginRight: "5%",marginLeft:"8%"}} // Optional: remove underline from link
                        >
                        <FaFacebook 
                           
                            className='hover-social-icons'
                        />
                    </a>   
                     <a 
                        href={agency.instagram}  
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ textDecoration: 'none', marginRight: "5%",}} 
                        >
                           <FaInstagram className='hover-social-icons'/>
                 
                    </a> 
                    <a 
                        href={agency.tiktok} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ textDecoration: 'none' ,marginRight: "5%",}} 
                        >
                        <FaTiktok  className='hover-social-icons'/>
              
                    </a> 
                 </div>
                 <div style={{display:"flex",width:"50%",justifyContent:"center",alignItems:"center",background:"white",height:"7px",margin:"2%",borderRadius:"5px"}}></div>
          
                
        </Box>
    );
}