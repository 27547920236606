import {React,useEffect, useState} from 'react';
import {Grid,Box, Typography,TextField,Button,CircularProgress} from '@mui/material';
import profile from '../assets/images/profile.jpeg';
import Avatar from '@mui/material/Avatar';
import {useDispatch,useSelector} from "react-redux";
import { fetchCurrentUser ,editUserInfo} from '../Redux_Managment/auth_slice';
import { notifySuccess,notifyError } from '../Components/Global_components/toast';
import { FaRegUser } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { MdFavoriteBorder } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import {signOutFromAll} from '../Redux_Managment/auth_slice';
import { useNavigate } from 'react-router-dom';
import '../Styles/SharedStyle.css';
export default function ProfilePage() {
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const {user,userStatus,userError,editError}=useSelector(state=> state.auth || {})
    const {t,i18n}=useTranslation();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setFirstName] = useState(user?.name || '');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState(user?.email || '');
    const [emailError, setEmailError] = useState('');
    const [phone, setPhone] = useState(user?.phone || '');
    const [phoneError, setPhoneError] = useState('');
    const [user_type, setUserType] = useState(user?.user_type || '');
    const [userTypeError, setUserTypeError] = useState('');
    const [loadingLogout,setLoadingLogOut]=useState(false);

    useEffect(() => {
      console.log("load user details");
      dispatch(fetchCurrentUser());
    }, [dispatch]);

    const logoutFromAll = async() => {
      setLoadingLogOut(true);
      try{
        await dispatch(signOutFromAll()).unwrap();
        navigate('/', { replace: true });;
      }catch(error){
       console.log(error);
      }finally{
        setLoadingLogOut(false);
      }
    };
    const goToOption = (path) => {
      navigate(path);
    };

        


    useEffect(() => {
      if (user) {
          setFirstName(user.name || '');
          setEmail(user.email || '');
          setPhone(user.phone || '');
          setUserType(user.user_type || '');
      }
  }, [user]);

  const handleEdit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true before starting
    setEmailError('');
    setError('');
    setNameError('');
    setPhoneError('');
    setUserTypeError('');
    
    try {
      if (name === '') {  
        setNameError('Name is required');
        return;
      }
      if (email === '') {
        setEmailError('Email is required');
        return;
      }
      if (phone === '') {
        setPhoneError('Phone is required');
        return;
      }
      if (user_type === '') {
        setUserTypeError('User role is required');
        return;
      }
  
 
      await dispatch(editUserInfo({ name, email, phone, user_type })).unwrap();
     
      notifySuccess('Profile updated successfully');
  
    } catch (e) {
      setError('An unexpected error occurred');
      notifyError('Some Thing went wrong!, Try again');
    } finally {
      setLoading(false); 
    }
  };
  
    

   return (
    
    <div style={{ display: "flex",
    flexDirection: "column", justifyContent: "center", alignItems: "center", background: "white", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" }}>
    
      <Box sx={{
        background: "#ECECF4",
        width: "80%",
        padding: "3%",
      }}>
       <Grid container spacing={2} display={"flex"} justifyContent={"center"}>


       <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={{height:"100%",borderRadius:"10px", 
              textAlign: "center",backgroundColor: "white",
              paddingBottom: "3%",paddingLeft: "3%",paddingRight: "3%",
               display: "flex", flexDirection: "column",justifyContent: "center",
                alignItems:"center", }}>

                  <Typography variant="h6" sx={{marginBottom:"2%",marginTop:"2%"}}>{t('personal_info')}</Typography>

                  <Box sx={{display:"flex",justifyContent:"start",alignItems:"start",background:"#ECECF4",borderRadius:"15px",width:"90%",margin:"5%",fontFamily:"Mukta-Bold"}}>

                    <Grid sx={{width:"100%",display:"flex",flexDirection:"Column",justifyContent:"center",alignItems:"center",textAlign:"start",padding:"10px"}}>
                          <Grid item  className='hover-profile-div'>
                             <FaRegUser sx={{margin:"10%",fontSize:"2rem"}}/>
                            <Typography  sx={{margin:"2%",fontSize:"0.9rem",color:"black",fontFamily:"Mukta-Bold"}}>{t('personal_info')}</Typography>
                            
                            
                          </Grid>
                          <Grid item className='hover-profile-div' onClick={() => goToOption('/my-Products')}>
                       
                            <MdProductionQuantityLimits  sx={{margin:"10%",fontSize:"2rem"}}/>
                            <Typography  sx={{margin:"2%",fontSize:"0.9rem",color:"black",fontFamily:"Mukta-Bold"}}>{t('your_product')}</Typography>
                            
                          </Grid>
                          <Grid item className='hover-profile-div' onClick={() => goToOption('/My-favorite-products')}>
                       
                            <MdFavoriteBorder  sx={{margin:"10%",fontSize:"2rem"}}/>
                            <Typography  sx={{margin:"2%",fontSize:"0.9rem",color:"black",fontFamily:"Mukta-Bold"}}>{t('your_fav_products')}</Typography>
                            
                          </Grid>
                          <Grid item  className='hover-profile-div' onClick={logoutFromAll}>
                       
                            {loadingLogout?<CircularProgress/>:<MdLogout color='red' sx={{margin:"10%",fontSize:"2rem",color:"red"}}/>}
                            <Typography  sx={{margin:"2%",fontSize:"0.9rem",color:"red",fontFamily:"Mukta-Bold"}}>{t('log_out_from_all')}</Typography>
                            
                          </Grid>

                    </Grid>
                     
                  
                  </Box> 
            
            </Box>
          

        </Grid>
     
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={{height:"100%",borderRadius:"10px", textAlign: "center",backgroundColor: "white",paddingBottom: "3%",paddingLeft: "3%",paddingRight: "3%", display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center" }}>
              
                <Avatar alt="Avatar" src={profile} sx={{ width: 120, height: 120 }} />
                <Typography style={{fontSize:"1.5rem",color:"black",padding:"2px", fontFamily:"Mukta"}}>{t('personal_info')}</Typography>
                <Typography style={{fontSize:"0.9rem",color:"#E4180B",padding:"2px",marginBottom:"10px", fontFamily:"Mukta"}}>
                {t('personal_info_sub_title')}</Typography>
              
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label= {t('name')}
                        margin="normal"
                        value={name}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        error={!!nameError}
                       helperText={nameError}
                        fullWidth
                        style={{marginTop:"10px",width:"100%"}}
                        sx={{
                            marginTop: "10px",
                            width: "100%",
                            '& .MuiInputBase-root': {
                              padding: '5px',  // Adjust padding to control height
                            },
                            '& .MuiOutlinedInput-input': {
                              fontSize: '0.9rem',  // Reduce font size to make the field smaller
                              padding: '8px',  // Adjust padding for height control
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '0.9rem',  // Adjust label font size to match
                            },
                          }}
                         
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Role"
                        value={user_type}
                      
                        onChange={e=>setUserType(e.target.value)}
                        error={!!userTypeError}
                        helperText={userTypeError}
                        style={{marginTop:"10px",marginRight:"10px",width:"100%"}}
                        sx={{
                            marginTop: "10px",
                            width: "100%",
                            '& .MuiInputBase-root': {
                              padding: '5px',  
                            },
                            '& .MuiOutlinedInput-input': {
                              fontSize: '0.9rem',  
                              padding: '8px', 
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '0.9rem',  
                            },
                          }}
                        />

                    </Grid>
               



                </Grid>

              
                
               
                <TextField
                id="outlined-multiline-flexible"
                label={t('email')}
                value={email}
                onChange={e=>setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                style={{width:"100%",marginTop:"10px"}}
                sx={{
                    marginTop: "10px",
                    width: "100%",
                    '& .MuiInputBase-root': {
                      padding: '5px',  // Adjust padding to control height
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: '0.9rem',  // Reduce font size to make the field smaller
                      padding: '8px',  // Adjust padding for height control
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '0.9rem',  // Adjust label font size to match
                    },
                  }}
                />
                <TextField
                id="outlined-multiline-flexible"
                label={t('phone')}
                value={phone}
                error={!!phoneError}
                helperText={phoneError}
                onChange={(e=>setPhone(e.target.value))}

                style={{width:"100%",marginTop:"10px"}}
                sx={{
                    marginTop: "10px",
                    width: "100%",
                    '& .MuiInputBase-root': {
                      padding: '5px',  // Adjust padding to control height
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: '0.9rem',  
                      padding: '8px',  
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '0.9rem',  
                    },
                  }}
                />
                  <Button
                style={{ width: '80%', marginTop: '1rem', padding: "10px", justifyContent: "center", alignItems: "center", borderRadius: "25px" }}
                fullWidth
                variant="contained"
                onClick={handleEdit}
                disabled={loading} // Disable button while loading
                sx={{ margin: 3, justifyContent: "center", textTransform: 'none', alignItems: "center", bgcolor: '#E4180B', borderRadius: "25px", fontFamily: "Mukta-BOLD" }}
            >
                {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Save'}
                 </Button>
                  <p style={{textAlign: 'center', fontFamily: 'Mukta', color: 'red', letterSpacing: '0.3px', fontSize: '14px'}}>{error}</p>
          
            </Box>
          

        </Grid>

       

       </Grid>

      </Box>

    </div>);
}