
import React from "react";
import { Grid } from "@mui/material";

export default function DescriptionPropertyBox({Title,Description,color}) {
    return (
        <Grid item xs={12} md={5.5} sm={5.5} lg={5.5} xl={5.5} style={{ background: color, padding: "2px",marginBottom:"10px" }}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={6} md={6} style={{justifyContent:"start",textAlign:"start",paddingLeft:"10px"}}>
                    <p style={{ fontSize: "0.9rem", fontFamily: "Mukta", color: "#5B5B5B" }}>{Title}</p>
                </Grid>
                <Grid item xs={6} md={6}>
                    <p style={{ fontSize: "0.8rem", fontFamily: "Mukta-Bold", color: "black" }}>{Description}</p>
                </Grid>
            </Grid>
        </Grid>
    );
}
