

import { Grid, Box } from "@mui/material";
import ImageSlider from "../../Components/Home_Page_components/images_slider";
import '../../Styles/MainStyle.css';
import TypeItemComponent from "../../Components/Home_Page_components/type_item_component";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function TopSection({banner,items,background,firstFiveCategories}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleNavComponent = (category_id,category_name) => {
   
    navigate(`/Category/${category_name}`, { state: { category_id,category_name } });
  }
  const savedLanguage = localStorage.getItem('language') || 'fr';
  return (
    <div style={{ width: "100%", paddingBottom: "5%", position: "relative", backgroundSize: 'cover', backgroundPosition: 'center' }}>
      
      <div 
        style={{
          backgroundImage: `url(${background})`,
          borderBottomLeftRadius: '50% 10%',
          borderBottomRightRadius: '50% 10%',
          backgroundRepeat: "no-repeat",
            backgroundSize: "cover", // Ensures the image covers the entire div
            backgroundPosition: "center", // Centers the image within the div
   
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
          zIndex: 2,  // Higher z-index to keep it above the red section
          position: "relative",  // Ensure this section is positioned relative
        }}
      > 
        <ImageSlider images={banner} />
     
        <Box
  sx={{
    width: "100%",
    marginLeft: {xs:"-15%",md:"0px",sm:"0px",lg:"0px",xl:"0px"},
    display: {
      xs: firstFiveCategories && firstFiveCategories.length > 0 ? "block" : "none",
      md: "block"
    }
  }}
>
    <Box className="centered-text"
        
        sx={{
          background:{ sm: 'transparent', lg: 'transparent',md:"transparent",xs:"white"},
          width: { sm: '100%', lg: '70%',xs:"115%" },
          height: { sm: 'auto', lg: 'auto',xs:"80%"},
          textAlign:"center",
          margin:{ sm: '0 auto', lg: '0 auto',xs:savedLanguage=='ar' ?"0px -50px -50px 0px":"50px 0px -50px 0px"},
          padding: { sm: '30px 0px 30px 0px', lg: '30px 0px 30px 0px',xs:"10px 0px 70px 0px"},
          
        }}
        > 
         {t('browse_product')}
        
        </Box>
        <Grid
       
       sx={{
         background:{ sm: 'transparent', lg: 'transparent',md:"transparent",xs:"white"},
         width: { sm: '100%', lg: '70%',sm:"80%",xs:"115%" },
         height: { sm: 'auto', lg: 'auto',xs:"100%"},
         margin:{ sm: '0 auto', lg: '0 auto',xs:savedLanguage=='ar' ?"0px -50px -50px 0px":"0px 0px -50px 0px"},
         padding: { sm: '0 0', lg: '0 0',xs:"0px -50px 0px 50px"},
         
       }}
       container spacing={0} justifyContent="center"
     >
       {firstFiveCategories && firstFiveCategories.length > 0 && firstFiveCategories.map((item, index) => (
       <Grid

           onClick={() => handleNavComponent(item.id,item.name)}
           item
           xs={6}
           sm={2.4}
           md={2.4}
           lg={2.4}
           key={index}
         >
           <TypeItemComponent  image={item.white_icon  ? item.white_icon.original_url:item.icon.original_url} title={item.name} />
         </Grid>
          ))}
        </Grid>
         </Box>
      
        
      </div>
      
      <div 
        style={{
          zIndex: 1,  // Lower z-index to place it behind the content above
          height: '15%',
          backgroundColor: 'red',
          borderBottomLeftRadius: '50% 50%',
          borderBottomRightRadius: '50% 50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          position: 'absolute',  // Position it absolutely to be behind the other content
          bottom: 20,  // Align it at the bottom of the container
          left: 0,
          right: 0,
        }}
      />
    </div>
  );
}