
import {React,useEffect} from 'react';
import ResponsiveAppBar from '../Components/Global_components/nav_bar';
import { Grid,Box  } from '@mui/material';
import { MdOutlineFavorite } from "react-icons/md";

import { CiBoxList } from "react-icons/ci";
import "../Styles/DescriptionImageSlider.css";
import ReviewSection from '../Components/Description_Page_Components/review_section';
import DescriptionImageSlider from '../Components/Description_Page_Components/description_slider';
import MapSection from '../Components/Description_Page_Components/map_section';
import DescriptionLeftSection from './description_section/description_left_section';
import DescriptionRightSection from './description_section/description_right_section';
import Footer from '../Components/Global_components/footer';
import { useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {fetchDashboardData} from '../Redux_Managment/dashboard_slice';


  
  export default function DescriptionPage() {
    const location = useLocation();
    const { product, agency_details } = location.state || {};
    const savedLanguage = localStorage.getItem('language') || 'fr';

    if (!product) {
      return <div>No product information available</div>;
    }

    return (
        <div style={{background: "#ECECF4", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" ,height:"100%"}}>
            <div style={{ width: "100%",direction:"ltr"}}>
           
            <DescriptionImageSlider imageSliders={product.images_collection} />
          
            </div>
            <Box  sx={{ width: { xs: '90%', md: '80%'},height:"auto" ,background: "#ECECF4",display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center", }}>
            <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={8}>
                        <h1 style={{ margin: 0,fontFamily:"Mukta", fontWeight:"bold",fontSize:"2rem" }}>
                        {product.title}
                            </h1>
                            {/* <p style={{color:"#5B5B5B", margin: 0,fontFamily:"Mukta", fontWeight:"100",fontSize:"0.8rem" }}>
                             {t('sub_description')}
                            </p> */}
                            <p style={{color:"#5B5B5B", margin: 0,fontFamily:"Mukta", fontWeight:"100",fontSize:"0.8rem" }}>
                            {product.address}
                            </p>
                    </Grid>   

                    <Grid item xs={12} sm={12} md={4} sx={{justifyContent:{ xs: "start", md: "flex-end"}}}>
                               <div style={{ display: "flex", flexDirection: "row", marginTop: "2%",justifyContent:"flex-end" }}>
                                    <div style={{display:"flex",borderRadius:"4px", background:"#dbd7d7",margin:"3px",width:"20px",height:"20px",justifyContent:"center",alignItems:"center"}}>
                                        <MdOutlineFavorite style={{ color: "#979797", justifyContent: "center", alignItems: "center" }} />
                                    </div>
                                    <div style={{display:"flex",borderRadius:"4px", background:"#dbd7d7",margin:"3px",width:"20px",height:"20px",justifyContent:"center",alignItems:"center"}}>
                                        <CiBoxList style={{ color: "#979797", justifyContent: "center", alignItems: "center" }} />
                                    </div>
                        
                                 </div>
                        <h1 style={{textAlign:savedLanguage== 'ar' ? "left":"right",justifyContent:"flex-end", margin: 0,fontFamily:"Mukta", fontWeight:"bold",fontSize:"2rem",color:"red" }}>
                        {product.price} Da
                        </h1>
                    </Grid>
            </Grid>

            <Grid container spacing={0}>
  {/* Left Side */}
  <Grid item xs={12} sm={12} md={8}>
    <DescriptionLeftSection description={product.description}
       garage_val={product.garage}
       area_val={product.area}
       garden={product.garden}
       pool={product.pool}
       Gas={product.gaz}
       electricity={product.electricity}
       Furnished={product.furnished} 

       status={product.status}
       type={product.type}
       sell_price={product.sell_price}
       category_name={product.category.name}
       
       />
       {/* rooms={product.num_of_rooms} */}
  </Grid>
  
  {/* Right Side */}
  <Grid 
    item 
    xs={false} 
    sm={false} 
    md={4} 
    sx={{
      display: { xs: 'none', sm: 'none', md: 'block' }, // Hide on xs and sm screens
      justifyContent: 'flex-end'
    }}
  >
      {(product.agency || agency_details) && (
        <DescriptionRightSection 
          Agency={product.agency} 
          agency_detail={agency_details} 
        />
      )}
  </Grid>
</Grid>


                <Grid container spacing={0}>


                    {/* left Side */}
                    <Grid item xs={12} sm={12} md={8} style={{ marginTop: "2%",}}>
                      <MapSection/>    
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} style={{ marginTop: "2%",marginBottom:"2%"}}>
                      <ReviewSection/>
              
                    </Grid>

                    <div style={{height:"10px",background:"blue"}}></div>

                </Grid>



            </Box>



            
           
        </div>
    );
}