import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import BASE_URL from '../Config';


// Set up interceptors to include the token automatically
// api.interceptors.request.use((config) => {
//   // Retrieve the token from the cookie if using HttpOnly cookies
//   const token = document.cookie
//     .split('; ')
//     .find(row => row.startsWith('token='))
//     ?.split('=')[1];

//   if (token) {
//     config.headers['Authorization'] = `Bearer ${token}`;
//   }
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

  // Function to retrieve the token from cookies
  // const getTokenFromCookies = () => {
  //   const token = document.cookie
  //     .split('; ')
  //     .find(row => row.startsWith('token='))
  //     ?.split('=')[1];
  //   return token;
  // };

  // const token = getTokenFromCookies();

// Async thunk for login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials,{ rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/login`, credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
    return rejectWithValue(error.response?.data?.message || 'Failed to login');
  }
});

// Async thunk for registration
export const registerUser = createAsyncThunk('auth/registerUser', async (credentials,{rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/register`, credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
    return rejectWithValue(error.response?.data?.message || 'Failed to register');
  }
});

export const fetchCurrentUser =createAsyncThunk('/auth/fetchCurrentUser', async () => {


  try {
    const response = await axios.get(`${BASE_URL}/api/user`,
    { headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }});

    console.log('----user data', response.data);
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
    // return rejectWithValue(error.response?.data?.message || 'Failed to fetch user');
  }
});


export const editUserInfo= createAsyncThunk('/auth/edtUserData',async(credentials,{ rejectWithValue })=>{
 try{
  const response= await axios.post(`${BASE_URL}/api/edit-profile`,credentials,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }});
  return response.data
 }
 catch(error){
    console.log(error.response?.data);
    return rejectWithValue(error.response?.data?.message || 'Failed to fetch user');
 }
})

export const signOut = createAsyncThunk('/auth/signOut', async () => {

  try {
    const response = await axios.post(`${BASE_URL}/api/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      } 
    });
    console.log("error log out: ",response.data)
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
  }
});

export const signOutFromAll = createAsyncThunk('/auth/logout-from-all', async () => {

  try {
    const response = await axios.post(`${BASE_URL}/api/logout-from-all`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      } 
    });

    console.log("log out ",response.data);
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
  }
})



const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    status: 'idle',
    error: null,
    userStatus: 'idle',
    userError:null,
    editStatus: 'idle',
    editError:null
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('user_name');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.token;

        localStorage.setItem('user_name', action.payload.user.name);
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.token;
        
        localStorage.setItem('user_name', action.payload.user.name);
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.user = action.payload.user;
        console.log(state.user);
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.userError = action.error.message;
      }).addCase(editUserInfo.pending, (state) => {
        state.editStatus = 'loading';
      }).addCase(editUserInfo.fulfilled, (state, action) => {
        state.editStatus = 'succeeded';
    
        localStorage.setItem('user_name', action.payload.user.name);
        console.log("success");
      }).addCase(editUserInfo.rejected, (state, action) => {
        state.editStatus = 'failed';
        state.editError = action.error.message;
      })
      .addCase(signOut.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = null;
        state.token = null;
        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(signOutFromAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signOutFromAll.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = null;
        state.token = null;
        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
      })
      .addCase(signOutFromAll.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
      
      
      ;
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
