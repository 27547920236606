import React, { useEffect } from 'react'; // Import useEffect here
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import store from './Store';
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Path to your i18n file




const Root = () => {
  const savedLanguage = localStorage.getItem('language') || navigator.language || 'fr';
  
  useEffect(() => {
    i18n.changeLanguage(savedLanguage);
    document.documentElement.setAttribute('dir', savedLanguage === 'ar' ? 'rtl' : 'ltr');
  }, [savedLanguage]); // Update dependency to savedLanguage

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Root />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
