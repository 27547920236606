import {React , useState} from "react";
import { Grid } from "@mui/material";
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import "../../Styles/FeaturedItemCardStyle.css";
import AgencyItem from "./agency_item";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} new-custom-arrow new-custom-arrow-next`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ color: 'black' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} new-custom-arrow new-custom-arrow-prev`}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClick={onClick}
    >
      <PlayArrowIcon className="arrow-icon" style={{ transform: 'rotate(-180deg)', color: 'black' }} />
    </div>
  );
}
const CustomDots = ({ onClick, active }) => (
  <div
    className={`w-2 h-2 mx-1 rounded-full cursor-pointer ${
      active ? 'bg-red-500' : 'bg-blue-300'
    }`}
    onClick={onClick}
  />
);

export default function AgenciesGrid({agencies }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };


  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    rows: 2,
    slidesPerRow: 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dotsClass: 'my-custom-slider-dots',
    customPaging: () => <button color="red"></button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  const savedLanguage = localStorage.getItem('language') || 'fr';
  return (
    <Grid sx={{
      
      width: {xs:"70%",md:"75%",lg:"80%"}, margin: '0 auto', position: 'relative' }}>
      <Slider {...settings}  style={{direction:savedLanguage!="ar"?"ltr":"rtl"}}>
        {agencies.map((item, index) => (
     
          <div key={index}>
             <AgencyItem agency={item} />
          </div>
        ))}
      </Slider>


    </Grid>
  );
}