
import React from "react";
import { Grid ,Box, Typography } from "@mui/material";
import '../../Styles/MainStyle.css';


export default function TypeItemComponent({image,title}) {
    return (
        <Grid sx={{cursor:"pointer", display: "grid", justifyContent: "center", alignItems: "center", marginTop: "1%" ,width:"100%",textAlign:"center"}}>
            <Box
                className="dotted-box"
                sx={{
                    width: { xs: "100px",md: "100px", lg: "110px" ,xl:"150px"},
                    height: { xs: "100px",md: "100px", lg: "100px" ,xl:"150px"},
                    padding: "2px",
                    background: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img src={image} alt="user icon" width="80%" height="80%" style={{  padding: '10px' }} />
            </Box>
            <Typography className="item-type-text" style={{marginTop:"5px",fontFamily:"Mukta"}} sx={{fontSize:"0.8rem"}}>{title}</Typography>
        </Grid>
     
     
        
    );
}
