
import {Box,} from "@mui/material";
import React from "react";

export default function WorkWithRealityItem({image}) {
    return (
        <Box
        sx={{
          margin:"2%",
          height:"100%",
          paddingTop: "20px",
          paddingBottom: "40px",
          paddingLeft: "10px",
          paddingRight: "10px",
          background: "#ECECF4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center" // Added to center-align text
        }}>
     <img
  src={image}
  alt="user icon"
  width="30%"
  height="30%"
  style={{
    filter: 'invert(18%) sepia(92%) saturate(7462%) hue-rotate(340deg) brightness(95%) contrast(120%)',
  }}
/>


          <p style={{color:"#3E3E3F",fontWeight:"bolder",fontSize:"16px",fontFamily:"serif"}}>Prime Real Estate Opportunities Await!</p>
        
          <p style={{color:"grey",fontWeight:"regular",fontSize:"16px",fontFamily:"serif",marginTop:"-2%"}} >Discover your next investment with our

                premium building listings. Our expert 

                team ensures you find the perfect property

                to meet your needs. Contact us today for

                personalized service and exclusive deals</p>

              
        </div>
        
      </Box>
    );
}