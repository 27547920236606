import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './Components/Global_components/nav_bar';
import Footer from './Components/Global_components/footer';
import { useSelector } from 'react-redux';

function Layout() {
  const location = useLocation();
  const { categories } = useSelector(state => state.dashboard || {});

  const noNavbarPaths = ['/profile','/my-Products','/description','/new-Product','/my-favorite-products','/Agency-Detail'];

  const hideNavbar = () => {
    
    return noNavbarPaths.some(path => {
      const regex = new RegExp(`^${path}`);
      return regex.test(location.pathname);
    });
  };

  return (
    <>
      {!hideNavbar() && <ResponsiveAppBar categories={categories} />}
      
      <Outlet />
      
      <Footer />
    </>
  );
}

export default Layout;
