
import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegisterForm from "../Components/Auth/register_form";
import LoginForm from "../Components/Auth/login_form";

import { 
    Box, 
    Tabs, Tab,
    Container, 
  } from '@mui/material';


export default function Authentication() {
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState('register');

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
        return (
            <div style={{minHeight:"100vh", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", background: "#ECECF4", width: "100%", maxWidth: "1500px", margin: "0 auto", padding: "0rem" }}>
                {/* <ResponsiveAppBar/> */}
                <Container sx={{width:{ xs: '90%', sm: '60%', md: '40%', lg: '40%'}}} style={{ background: 'white', 
                    paddingLeft: '4%', paddingRight: '4%',justifyContent: 'center',alignItems: 'center',
                    margin: '4%', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                    <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ width: '80%', padding: "0px", mb: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange} 
                    textColor="#7B7B7B"
                    indicatorColor="#7B7B7B"
                    sx={{
                    flexGrow: 1, // Ensures Tabs take full width available
                    '& .MuiTab-root': { 
                        color: 'text.secondary',
                        fontFamily: 'Mukta-Bold',
                        fontWeight: 'bold',
                        padding:"5%",
                        fontSize:"0.8rem",
                        marginRight:"3%",
                        marginLeft:"-5%",
                        textTransform: 'none',
                        textAlign: 'start',
                        '&.Mui-selected': { color: '#E4180B' }
                    },
                    '& .MuiTabs-indicator': { backgroundColor: '#E4180B' }
                    }}
                >
                    <Tab label={t('login')} value="login" />
                    <Tab label={t('register')} value="register" />
                </Tabs>
                    </Box>

                    {activeTab=="register" ?
                    <RegisterForm  handleTabChange={handleTabChange}/>
                    
                        : <LoginForm handleTabChange={handleTabChange}/>}
                    </Box>
                </Container>
                
            </div>
        );
}