// src/Redux_Managment/products_slice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../Config';


const savedLanguage = localStorage.getItem('language') || 'fr';
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/products`);
    console.log(response.data.data.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch products');
  }
});

export const fetchAct = createAsyncThunk('products/fetchActs', async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/acts`,{params: {"lang": savedLanguage}});
    console.log(response.data.data.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch acts');
  }
});
export const fetchUserProducts = createAsyncThunk('products/fetchUserProducts', async () => {
  try {
    //user-
    const response = await axios.get(`${BASE_URL}/api/user-products`,{
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
    });
    console.log("user-products products",response.data.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch products');
  }
});
export const fetchFavProducts = createAsyncThunk('products/fetchFavProducts', async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/favorites`,{
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
    });
    console.log("fav products",response.data.data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to fetch products');
  }
});



export const toggleFavProduct = createAsyncThunk('products/toggleFavProduct', async (productId, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/toggle-favorite`,

      { productId },
      
  
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      },


    );
    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error.response?.data?.message || 'Failed to fetch fav products');
  }
});

export const addProduct = createAsyncThunk('products/addProduct', async (formData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/products`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Update to multipart/form-data
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
    return rejectWithValue(error.response?.data?.message || 'Failed to add new product');
  }
});


export const editProduct = createAsyncThunk('products/editProduct', async (formData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/edit-product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Update to multipart/form-data
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response?.data);
    return rejectWithValue(error.response?.data?.message || 'Failed to add new product');
  }
});


export const deleteProduct = createAsyncThunk('products/deleteProduct', async (productId, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/delete-product`,
      productId,
  
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      },


    );
    
    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error.response?.data?.message || 'Failed to remove fav products');
  }
});



const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    favProduct:[],
    acts: [],
    userProducts:[],
    status: 'idle',
    error: null,
    toggleError: null,
    addProductStatus: 'idle',
    addProductError: null,

    actStatus: 'idle',
    actError: null,

    deleteStatus: 'idle',
    deleteError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload.data.data;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })   
      .addCase(fetchFavProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFavProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favProduct = action.payload.data;
      })
      .addCase(fetchFavProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
       .addCase(fetchUserProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log("---1---1--fetch user products", action.payload.data.data);
        state.userProducts = action.payload.data.data;
        console.log("---2---2--fetch user products", state.userProducts);
      })
      .addCase(fetchUserProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(toggleFavProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toggleFavProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favProduct = action.payload.data;
        console.log(state.favProduct);
      })
      .addCase(toggleFavProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.toggleError = action.error.message;
      })
      .addCase(addProduct.pending, (state) => {
        state.addProductStatus = 'loading';
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.addProductStatus = 'succeeded';
        console.log("add product", action.payload.data);
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.addProductStatus = 'failed';
        state.addProductError = action.error.message;
      })
      .addCase(editProduct.pending, (state) => {
        state.addProductStatus = 'loading';
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.addProductStatus = 'succeeded';
        console.log("Edit product", action.payload.data);
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.addProductStatus = 'failed';
        state.addProductError = action.error.message;
      })
      .addCase(fetchAct.pending, (state) => {
        state.actStatus  = 'loading';
      })
      .addCase(fetchAct.fulfilled, (state, action) => {
        state.actStatus = 'succeeded';
        state.acts= action.payload.data;
        console.log("acts", action.payload.data);
      })
      .addCase(fetchAct.rejected, (state, action) => {
        state.actStatus = 'failed';
        state.actError = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.deleteStatus  = 'loading';
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.deleteStatus = 'succeeded';
        console.log("delete product", action.payload.data);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.deleteError = 'failed';
      })
      
      
      ;
  },
});

export default productsSlice.reducer;
