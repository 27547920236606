// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: "Welcome",
      next_btn:"Next",
      categories:"Categories",
      about:"About",
      contact:"Contact",
      agencies:"Agencies",
      search:"Search...",
      log_in:"Log in",
      log_out:"Log out",
      browse_product:"Browse more property types",
      sub_description:"Listing featured properties with types filter",
      home:"home",
      profile:"Profile",
      my_products:"My products",
      fav_products:"Favorite products",
      english:"English",
      french:"French",
      arabic:"Arabic",
      close:"Close",
      no_suggestions:"No suggestions",
      featured:"Featured",
      for_sale:"For Sale",
      for_rent:"For Rent",
      all:"All",
      properties:"Properties",
      for_exchange:"For exchange",
      properties:"Properties",
      working_with:"Working with",
      reality:"the reality",
      reality_properties:"Reality Properties",
      register:"Register",
      login:"Login",
      email:"Email",
      password:"Password",
      re_password:"Re-Password",
      name:"Name",
      user_name:"User Name",
      have_account:"Already have an account?",
      email_phone:"Email or phone",
      or_connect:"Or connect with",
      invalide_email:"Invalid email",
      invalid_phone:"Invalid phone",
      invalid_email_phone:"Invalid email or phone",
      required_password:"Password is required",
      required_field:"Field is required",
      not_password:"Passwords do not match",
      agency_detail_page:"Agency Details",
      agency_explore_more:"Exploring More featured agencies",
      agency_page_title:"Find Your Perfect Real Estate Partner",
      agency_page_sub_title:"Explore our diverse range of agencies dedicated to providing top-notch services and solutions tailored to your needs.",
      no_description:"No description is available",
      garage:"Garage",
      property_details:"Property Details",
      status:"Status",
      sell_price:"Sell Price",
      category:"Category",
      type:"Type",
      features:"Features",
      property_address:"Property Adress :",
      property_content:"Property Content :",
      leave_comment:"Leave a comment",
      review_for:"Review for house and pool :",
      user_product_title:"Manage Your Products",
      user_product_sub_title:"Efficiently manage your product inventory. View, edit, or remove items as needed to keep your list up-to-date.",
      new_product:"New Product",
      product_title:"Product Title",
      product_description:"Product Description",
      product_price:"Product Price",
      product_image:"Product Image",
      product_status:"Product Status",
      invetory_product:"Product Inventory Management",
      invetory_product_sub_title:"Manage your product inventory from this section. You keep track of all product details and updates",
      add_product_s1:"Step 1: Step 1 :Categories", 
      add_product_s2:"Step 2: Complete Form", 
      add_product_s3:"Step 3: Define Location", 
      add_product_s4:"Step 4: Upload Images", 
      add_product_s5:"Step 5: Select Features", 
      create_product:"Create New Product",
      edit_product:"Edit Your Product",
      selected_category:"Selected category :",
      select_your_state:"Select your state :",
      select_your_city:"Select your city :",
      selected_state:"Selected state :",
      selected_city:"Selected city :",
      active_featured:"Active Featured",
      pool:'Pool',
      electricity:'Electricity',
      gaz:'Gaz',
      furnished:'Furnished',
      garden:'Garden',
      choose_one_type:"Choose one type",
      selected_act:"Select ypur activity",
      finish:"Finish",
      social_media:"Social Media",
      contact_info:"Contact Info",
      filter_result:"Filter Result",
      filter_result_sub_title:"Results That Perfectly Match Your Preferences and Expectations.",
      no_product:"No Products are existed yet!",
      no_product_for_category:"No Products are existed for this category yet!",
      no_agency:"No Agencies are existed for this term",
      no_result:"No result exists for this term",
      search_for:"Search result for",
      search_for_sub_title:"Explore our curated selection of items that closely match your search criteria and preferences.",
      delete:"Delete",
      delete_product:"Delete Product",
      are_u_sur:"Are You sur! you want to delete this product",
      floor:"Floor",
      user_product_title:"Manage Your Products",
      user_product_sub_title:'Efficiently manage your product inventory View, edit, or remove items as needed to keep your list up-to-date.',
      all_products:"All Products",
      accepted_products:"Accepted Products",
      rejected_products:"Rejected Products",
      pending_products:"Pending Products",
      rejected_products:"Rejected Products",
      sale:"For Sale",
      rent:"For Rent",
      exchange:"For Exchange",
      fav_product_title:"My Favorite Products",
      fav_product_title_sub: ' Explore a curated selection of Your top picks,  showcasing products that You love .',
      fav_product_title_sub2:' Enjoy browsing through Your personal favorites!',
      product_name: "Product Name",
      address: "Address",
      product_description: "Product Description",
      price: "Price",
      min_price: "Min Price",
      sell_price: "Sell Price",
      nb_rooms: "Number of Rooms",
      nb_floor: "Which Floor",
      nb_floors: "Number of Floors",
      date_of_construction: "Date of Construction",
      area: "Area (m²)", // Added proper unit
      type: "Type",
      upload_imgs:"Upload you product Images",
      use_links:"Useful Links",
      contact_us:"Contact Us",
      about_us:"About Us",
      footer_sub_title:"Explore a wide range of buildings for sale with our dedicated real estate agency.",
      footer_sub_title2:"From commercial to residential properties, we have the perfect solution for you.",
      footer_sub_title3:"Get in touch to start your journey with us. Whether you're looking to buy or sell, our agency offers unmatched expertise and a portfolio of top-tier buildings.",
      subscribe:"SubScribe",
      email_address:"Email address",
      contact_with_us:"Contact with us",
      reality_properties:"Reality Property",
      decription:"Description",
      your_product:"Your products",
      your_fav_products:"Your favorite products",
      log_out_from_all:"Log out from all Devices",
      personal_info:"Personnal Information",
      personal_info_sub_title:"Edit Your Personnal Email Phone and other Information",
      phone:"Phone",
      save:"Save",
      willaya:"Willaya",
      filter_title: "Filter your products by",
      prix:"Price (Da)",
      min:"Min",
      max:"Max",
      superficie:"Area",
      papier:"Documents",
      specification:"Specification",
      filter:"Filter",






     
  
      
     
     









      // Add other translations here
    }
  },
  fr: {
    translation: {
        welcome: "Bienvenue",
        next_btn:"Suivant",
        categories: "Catégories",
        about: "À propos",
        contact: "Contact",
        agencies: "Agences",
        search: "Rechercher...",
        log_in: "Se connecter",
        log_out: "Se déconnecter",
        browse_product: "Explorer d'autres types de propriétés",
        sub_description:"Liste des propriétés en vedette avec filtre de types.",
        home: "Accueil",
        profile: "Profil",
        my_products: "Mes produits",
        fav_products: "Produits favoris",
        english: "Anglais",
        french: "Français",
        arabic: "Arabe",
        close: "Fermer",
        no_suggestions: "Aucune suggestion",
        featured: "En vedette",
        for_sale: "À vendre",
        for_rent: "À louer",
        all: "Tout",
        properties: "Propriétés",
        for_exchange: "À échanger",
        working_with: "Travailler avec",
        reality:"réalité",
        reality_properties:"Propriétés de réalité",
        register:"S'inscrire",
        login:"Connexion",
        email:"Email",
        password:"Mot de passe",
        re_password:"Ressaisir le mot de passe",
        name:"Nom",
        user_name:"Nom d'utilisateur",
        have_account:"Vous avez déjà un compte ?",
        email_phone:"Email ou téléphone",
        or_connect:"Ou connectez-vous avec",
        invalide_email: "Email invalide",
        invalid_phone: "Téléphone invalide",
        invalid_email_phone:" Email ou téléphone invalide",
        required_password: "Le mot de passe est requis",
        required_field: "Ce champ est requis",
        not_password:"Les mots de passe ne correspondent pas.",
        agency_detail_page: "Détails de l'agence",
        agency_explore_more: "Explorer plus d'agences en vedette",
        agency_page_title:"Trouvez votre partenaire immobilier idéal",
        agency_page_sub_title: "Explorez notre large éventail d'agences dédiées à fournir des services et des solutions de haute qualité adaptés à vos besoins.",
        no_description: "Aucune description disponible",
        garage: "Garage",
        property_details: "Détails de la propriété",
        status: "Statut",
        sell_price: "Prix de vente",
        category: "Catégorie",
        type: "Type",
        features: "Caractéristiques",
        property_address: "Adresse de la propriété :",
        property_content: "Contenu de la propriété :",
        leave_comment: "Laisser un commentaire",
        review_for: "Avis pour la maison et la piscine :",
        user_product_title: "Gérez vos produits",
        user_product_sub_title: "Gérez efficacement votre inventaire de produits. Affichez, modifiez ou supprimez des articles au besoin pour garder votre liste à jour.",
        new_product: "Nouveau produit",
        product_title: "Titre du produit",
        product_description: "Description du produit",
        product_price: "Prix du produit",
        product_image: "Image du produit",
        product_status: "Statut du produit",
        invetory_product: "Gestion de l'inventaire des produits",
        invetory_product_sub_title: "Gérez votre inventaire de produits depuis cette section. Vous suivez tous les détails et mises à jour des produits.",
        add_product_s1: "Étape 1 : Catégories",
        add_product_s2: "Étape 2 : Remplir le formulaire",
        add_product_s3: "Étape 3 : Définir l'emplacement",
        add_product_s4: "Étape 4 : Télécharger des images",
        add_product_s5: "Étape 5 : Sélectionner les caractéristiques",
        create_product:"Créer un nouveau produit",
        edit_product:"Modifier votre produit",
        selected_category:"Catégorie sélectionnée :",
        select_your_state:"Sélectionnez votre willaya :",
        select_your_city:"Sélectionnez votre ville :",
        selected_state:"Willaya sélectionné :",
        selected_city:"Ville sélectionnée :",
        active_featured: "Choisissez les propriétés",
        pool:"Piscine",
        electricity:"Électricité",
        gaz:'Gaz',
        furnished:"Meublé",
        garden:"Jardin",
        choose_one_type:"Choisissez un type",
        selected_act:"Sélectionnez votre activité",
        finish: "Terminer",
        social_media: "Médias sociaux",
        contact_info: "Informations de contact",
        filter_result: "Résultat du filtre",
        filter_result_sub_title: "Résultats qui correspondent parfaitement à vos préférences et à vos attentes",
        no_product: "Aucun produit n'existe encore !",
        no_product_for_category: "Aucun produit n'existe encore pour cette catégorie !",
        no_agency: "Aucune agence n'existe pour ce terme.",
        no_result: "Aucun résultat n'existe pour ce terme.",
        search_for: "Résultat de recherche pour",
        search_for_sub_title: "Explorez notre sélection soigneusement choisie d'articles qui correspondent étroitement à vos critères de recherche et préférences.",
        delete: "Supprimer",
        delete_product: "Supprimer le produit",
        are_u_sur: "Êtes-vous sûr! Vous voulez supprimer ce produit?",
        floor: "Étage(s)",
        user_product_title: "Gérer vos produits",
        user_product_sub_title: "Gérez efficacement votre inventaire de produits. Consultez, modifiez ou supprimez des articles selon vos besoins pour garder votre liste à jour.",
        all_products: "Tous les produits",
        accepted_products: "Produits acceptés",
        rejected_products: "Produits rejetés",
        pending_products: "Produits en attente",
        rejected_products: "Produits rejetés",
        sale: "À vendre",
        rent: "À louer",
        exchange: "À échanger",
        fav_product_title: "Mes produits favoris",
        fav_product_title_sub: "Explorez une sélection soigneusement organisée de vos meilleurs choix, présentant les produits que vous aimez.",
        fav_product_title_sub2: "Profitez de la navigation à travers vos favoris personnels !",
        product_name: "Nom du produit",
        address: "Adresse",
        product_description: "Description du produit",
        price: "Prix",
        min_price: "Prix minimum",
        sell_price: "Prix de vente",
        nb_rooms: "Nombre de chambres",
        nb_floor: "À quel étage",
        nb_floors: "Nombre d'étages",
        date_of_construction: "Date de construction",
        area: "Surface (m²)", // Added proper unit
        type: "Type",
        upload_imgs: "Téléchargez les images de votre produit",
        contact_us: "Contactez-nous",
        about_us: "À propos de nous",
        footer_sub_title: "Découvrez une large gamme de bâtiments à vendre avec notre agence immobilière dédiée.",
        footer_sub_title2: "Des propriétés commerciales aux résidences, nous avons la solution parfaite pour vous.",
        footer_sub_title3: "Contactez-nous pour commencer votre aventure avec nous. Que vous cherchiez à acheter ou à vendre, notre agence offre une expertise inégalée et un portefeuille de bâtiments de premier choix.",
        subscribe: "S'abonner",
        email_address: "Adresse e-mail",
        contact_with_us: "Contactez-nous",
        reality_properties: "Propriété Réelle",
        decription:"Description",
        your_product: "Vos produits",
        your_fav_products: "Vos produits favoris",
        log_out_from_all: "Se déconnecter de tous les appareils",
        personal_info: "Informations personnelles",
        personal_info_sub_title: "Modifiez votre e-mail, téléphone et autres informations personnelles",
        save: "Enregistrer",
        phone:"téléphone",
        willaya:"Willaya",
        filter_title: "Filtrer votre produit par",
        prix:"Prix (Da)",
        min:"Min",
        max:"Max",
        superficie:"superficie",
        papier:"papier",
        specification:"Spécification",
        filter:"Filtre",

       



        

     

        
      }
      
  },
  ar: {
    translation: {
      welcome: "مرحبًا",
      next_btn:"التالي",
      categories: "الفئات",
      about: "حول",
      contact: "اتصل",
      agencies: "وكالات",
      search: "ابحث...",
      log_in: "تسجيل الدخول",
      log_out: "تسجيل الخروج",
      browse_product: "استكشاف أنواع العقارات الأخرى",
      sub_description: "قائمة العقارات المميزة مع فلتر الأنواع.",
      home: "الرئيسية",
      profile: "الملف الشخصي",
      my_products: "منتجاتي",
      fav_products: "المنتجات المفضلة",
      english: "الإنجليزية",
      french: "الفرنسية",
      arabic: "العربية",
      close: "إغلاق",
      no_suggestions: "لا توجد اقتراحات",
      featured: "",
      for_sale: "للبيع",
      for_rent: "للايجار",
      all: "الكل",
      properties: "عقارات",
      for_exchange: "للتبادل",
      working_with: "العمل مع",
      reality: "الواقع",
      reality_properties: "عقارات الواقع",
      register: "سجل",
      login: "تسجيل الدخول",
      email: "البريد الإلكتروني",
      password: "كلمة المرور",
      re_password: "إعادة إدخال كلمة المرور",
      name: "الاسم",
      user_name: "اسم المستخدم",
      have_account: "هل لديك حساب بالفعل؟",
      email_phone: "البريد الإلكتروني أو الهاتف",
      or_connect: "أو قم بتسجيل الدخول باستخدام",
      invalide_email: "بريد إلكتروني غير صالح",
      invalid_phone: "هاتف غير صالح",
      invalid_email_phone: "بريد إلكتروني أو هاتف غير صالح",
      required_password: "كلمة المرور مطلوبة",
      required_field: "هذا الحقل مطلوب",
      not_password: "كلمات المرور غير متطابقة",
      agency_detail_page: "تفاصيل الوكالة",
      agency_explore_more:  "استكشاف المزيد من الوكالات المميزة",
      agency_page_title: "اعثر على شريكك العقاري المثالي",
      agency_page_sub_title: "استكشف مجموعة واسعة من الوكالات المخصصة لتقديم خدمات وحلول عالية الجودة تناسب احتياجاتك.",
      no_description: "لا يوجد وصف متاح",
      garage: "كراج",
      property_details: "تفاصيل العقار",
      status: "الحالة",
      sell_price: "سعر البيع",
      category: "الفئة",
      type: "النوع",
      features: "الميزات",
      property_address: "عنوان العقار:",
      property_content: "محتوى العقار:",
      leave_comment: "اترك تعليقًا",
      review_for: "تقييم للمنزل والمسبح:",
      user_product_title: "إدارة منتجاتك",
      user_product_sub_title: "قم بإدارة مخزون منتجاتك بكفاءة. عرض وتعديل أو حذف العناصر حسب الحاجة للحفاظ على قائمتك محدثة.",
      new_product: "منتج جديد",
      product_title: "عنوان المنتج",
      product_description: "وصف المنتج",
      product_price: "سعر المنتج",
      product_image: "صورة المنتج",
      product_status: "حالة المنتج",
      invetory_product: "إدارة مخزون المنتجات",
      invetory_product_sub_title: "قم بإدارة مخزون منتجاتك من خلال هذا القسم. تتبع جميع تفاصيل وتحديثات المنتجات.",
      add_product_s1: "الخطوة 1: الفئات",
      add_product_s2: "الخطوة 2: ملء النموذج",
      add_product_s3: "الخطوة 3: تحديد الموقع",
      add_product_s4: "الخطوة 4: تحميل الصور",
      add_product_s5: "الخطوة 5: اختيار الميزات",
      create_product:"إنشاء منتج جديد",
      edit_product: "تعديل معلومات المنتج",
      selected_category:"الفئة المختارة",
      select_your_state:"اختر ولايتك :",
      select_your_city: "اختر مدينتك :",
      selected_state: "الولاية المختارة :",
      selected_city:"المدينة المختارة :",
      active_featured: "اختر مميزات هذا العقار",
      pool:"المسبح",
      electricity:"الكهرباء",
      gaz:'الغاز',
      furnished:"المفروشات",
      garden:"الحديقة",
      choose_one_type:"اختر نوع واحد",
      selected_act:"اختر نشاط واحد",
      finish: "إنهاء",
      social_media: "وسائل التواصل الاجتماعي",
      contact_info: "معلومات الاتصال",
      filter_result: "نتيجة الفلتر",
      filter_result_sub_title: "نتائج تتوافق تمامًا مع تفضيلاتك وتوقعاتك",
      no_product: "لا يوجد منتج حتى الآن!",
      no_product_for_category: "لا يوجد منتج لهذه الفئة حتى الآن!",
      no_agency: "لا توجد وكالة لهذا المصطلح.",
      no_result: "لا توجد نتيجة لهذا المصطلح.",
      search_for: "نتيجة البحث عن",
      search_for_sub_title: "استكشف مجموعتنا المختارة بعناية من العناصر التي تتوافق بشكل وثيق مع معايير بحثك وتفضيلاتك.",
      delete: "حذف",
      delete_product: "حذف المنتج",
      are_u_sur: "هل أنت متأكد! هل تريد حذف هذا المنتج؟",
      floor: "الطابق",
      user_product_title: "إدارة منتجاتك",
      user_product_sub_title: "قم بإدارة مخزون منتجاتك بكفاءة. عرض، تعديل أو إزالة العناصر حسب الحاجة للحفاظ على قائمتك محدثة.",
      all_products: "جميع المنتجات",
      accepted_products: "المنتجات المقبولة",
      rejected_products: "المنتجات المرفوضة",
      pending_products: "المنتجات قيد الانتظار",
      rejected_products: "المنتجات المرفوضة",
      sale: "للبيع",
      rent: "للإيجار",
      exchange: "للتبادل",
      fav_product_title: "منتجاتي المفضلة",
      fav_product_title_sub: "استكشف مجموعة مختارة بعناية من أفضل اختياراتك، تعرض المنتجات التي تحبها",
      fav_product_title_sub2: "استمتع بتصفح قائمتك المفضلة الشخصية!",
      product_name: "اسم المنتج",
      address: "العنوان",
      product_description: "وصف المنتج",
      price: "السعر",
      min_price: "أقل سعر",
      sell_price: "سعر البيع",
      nb_rooms: "عدد الغرف",
      nb_floor: "في أي طابق",
      nb_floors: "عدد الطوابق",
      date_of_construction: "تاريخ البناء",
      area: "المساحة (م²)", // Added proper unit
      type: "النوع",
      upload_imgs: "قم بتحميل صور منتجك",
      contact_us: "اتصل بنا",
      about_us: "معلومات عنا",
      footer_sub_title: "استكشف مجموعة واسعة من المباني المعروضة للبيع مع وكالتنا العقارية المتخصصة.",
      footer_sub_title2: "من العقارات التجارية إلى السكنية، لدينا الحل المثالي لك.",
      footer_sub_title3: "تواصل معنا لبدء رحلتك معنا. سواء كنت تبحث عن شراء أو بيع، تقدم وكالتنا خبرة لا مثيل لها ومحفظة من المباني من الدرجة الأولى.",
      subscribe: "اشترك",
      email_address: "عنوان البريد الإلكتروني",
      contact_with_us: "تواصل معنا",
      reality_properties: "عقارات حقيقية",
      decription:"",
      your_product: "منتجاتك",
      your_fav_products: "منتجاتك المفضلة",
      log_out_from_all: "تسجيل الخروج من جميع الأجهزة",
      personal_info: "المعلومات الشخصية",
      personal_info_sub_title: "قم بتعديل بريدك الإلكتروني، الهاتف والمعلومات الشخصية الأخرى",
      phone:"رقم الهاتف",
      save: "حفظ",
      willaya:"الولاية",
      filter_title: "البحث عن العقارات عن طريق ",
      prix:" السعر (دج)",
      min:"اقل",
      max:"اكبر",
      superficie:"المساحة",
      papier:"النشاط",
      specification:"الميزات",
      filter:"البحث",


  }
  
  }
};
const savedLanguage = localStorage.getItem('language') || 'fr';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage, // Use saved language or default to 'en'
    fallbackLng: 'en',
    interpolation: {

      escapeValue: false // React already escapes values
    }
  });

export default i18n;
