// MyComponent.js
import React from 'react';
import { toast } from 'react-toastify';
export const notifySuccess = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000, // Duration of the toast in milliseconds
    });
  };

  export  const notifyError = () => {
    toast.error('Error message!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000,
    });
  };
