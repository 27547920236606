




import React from "react";
import { Switch, Typography, Box,Grid,IconButton ,CircularProgress} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
export default function 
Step5({ setActivateStep,features, setFeatures,addProduct ,loading,acts,selectAct,actName}) {

    const { t } = useTranslation();


    const handleSwitchChange = (feature) => (event) => {
        setFeatures({ ...features, [feature]: event.target.checked });
      };
      
  
    
    return(
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                background: "white",
                borderRadius: "10px",
                padding: "2%", 
            }}   
        > 
      
        
      <Grid container style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                marginTop:"3%",
                justifyContent: "center",
                alignItems: "center"
            }}>
              <Grid item xs={12} md={6} justifyContent={"center"} display={"flex"} textAlign={{xs:"center" , md:"start"}}>
                
              <Box sx={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                flexDirection:{xs:"column",md:"row"},
                justifyContent: "center",
                alignItems: "center"
            }}>
                <IconButton onClick={() => setActivateStep(2)}>
                    <MdKeyboardBackspace style={{ fontSize: "1.5rem", color: "#ff574b" }} />
                </IconButton>
                <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold" }}>
                  {t('add_product_s5')}</Typography>
                 
            </Box>
     

        </Grid>

       
        
        {actName ? <Grid item xs={12} md={6} marginTop={{xs:"3%",md:"0%"}} background="red" justifyContent={{xs:"center" , md:"end"}} alignItems={{xs:"center" , md:"end"}}>
        
        {loading ? 
        <Box
        sx={{
            cursor: "pointer",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                justifyContent: {xs:"center", md : "end",},
                alignItems: {xs:"center", md :"flex-end",} ,
                textAlign: {xs:"center", md : "end",}
            }}>
                <CircularProgress size={24} sx={{ color: 'orange' }} />
               
                
        </Box>
        
          :   
          <Box
        onClick={() => addProduct()}
        sx={{
            cursor: "pointer",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                justifyContent: {xs:"center", md : "end",},
                alignItems: {xs:"center", md :"flex-end",} ,
                textAlign: {xs:"center", md : "end",}
            }}>
                 <Typography  style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold",color:"#ff574b" }}>
                 {t('finish')}</Typography>
              
            </Box>}
        </Grid>:<Grid item xs={12} md={6} marginTop={{xs:"3%",md:"0%"}}></Grid>}
        </Grid>
        <Grid container width={{xs:"100%",md:"90%",margin:"2%"}}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Mukta-Bold",textAlign:{xs:"center",md:"start"} }}>{t('active_featured')}</Typography></Grid>
         </Grid>    
     
        <Grid container sx={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px",marginBottom:"20px" }}>
                   <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                     <div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center"}}>
                      <Typography >{t('pool')}</Typography>
                      <Switch
                        checked={features.pool}
                        onChange={handleSwitchChange("pool")}
                        />
                      
                       </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                       <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                      
                       <Typography >{t('electricity')}</Typography>
                       <Switch
                        checked={features.electricity}
                        onChange={handleSwitchChange("electricity")}
                        />
                       
                       </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                       <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                       <Typography >{t('gaz')}</Typography>
                       <Switch
                        checked={features.Gaz}
                        onChange={handleSwitchChange("Gaz")}
                        />
        
                       
                       </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
                           
                            <Typography >{t('garden')}</Typography>
                            <Switch
                            checked={features.garden}
                            onChange={handleSwitchChange("garden")}
                            />
                       </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                       <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                       <Typography >{t('garage')}</Typography>
                       <Switch
                        checked={features.garage}
                        onChange={handleSwitchChange("garage")}
                        />
                       
                       </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                       <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                       <Typography >{t('furnished')}</Typography>
                       <Switch
                        checked={features.furnished}
                        onChange={handleSwitchChange("furnished")}
                        />
                       
                       </div>
                    </Grid>
                </Grid>
         <Grid container width={{xs:"100%",md:"90%",margin:"2%"}}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "0.9rem", fontFamily: "Mukta-Bold",textAlign:{xs:"center",md:"start"} }}>{t('choose_one_type')}</Typography></Grid>
                    <Grid item xs={12} md={6} sx={{justifyContent:{xs:"center",md:"end"}}}>  { actName && 
                        <Typography sx={{fontSize: "0.9rem", fontFamily: "Mukta-Bold"  ,textAlign:{xs:"center",md:"end"}}}>{t('selected_act')}: {actName}</Typography>}
                    </Grid>
         </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: { xs: "100px", md: "150px" }, // Adjust height as needed
                            display: "flex",
                            justifyContent: "flex-start", // Use 'flex-start' to align items at the start
                            alignItems: "flex-start", // Align items vertically at the start
                            flexDirection: "column",
                            borderRadius: "10px",
                            marginLeft: "7%",
                            marginRight: "7%",
                            overflowY: "auto", // Enable vertical scrolling
                        }}
                        >
                        {acts && acts.length > 0 && acts.map((act, index) => (
                          <Box
                          key={index} // Always include a key when mapping items
                          onClick={() => selectAct(act.id, act.name)}
                          sx={{
                            display: "flex", // Use flex to align items in a row
                            flexDirection: "row", // Ensure children are arranged in a row
                            padding: "5px",
                            textAlign: "start",
                            cursor: "pointer",
                            borderRadius: "0px",
                            alignItems: "center", 
                          }}
                        >
                          <FaCheckCircle
                            style={{
                              padding: "0px",
                              margin: "1%",
                              fontSize: "1rem",
                              color: actName === act.name ? "green" : "transparent",
                              flexShrink: 0, 
                            }}
                          />
                          <Typography
                            style={{
                              margin: "10px",
                              display: "flex", 
                              alignItems: "center", 
                            }}
                          >
                            {act.name}
                          </Typography>
                        </Box>
                        
                        ))}
                    </Box>

                    </Grid>
               </Grid>

    
              
           

        </Box>

    );
}