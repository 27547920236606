import React, { useState } from 'react';
import { MdOutlineMailOutline, MdPassword } from "react-icons/md";
import "../../Styles/Authentication.css";
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import googleIcon from "../../assets/images/google.png";
import facebookIcon from "../../assets/images/facebook.png";
import { loginUser } from '../../Redux_Managment/auth_slice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function LoginForm({handleTabChange}) {
    const { t, i18n } = useTranslation();
    const [email_or_phone, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const dispatch = useDispatch();
    const navigate =useNavigate();
    const { status, error } = useSelector((state) => state.auth);

    const validateEmailOrPhone = (input) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Regular expression for phone number validation
        const phoneRegex = /^(?:\+?\d{1,3})?[-.\s]?\(?\d{2,3}\)?[-.\s]?\d{3,4}[-.\s]?\d{4}$/;
        
        // Test the input against both regex patterns
        return emailRegex.test(input) || phoneRegex.test(input);
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setEmailError('');
        setPasswordError('');
        setLoading(true); // Set loading to true when starting the request

        if (!email_or_phone || !validateEmailOrPhone(email_or_phone)) {
            setEmailError(t('invalid_email_phone'));
        }
        if (!password) {
            setPasswordError(t('required_field'));
        }

        if (email_or_phone && password && validateEmailOrPhone(email_or_phone)) {
            try {
                await dispatch(loginUser({ email_or_phone, password })).unwrap();
                navigate('/', { replace: true });
            } catch (err) {
                // Assuming error.response.data contains error messages from the backend
                if (err.response && err.response.data) {
                    if (err.response.data.email_or_phone) {
                        setEmailError(err.response.data.email);
                    }
                    if (err.response.data.password) {
                        setPasswordError(err.response.data.password);
                    }
                } else {
                    // Handle other possible errors
                    console.error('An unexpected error occurred:', err);
                }
            } finally {
                setLoading(false); // Set loading to false after request completes
            }
        } else {
            setLoading(false); // Set loading to false if validation fails
        }
    };

    return (
        <Box component="form"
            noValidate
            sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <div style={{ paddingLeft: "9%", paddingRight: "9%" }}>

                <TextField
                    style={{ backgroundColor: '#F1F1F1' }}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    placeholder={t('email_phone')}
                    name="email"
                    value={email_or_phone}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    
                    autoComplete="email"
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdOutlineMailOutline />
                            </InputAdornment>
                        ),
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                padding: '8px 0',

                    
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                padding: '8px 0',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                padding: '8px 0',
                            },
                            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                                border: '2px solid red',
                                padding: '8px 0', // Custom error border
                            },
                            '& .MuiInputBase-input': {
                                padding: '8px 0', // Adjust padding for the text inside
                            },
                        },
                    }}
                />

                <span style={{color:"red",fontSize:"12px"}}>{emailError}</span>

                <TextField
                    style={{ backgroundColor: '#F1F1F1' }}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    placeholder={t('password')}
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdPassword />
                            </InputAdornment>
                        ),
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                                border: '2px solid red', // Custom error border
                            },
                            '& .MuiInputBase-input': {
                                padding: '8px 0', // Adjust padding for the text inside
                            },
                        },
                    }}
                />
                <span style={{color:"red",fontSize:"12px"}}>{passwordError}</span>

            </div>

            <Button
                style={{ width: '80%', marginTop: '1rem', padding: "10px", justifyContent: "center", alignItems: "center", borderRadius: "25px" }}
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={loading} // Disable button while loading
                sx={{ margin: 3, justifyContent: "center", textTransform: 'none', alignItems: "center", bgcolor: '#E4180B', borderRadius: "25px", fontFamily: "Mukta-BOLD" }}
            >
                {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : t('login')}
            </Button>
            <p style={{textAlign: 'center', fontFamily: 'Mukta', color: 'red', letterSpacing: '0.3px', fontSize: '14px'}}>{error}</p>
            <Typography style={{ fontFamily: "Mukta-Bold", color: "#4D4D4D", letterSpacing: "0.3px", fontSize: "16px" }} align="center" sx={{ mt: 1, mb: 1 }}>
                {t('or_connect')}
            </Typography>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "20px" }} >
                <img src={googleIcon} alt="Google" width="25px" style={{ margin: '0 10px' }} />
                <img src={facebookIcon} alt="Facebook" width="25px" style={{ margin: '0 10px' }} />
            </Box>

        </Box>
    );
}
