import { Grid } from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


export default function FeatureGrid({
    garage,
    garden,
    pool,
    Gas,
    electricity ,
    Furnished ,
}) {

    const {t,i18n}=useTranslation();
    const features = [
      
        { name: t('pool'), checked:pool ==0 ? false:true },
        { name:t('garden'), checked: garden==0? false:true },
        { name:t('garage'), checked: garage == 0 ? false : true },
        { name:t('Gaz') , checked: Gas==0?false:true },
        { name: t('electricity'), checked: electricity==0?false:true },
        { name: t('furnished'), checked: Furnished==0?false:true },
         
        
        // Add more features as needed
    ];
    return (
        <Grid container spacing={2}>
        {features.map((feature, index) => (
          <Grid item xs={6} md={4} sm={4} lg={4} xl={4} key={index}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px", // Added padding for better visual separation
            }}>
              <p style={{
                fontSize: "0.85rem",
                fontWeight: "normal",
                fontFamily: "Mukta",
                color: "black",
                margin: "0", // Remove default margin
                marginRight: "8px", // Space between text and icon
              }}>
                {feature.name}
              </p>
              <div style={{
                display: "flex",
                justifyContent: "center", // Center icon horizontally
                alignItems: "center", // Center icon vertically
                width: "18px", // Width of the icon container
                height: "17px", // Height of the icon container
                borderRadius: "50%", // Make it a circle
                background: feature.checked ? "green" : "#D9D9D9",
                margin: "0", // Remove default margin
              }}>
                <FaCheck 
                  style={{
                    fontSize: "0.7rem",
                    color: feature.checked ? "white" : "#D9D9D9",
                  }} 
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    );
}
