
import React from "react";
import {Avatar, Box,Typography,Grid,CircularProgress} from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAgencyById  } from "../Redux_Managment/agencies_slice";
import { useLocation } from "react-router-dom";
import DescriptionImageSlider from "../Components/Description_Page_Components/description_slider";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { useTranslation } from "react-i18next";
import '../Styles/SharedStyle.css';
import FeaturedPropertiesSection from "./home_page_section/featured-properties-section";
import LoadingWidget  from "../Components/Global_components/loading_widget";
 export default function AgencyDetail() {
    const { t } = useTranslation();
    const location=useLocation();
    const dispatch=useDispatch();
    const {agency_id,agency_name}=location.state || {};
    console.log(agency_id,agency_name);
    const {agency,oneStatus,agencyProducts}=useSelector((state)=>state.agencies || {})
    useEffect(() => {
        dispatch(fetchAgencyById(agency_id));
    }, [dispatch]);
  

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            width: "100%",
            maxWidth: "1500px",
            margin: "0 auto",
            padding: "0rem"
          }}>
            <Box sx={{
              background: "#ECECF4",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px"
            }}>
              <Typography variant="h5" sx={{
                textAlign: "center",
                color: "#E4180B",
                fontFamily: "Mukta",
                paddingTop: "20px",
                paddingBottom: "5px"
              }}>
                {t('agency_detail_page')}
              </Typography>
              <Typography sx={{
                textAlign: "center",
                fontSize: "14px",
                width: "80%",
                padding: "0 10%",
                fontFamily: "Mukta",
                paddingTop: "5px",
                paddingBottom: "20px"
              }}>
                   {t('agency_page_sub_title')}
              </Typography>
              <Box sx={{
                background: "white",
                width: "80%",
                paddingBottom: "10px",
                display: "flex", // Added to ensure any potential alignment of children inside the box
                justifyContent: "center",
                alignItems: "center"
              }}>
           
              

                  
                {oneStatus=='loading'? <LoadingWidget/> :

                    <div style={{width:"100%",justifyContent:"center",alignItems:"center",}}>
                        {agency?.banners?.length > 0 && (
                            <DescriptionImageSlider imageSliders={agency.banners} />
                        )}
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"4px",margin:"2%"}}>
                        <Grid container spacing={0} justifyContent="center" style={{marginRight:"2%",marginLeft:"2%"}}>
                        <Grid item xs={12} md={12} >
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Avatar
                        src={agency?.logo?.original_url}
                        alt="Remy Sharp"
                        sx={{
                            width: 80,
                            height: 80,
                            margin: 2,
                            border: '2px solid grey',
                            // Optionally, you can add more styling if needed
                            borderRadius: '50%' // Ensures circular shape
                        }}
                        />
                            <Typography sx={{fontFamily:"Mukta",fontSize:"22px",paddingTop:"20px"}}>{agency_name}</Typography>
                    
                            </div>
                
                        </Grid>
                      
                 
                   
                     </Grid>
                  
                    </div>
                    <div style={{
                        width: "100%", // Ensure the parent takes full width
                        height: "3px", // Ensures parent container has height
                        display: "flex",
                        justifyContent: "center", // Centers horizontally
                        alignItems: "center" // Centers vertically
                        }}>
                        <div style={{
                            width: "90%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            height: "1px",
                            color: "grey",
                            background: "grey"
                        }}></div>
                        </div>
               

                    {agency?.about && <div style={{ width:"80%",marginLeft:"10%",marginRight:"10%",display:"flex",flexDirection:"column",justifyContent:"center", alignContent:"center",textAlign:"start"}}>
                       <p style={{fontSize:"1.1rem",fontFamily:"Mukta",fontWeight:"normal" ,}} >{t('decription')}</p>
                       <p style={{fontSize:"0.9rem",fontFamily:"Mukta",fontWeight:"normal" ,marginTop:"-1%",color:"#5b5b5b"}} >{agency?.about}</p>
                   
                    </div>}

                    <div style={{ width:"80%",marginLeft:"10%",
                        marginRight:"10%",display:"flex",flexDirection:"column",
                        borderRadius:"15px",
                        justifyContent:"center", alignContent:"center",textAlign:"start" }}>
                       <p style={{fontSize:"1.1rem",fontFamily:"Mukta",fontWeight:"normal" ,}} >{t('contact_info')}</p>
                       <Grid container >
                         <Grid item xs={12} md={4} sx={{marginTop:"-1%"}}>
                            <div style={{cursor:"pointer",display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                                <MdOutlineMailOutline style={{fontSize:"1.2rem",marginRight:"5px"}}/>
                                <p style={{fontFamily:"Mukta",fontWeight:"normal" ,paddingTop:"2px"}}>{agency?.email}</p>
                            </div>
                         </Grid>
                         <Grid item xs={12} md={4} sx={{marginTop:"-1%"}}>
                            <div style={{cursor:"pointer",display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                                <MdOutlinePhone style={{fontSize:"1.2rem",marginRight:"5px"}}/>
                                <p style={{fontFamily:"Mukta",fontWeight:"normal" ,paddingTop:"2px"}}>{agency?.phone}</p>
                            </div>
                         </Grid>
                         <Grid item xs={12} md={4} sx={{marginTop:"-1%"}}>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                                <CiLocationOn style={{fontSize:"1.2rem",marginRight:"5px"}}/>
                                <p style={{fontFamily:"Mukta",fontWeight:"normal" ,paddingTop:"2px"}}>{agency?.address}</p>
                            </div>
                         </Grid>

                       </Grid>
                    </div>

                    <div style={{ width:"80%",marginLeft:"10%",
                        marginRight:"10%",display:"flex",flexDirection:"column",
                        borderRadius:"15px",
                        justifyContent:"center", alignContent:"center",textAlign:"start" }}>
                       <p style={{fontSize:"1.1rem",fontFamily:"Mukta",fontWeight:"normal" ,marginBottom:"2%"}} >{t('social_media')}</p>
                       <Grid container >
                         <Grid item xs={12} md={4} sx={{marginTop:"1%"}}>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                                <a
                                    href={agency?.instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover-agency-social-icons"
                                    style={{ textDecoration: 'none',padding:"5px" }}
                                    >
                                   
                                    <FaFacebook />
                                    <p style={{margin:"3px"}}>Facebook</p>
                                 </a>
                            </div>
                         </Grid>
                         <Grid item xs={12} md={4} sx={{marginTop:"1%"}}>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                            <a
                                href={agency?.tiktok}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover-agency-social-icons"
                                style={{ textDecoration: 'none',padding:"5px" }}
                                >
                                
                                <FaTiktok />
                                <p style={{margin:"3px"}}>Tik Tok</p>
                                </a>
                            </div>
                         </Grid>
                         <Grid item xs={12} md={4} sx={{marginTop:"1%"}}>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0px"}}>
                              
                            <a
                                href={agency?.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover-agency-social-icons"
                                style={{ textDecoration: 'none' }}
                                >
                                
                                <FaInstagram />
                                <p style={{margin:"3px"}}>Instagram</p>
                                </a>
                               </div>
                         </Grid>

                       </Grid>
                    </div>

                    <div style={{width:"80%",margin:"10%"}}></div>
                    
                    </div>
                   

                }
              
     
              </Box>
              {agencyProducts  && agencyProducts?.data?.length>0 &&
                       <div style={{width:"90%",display:"flex",justifyContent:"center"}}>
                         <FeaturedPropertiesSection products={agencyProducts.data} agency_details={agency}
                       hideProperties={true}/>
                        </div>}
            </Box>
          </div>
    );
}