import * as React from 'react';
import {Box,IconButton,Typography,TextField, Select,MenuItem,Grid,} from '@mui/material';
import { useState } from 'react';  
import { MdKeyboardBackspace } from "react-icons/md";
import { useTranslation } from 'react-i18next';
export default function Step2({
    setActivateStep,
    title, setTitle,
    description, setDescription,
    address, setAddress,
    area, setArea,
    type, setType,
    status, setStatus,
    price, setAPrice,
    min_price, setMinPrice,
    sell_price, setSellPrice,
    price_negotiable, setNegoPrice,
    num_of_rooms, setNumRoom,
    num_of_floors, setNumFloor,
    floor, setFloor,
    date_of_construction, setDateOfconstruction 





}) {
    const { t } = useTranslation();
    const handleChange = (e) => {
        setTitle(e.target.value);
      
      };
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [areaError, setAreaError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [statusError, setStatusError] = useState('');

    const [priceError, setAPriceError] = useState('');
    const [min_priceError, setMinPriceError] = useState('');
    const [sell_priceError, setSellPriceError] = useState('');

    const [price_negotiableError, setNegoPriceError] = useState('');
    const [num_of_roomsError, setNumRoomError] = useState('');
    const [num_of_floorsError, setNumFloorError] = useState('');
    const [floorError, setFloorError] = useState('');
    const [date_of_constructionError, setDateOfconstructionError] = useState('');
    
    const [isvalide,setValide]=useState(false);

    const validation = () => {
        let isValid = true;
    
        // Clear previous errors
        setTitleError('');
        setDescriptionError('');
        setAddressError('');
        setAreaError('');
        setTypeError('');
        setStatusError('');
        setAPriceError('');
        setMinPriceError('');
        setSellPriceError('');
        setNegoPriceError('');
        setNumRoomError('');
        setNumFloorError('');
        setFloorError('');
        setDateOfconstructionError('');
    
        // Validate title
        if (!title) {
            setTitleError('Title is required');
            isValid = false;
        }
    
        // Validate description
        if (!description) {
            setDescriptionError('Description is required');
            isValid = false;
        }
    
        // Validate address
        if (!address) {
            setAddressError('Address is required');
            isValid = false;
        }
    
        // Validate price
        if ( !price || price <= 0) {
            setAPriceError('Price must be a positive number');
            isValid = false;
        }
    
        // Validate min_price
        if (!min_price || min_price < 0) {
            setMinPriceError('Min Price cannot be negative');
            isValid = false;
        }
    
        // Validate sell_price
        if (!sell_price || sell_price <= 0) {
            setSellPriceError('Sell Price must be a positive number');
            isValid = false;
        }
    
        // Validate number of rooms
        if (!num_of_rooms || num_of_rooms < 0) {
            setNumRoomError('Number of Rooms cannot be negative');
            isValid = false;
        }
    
        // Validate number of floors
        if (!num_of_floors || num_of_floors < 0) {
            setNumFloorError('Number of Floors cannot be negative');
            isValid = false;
        }
    
        // Validate floor
        if (!floor || floor < 0) {
            setFloorError('Floor cannot be negative');
            isValid = false;
        }
    
        // Validate date of construction
        if (!date_of_construction) {
            setDateOfconstructionError('Date of Construction is required');
            isValid = false;
        }

        if (!area) {
            setAreaError('Area is required');
            isValid = false;
        }
        if (!type) {
            setTypeError('Product Type is required');
            isValid = false;
        }
    
        // Set overall validity state
        setValide(isValid);
    };
    
    // Call this validation function before submitting the form
    const handleSubmit = (e) => {
        e.preventDefault();
        validation();
        if (isvalide) {
            
           setActivateStep(2);
        }
    };

    

    return (
        <Box
        sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent:"center",
            background: "white",
            borderRadius: "10px",
            padding: "2%", // Optional padding for aesthetics
        }}
    >
        <Grid container style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                marginTop:"3%",
                justifyContent: "center",
                alignItems: "center"
            }}>
              <Grid item xs={12} md={6} justifyContent={"center"} display={"flex"} textAlign={{xs:"center" , md:"start"}}>
                 
          
                <Box sx={{
                    width: "90%",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    display: "flex",
                    flexDirection:{xs:"column",md:"row"},
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <IconButton onClick={() => setActivateStep(0)}>
                        <MdKeyboardBackspace style={{ fontSize: "1.5rem", color: "#ff574b" }} />
                    </IconButton>
                    <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold" }}>
                        {t('add_product_s2')}</Typography>
                </Box>
         

            </Grid>
            <Grid item xs={12} md={6} marginTop={{xs:"3%",md:"0%"}} background="red" justifyContent={{xs:"center" , md:"end"}} alignItems={{xs:"center" , md:"end"}}>
          
            <Box
            onClick={handleSubmit}
            sx={{
              cursor: "pointer",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  display: "flex",
                  justifyContent: {xs:"center", md : "end",},
                  alignItems: {xs:"center", md :"flex-end",} ,
                  textAlign: {xs:"center", md : "end",}
              }}>
                     <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold",color:"#ff574b" }}>
                     {t('next_btn')}</Typography>
                   
                   
                </Box>
            </Grid>
        </Grid>
        <Box
            sx={{
                width: { xs: "90%", md: "90%" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center items horizontally
                background: "white",
                borderRadius: "10px",
                marginTop: "10px", // Space between header and box
                padding: "16px", // Optional padding for better spacing
            }}
        >
            <div style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
             <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('product_name')}</p>  
                  <TextField
                    id="outlined-multiline-flexible"
                    
                    margin="normal"
                    value={title}
                    onChange={(e)=>{setTitle(e.target.value)}}
                    error={!!titleError}
                    helperText={titleError}

                    required
                    fullWidth
                    
                    style={{marginTop:"-6px"}}
                    sx={{
                        marginTop: "10px",
                        width: {xs:"100%",md:"90%"},
                        '& .MuiInputBase-root': {
                          padding: '5px',  // Adjust padding to control height
                        },
                        '& .MuiOutlinedInput-input': {
                          fontSize: '0.9rem',  // Reduce font size to make the field smaller
                          padding: '8px',  // Adjust padding for height control
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '0.9rem',  // Adjust label font size to match
                        },
                      }}
                    
                  />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('address')}</p> 
                  <TextField
                            id="outlined-multiline-flexible"
                           onChange={(e) => setAddress(e.target.value)}
                            margin="normal"
                            value={address}
                            error={!!addressError}
                            helperText={addressError}
                            required
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('product_description')}</p> 
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            maxRows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            error={!!descriptionError}
                            helperText={descriptionError}
                            required
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('price')}</p> 
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            type='number'
                            value={price}
                            error={!!priceError}
                            helperText={priceError}
                            onChange={(e) => 

                              {if(e.target.value<0){
                                setAPrice(0)
                              }else{
                                setAPrice(e.target.value)
                              }}
                              


                            }
                            
                            required
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('min_price')}</p> 
                  <TextField
                            id="outlined-multiline-flexible"
                          
                            margin="normal"
                            maxRows={4}
                            required
                            fullWidth
                            onChange={(e) =>
                              {if(e.target.value<0){
                                setMinPrice(0)
                              }else{
                                setMinPrice(e.target.value)
                              }}
                              
                              }
                            type='number'  
                            error={!!min_priceError}
                            helperText={min_priceError}
                            value={min_price}
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('sell_price')}</p> 
                  <TextField
                            id="outlined-multiline-flexible"
                           
                            margin="normal"
                            type='number'
                            value={sell_price}
                            onChange={(e) => 
                            {if(e.target.value<0){
                              setSellPrice(0)
                            }else{
                              setSellPrice(e.target.value)
                            }}
                            
                            }
                            error={!!sell_priceError}
                            helperText={sell_priceError}
                            required
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('nb_rooms')}</p> 
           
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            maxRows={4}
                            required
                            onChange={(e) => 
                              {if(e.target.value<0){
                                setNumRoom(0)
                              }else{
                                setNumRoom(e.target.value)
                              }}
                              
                              }
                            value={num_of_rooms}
                            error={!!num_of_roomsError}
                            helperText={num_of_roomsError}
                             type='number'
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('nb_floors')}</p> 
           
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            maxRows={4}
                            required
                            fullWidth
                            value={num_of_floors}
                            error={!!num_of_floorsError}
                            helperText={num_of_floorsError}
                             type='number'
                           
                            onChange={(e) => 
                              {if(e.target.value<0){
                                setNumFloor(0)
                              }else{
                                setNumFloor(e.target.value)
                              }}
                              
                              }
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
              
               </Grid>
    
               <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('nb_floor')}</p> 
           
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            maxRows={4}
                            required
                            value={floor}
                            error={!!floorError}
                            helperText={floorError}
                            type='number'
                           
                            onChange={(e) => 
                              {if(e.target.value<0){
                                setFloor(0)
                              }else{
                                setFloor(e.target.value)
                              }}
                              
                              }
                            type='number'
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                width: {xs:"100%",md:"90%"},
                                marginTop: "10px",
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('date_of_construction')}</p> 
           
                  <TextField
                            id="outlined-multiline-flexible"
                          
                            margin="normal"
                            type="date"
                            maxRows={4}
                            value={date_of_construction}
                            onChange={(e) => setDateOfconstruction(e.target.value)}
                            error={!!date_of_construction}
                            helperText={date_of_construction}
                            required
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                marginTop: "10px",
                                width: {xs:"100%",md:"90%"},
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}


                             
                            />
                  </Grid>
              
               </Grid>

               <Grid container>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('area')}</p> 
           
                  <TextField
                            id="outlined-multiline-flexible"
                            margin="normal"
                            maxRows={4}
                            required
                            value={area}
                        
                            onChange={(e) => 
                              {if(e.target.value<0){
                                setArea(0)
                              }else{
                                setArea(e.target.value)
                              }}
                              
                              }
                            type='number'
                            error={!!areaError}
                            helperText={areaError}
                            fullWidth
                            style={{marginTop:"-6px"}}
                            sx={{
                                width: {xs:"100%",md:"90%"},
                                marginTop: "10px",
                                '& .MuiInputBase-root': {
                                  padding: '5px',  // Adjust padding to control height
                                },
                                '& .MuiOutlinedInput-input': {
                                  fontSize: '0.9rem',  // Reduce font size to make the field smaller
                                  padding: '8px',  // Adjust padding for height control
                                },
                                '& .MuiInputLabel-root': {
                                  fontSize: '0.9rem',  // Adjust label font size to match
                                },
                              }}
                             
                            />
                  </Grid>
                  <Grid itex xs={12} md={6}>
                  <p style={{fontFamily:"Mukta",color:"balck",fontSize:"0.9rem",fontWeight:"bold"}}>{t('type')}</p> 
           
             <Box
                 
                    >
                   
                    <Select
                        fullWidth
                        value={type}
                        error={!!typeError}
                        helperText={typeError}
                        onChange={(e) => setType(e.target.value)}
                        displayEmpty
                        IconComponent={() => <p />}
                        sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '0.5px solid grey',
                            width:   {xs:"84%",md:"90%",sm:"80%",lg:"80%"},
                             paddingLeft:"20px",
                             paddingRight:"20px",
                          
                             marginTop: '-5px',
                             marginRight:"-3px",
                             marginLeft:"-3px",
                             height: '46px'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: '0.5px solid grey',
                            padding: '0px 4px 0px 4px',
                             width:   {xs:"95%",md:"88%"},
                             height: '46px'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid grey',
                            padding: '0px 4px 0px 4px',
                            width:   {xs:"95%",md:"88%"},
                            height: '46px'
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                           border: '0.5px solid red',
                            padding: '0px 4px 0px 4px',
                            width:   {xs:"95%",md:"88%"},
                             height: '46px'
                        },
                        '& .MuiInputBase-input': {
                            padding: '4px 0',
                            fontSize: '14px',
                            lineHeight: '1.2'
                        },
                        }}
                    >
                        <MenuItem disabled value="">
                        <p style={{ fontFamily: "Mukta", color: "#999999", fontSize: "14px" }}>{t('choose_one_type')}</p>
                        </MenuItem>
                        <MenuItem value={1}>{t('for_rent')}</MenuItem>
                        <MenuItem value={2}>{t('for_sale')}</MenuItem>
                        <MenuItem value={3}>{t('for_exchange')}</MenuItem>
                    </Select>
                    </Box>
                  </Grid>
              
               </Grid>
          
            </div>
               
            </Box>
                     </Box>
    );
}