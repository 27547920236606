



import React, { useRef, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { MdKeyboardBackspace, MdClose } from "react-icons/md";
import { RiUploadCloudLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";


export default function Step4({ setActivateStep ,setBanners,imgsUrl,setDeleteImages,setImagesUrl}) {
  const { t } = useTranslation();

  const imagesInputRef = useRef(null); 
  const [imgs, setImgs] = useState([]); 

 
  // Handle removing the cover image
  // Handle additional image upload and display preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); 
      setImgs((prevImgs) => [...prevImgs, imageUrl]); 
      console.log(file);
      setBanners((prevBanners) => [...prevBanners, file]);
    }
  };

  // Trigger cover image file input click

  // Trigger additional images file input click
  const handleImagesBoxClick = () => {
    if (imagesInputRef.current) {
      imagesInputRef.current.click(); // Trigger images input
    }
  };

  // Handle removing individual images
  const handleClose = (index) => {
    setImgs((prevImgs) => prevImgs.filter((_, i) => i !== index));
    setBanners(imgs);
  };

  const handleCloseUrlImgs = (index, id) => {
    // Remove the image from imgsUrl
    setImagesUrl((prevImgs) => prevImgs.filter((_, i) => i !== index));
    
    // Add the ID of the deleted image to the state
    setDeleteImages((prevIds) => [...prevIds, id]);
};

// Log imgsUrl whenever it changes
    useEffect(() => {
        console.log('Updated imgsUrl:', imgsUrl);
    }, [imgsUrl]);




  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: "white",
        borderRadius: "10px",
        padding: "2%",
      }}
    >
           <Grid container style={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                marginTop:"3%",
                justifyContent: "center",
                alignItems: "center"
            }}>
              <Grid item xs={12} md={6} justifyContent={"center"} display={"flex"} textAlign={{xs:"center" , md:"start"}}>
                
      
            <Box sx={{
                width: "90%",
                paddingLeft: "2%",
                paddingRight: "2%",
                display: "flex",
                flexDirection:{xs:"column",md:"row"},
                justifyContent: "center",
                alignItems: "center"
            }}>
            <IconButton onClick={() => setActivateStep(2)}>
              <MdKeyboardBackspace style={{ fontSize: "1.5rem", color: "#ff574b" }} />
            </IconButton>
            <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold" }}>
            {t('add_product_s4')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} marginTop={{xs:"3%",md:"0%"}} background="red" justifyContent={{xs:"center" , md:"end"}} alignItems={{xs:"center" , md:"end"}}>
          
          <Box
            onClick={() => setActivateStep(4)}
            sx={{
                cursor: "pointer",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    display: "flex",
                    justifyContent: {xs:"center", md : "end",},
                    alignItems: {xs:"center", md :"flex-end",} ,
                    textAlign: {xs:"center", md : "end",}
                }}>
            <Typography style={{ fontSize: "1.2rem", fontFamily: "Mukta-Bold", color: "#ff574b" }}>
            {t('next_btn')}
            </Typography>
         
          </Box>
        </Grid>
      </Grid>

      {/* Cover Image Upload */}
      <Grid container display="flex" justifyContent="center" alignItems="center">
      

        {/* Additional Images Upload */}
        <Grid item xs={12} md={12}>
          <input
            type="file"
            ref={imagesInputRef}
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: "none" }}
          />
          <Box
            onClick={handleImagesBoxClick}
            sx={{
              width: "95%",
              marginBottom: "10px",
              height: { xs: "150px", md: "300px" },
              paddingLeft: "2%",
              paddingRight: "2%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              background: "#ECECF4",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <RiUploadCloudLine style={{ fontSize: "2rem", color: "#ff574b" }} />
            <Typography sx={{ fontSize: "0.8rem", fontFamily: "Mukta", color: "grey" }}>
              {t('upload_imgs')}
            </Typography>
          </Box>

          {/* Display uploaded images */}
          {imgs && imgs.length>0 && <Grid container spacing={1} sx={{ padding: "2%", width: "95%"  }}>
             { imgs.map((img, index) => (
                <Grid item xs={4} md={2} key={index}>
                    <Box position="relative">
                    <img
                        src={img}
                        alt={`Profile ${index}`}
                        style={{ width: "100%", borderRadius: "10px" }}
                    />
                    <IconButton
                        onClick={() => handleClose(index)}
                        sx={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        background: "white",
                        "&:hover": {
                            background: "rgba(255, 255, 255, 0.8)", // Slightly transparent on hover
                        },
                        }}
                    >
                        <MdClose style={{ fontSize: "14px", color: "#ff574b" }} />
                    </IconButton>
                    </Box>
                </Grid>
                ))}
            </Grid>}

            {imgsUrl && imgsUrl.length > 0 && <Grid container spacing={1} sx={{ padding: "2%", width: "95%" }}>
            {imgsUrl.length > 0 ? (
                imgsUrl.map((img, index) => (
                    <Grid item xs={4} md={2} key={index}>
                        <Box position="relative">
                            <img
                                src={img.original_url}
                                alt={`img ${index}`}
                                style={{ width: "100%", borderRadius: "10px" }}
                            />
                            <IconButton
                                onClick={() => handleCloseUrlImgs(index, img.id)}
                                sx={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    width: "20px",
                                    height: "20px",
                                    padding: "0px",
                                    background: "white",
                                    "&:hover": {
                                        background: "rgba(255, 255, 255, 0.8)",
                                    },
                                }}
                            >
                                <MdClose style={{ fontSize: "14px", color: "#ff574b" }} />
                            </IconButton>
                        </Box>
                    </Grid>
                ))
            ) : (
                <Box sx={{ height: "50px", width: "100%" }}>
                  
                </Box>
            )}
          </Grid>}



        </Grid>
      </Grid>
    </Box>
  );
}
